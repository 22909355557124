<template>
	<div class="nav-content m-b0">
		<Banner :bannerTitle="bannerTitle"></Banner>
		<div class="height510">
			<div class="article-box">
				<div class="article-title">欢迎您，来自{{userSource}}的用户！</div>
				<div class="article-p">《企业技术创新力在线自测系统》从创新投入、知识产权、创新效益、创新组织与管理等角度切入，蕴含有关人才、学历、薪酬、研发经费、设备、技术来源、专利、商标、其他知识产权、技术运营、产品销售、利润、出口、效率、能耗、战略、管理、文化、资本市场、重大项目等多项关联指标，结合科学的评价模型和覆盖全国、全产业链技术领域的创新大数据，能够帮助企业实现快捷、客观、定量、可持续的技术创新能力自我评价，并为企业提供线上自助填测、测评结果分析、报告自动生成和下载等服务支持。</div>
				<div class="article-p">科学、严谨、持续性的技术创新力自我评价能够帮助企业发现自身技术创新能力的优劣势，助力企业扬长补短，提升科技创新能力，推动高质量发展。</div>
			</div>
			<div class="welcome-box">
				<div class="welcome-floatpart welcome-curenterpart">
					<div class="curenterpart-entercon">
						<span class="entercon-entertle">当前登录企业</span>
						<!-- <span class="entercon-companyname" v-if="companyName != ''">{{companyName}}</span> --><!--北京科华万象科技有限公司-->
						<span class="entercon-companyname"><!--entercon-nocompany-->
							<span class="companyname-resname" v-if="companyName != ''">{{companyName}}</span><!--北京科华万象科技有限公司-->
							<el-autocomplete  v-else
								class="companyname-bindinput"
								v-model="companyInput"
								:fetch-suggestions="nameTipFn"
								:trigger-on-focus="false"
								placeholder="输入完整企业名称"
								@select="searchSelect"
							></el-autocomplete>
						</span>
					</div>
					<div class="curenterpart-enterbtnbox"><!-- v-if="companyName == ''"-->
						<button class="companyname-logoutbtn" @click="logoutHandle">退出登录</button><!--绑定按钮-->
						<button class="companyname-bindbtn" v-if="companyName == ''" @click="bindCompanyHandle">{{bindBtnName}}</button><!--绑定按钮-->
					</div>
				</div>
				<div class="welcome-rightpart welcome-pagepart" v-if="companyName != ''">
					<!-- 绑定企业后的显示  target="_blank" -->
					<router-link class="pagepart-pagecom" to="/welcomeBasic">进入基础版（11项评价指标）▶</router-link>
					<router-link class="pagepart-pagecom pagepart-pagespec" to="/welcomeSpecial">进入专业版（43项评价指标）▶</router-link>
					<!-- 未绑定企业-显示 -->
					<!-- <div class="pagepart-pagecom pagepart-pdisabled">进入基础版（11项评价指标） ➡</div>
					<div class="pagepart-pagecom pagepart-pagespec pagepart-pdisabled">进入专业版（43项评价指标） ➡</div>
					<div class="companypart-hint">请先绑定企业后进入！</div> -->
					<!-- <div class="companypart-box">
						<el-input class="companypart-loginput" v-model="userPhoneVal" placeholder="请输入要绑定的企业"></el-input>
					</div>
					<div class="companypart-hint">请先绑定企业后进入！</div>
					<button class="companypart-submitbtn">提交注册</button> -->
				</div>
				<div class="welcome-rightpart welcome-pagepart" v-else>
					<!-- 未绑定企业-显示 -->
					<div class="pagepart-pagecom pagepart-pdisabled">进入基础版（11项评价指标） ➡</div>
					<div class="pagepart-pagecom pagepart-pagespec pagepart-pdisabled">进入专业版（43项评价指标） ➡</div>
					<div class="companypart-hint">请先绑定企业后进入！</div>
				</div>
			</div>
		</div>
		
		<!-- 提示过期的测评结果-弹框 -->
		<el-dialog
			class="popup-submitconfirm"
			:visible.sync="datedPopupShow"
			:close-on-click-modal="false">
			<div class="submitconfirm-confirmcon">
				<div class="confirmcon-poptle">请刷新您的测评数据</div>
				<div class="confirmcon-puthint">您已有3个月未刷新测评数据，之前的得分即将失效。修正并重新提交测评数据，即可获得最新、最准确的测评结果。</div>
			</div>
			<div slot="footer" class="confirmcon-confirfooter">
				<button class="confirfooter-footbtn" type="primary" @click="closeDatePopup">确 认</button>
			</div>
		</el-dialog>
		
		<!-- 未登录-跳转登录页-弹框 -->
		<LoginHint :goLoginPopupShow="goLoginPopupShow"></LoginHint>
	</div>
</template>

<script>
	import Banner from '../components/banner.vue'
	import LoginHint from '../components/loginHint.vue'
	
	export default {
		data() {
			return {
				bannerTitle: '企业技术创新力在线自测系统', // banner背景图title
				goLoginPopupShow: false, // 未登录-跳转登录页-弹框显示/隐藏
				companyName: '', // 当前登录企业名
				companyInput: '', // 获取绑定企业名
				bindBtnName: '绑定', // 绑定按钮
				userSource: '', // 进入系统用户来源
				datedPopupShow: false, // 提示过期的测评结果-弹框显示/隐藏
				userId: '',
				evalMsg: '',
			}
		},
		computed:{
			
		},
		created() {
			this.getCompany()
			// console.log(this.$route.params)
		},
		mounted(){
			
		},
		components: {
			Banner,
			LoginHint
		},
		methods: {
			closeDatePopup() { // 提示过期的测评结果-弹框关闭
				localStorage.setItem("datedPopupCount", 0)
				this.datedPopupShow = false
			},
			getEvalTime() { // 返回用户测评时间
				let _this = this
				console.log(_this.userId)
				let qsData = _this.$qs.stringify({
					userId: _this.userId,
				});
				_this.$axios({
					method: 'post',
					url: '/user/find/time',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					_this.goLoginPopupShow = false
					// 测评过期的弹框提示--登陆的时候设置datedPopupCount为1，弹框显示，点击弹框中确定设置datedPopupCount为0，弹框不显示
					let datedPopupCount = localStorage.getItem("datedPopupCount") ? localStorage.getItem("datedPopupCount") : 1
					if(res.data.code == 200 && datedPopupCount == 1){
						_this.datedPopupShow = true
						_this.evalMsg = res.data.msg
					}else{
						_this.datedPopupShow = false
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			getCompany() { // 获取企业名称
				let _this = this
				// let qsData = _this.$qs.stringify({
				// 	mobile: ,
				// });
				_this.$axios({
					method: 'post',
					url: '/user/online/find',
					// data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					_this.goLoginPopupShow = false
					if(res.data.code == 200){
						_this.userId = res.data.data.id
						_this.companyName = res.data.data.company
						if(res.data.data.source == 'self') {
							_this.userSource = '中国科协企业创新服务中心网站'
						}
						if(res.data.data.source == 'kczg') { // 这个科协说暂时不接入，就先不做对接。
							_this.userSource = '科创中国'
						}
						if(res.data.data.source == 'lpt') {
							_this.userSource = '中小企业服务平台'
						}
						if(res.data.data.source == 'scei') {
							_this.userSource = '海外专利信息资源库'
						}
						_this.getEvalTime()
					}else{
						_this.companyName = ''
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			bindCompanyHandle() { // 绑定企业名称-修改企业名称
				let _this = this
				let qsData = _this.$qs.stringify({
					company: _this.companyInput, // 输入企业名称
				});
				_this.$axios({
					method: 'post',
					url:'/user/online/bindcompany',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					_this.goLoginPopupShow = false
					if(res.data.code == 200){
						_this.getCompany()
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			logoutHandle() { // 退出登录
				// this.isLogin = false;
				// localStorage.clear('login')
				window.location.href = this.logoutHref;
			},
			// 绑定的企业 ********************************************
			searchSelect(item) {
				if(item || item.length){
					return;
				}
				// this.companyInput = item.value;
				this.companyInput = item.present;
				// console.log(this.companyInput)
			},
			nameTipFn(queryString, cb) { // 搜索企业名称时-推荐结果列表-模糊查询企业名接口
				cb([]); 
				// console.log(queryString)
				let _this = this
				if(_this.companyInput == '') {
					_this.companyHint = '请输入要绑定的企业！'
				}else{
					_this.companyHint = ''
				}
				let qsData = _this.$qs.stringify({
					keyword: _this.companyInput,  
				});
				
				_this.$axios({
					method: 'post',
					url:'/company/suggest',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 200){
						let dataTemp = res.data.data;
						// console.log(dataTemp)
						_this.nameTipData = []
						for(let i = 0; i < dataTemp.length; i++){
							// _this.nameTipData.push({'value': dataTemp[i].title, 'val': dataTemp[i].id});
							_this.nameTipData.push({'value': dataTemp[i].title, 'val': dataTemp[i].id, 'present': dataTemp[i].present});
						}
						// console.log(_this.nameTipData)
						// let results = queryString ? _this.nameTipData.filter(_this.createFilter(queryString)) : [];
						// console.log(results)
						// cb(results);
						cb(_this.nameTipData)
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			// createFilter(queryString) {
			// 	return (data) => {
			// 		return (data.value.indexOf(queryString) === 0);
			// 	};
			// },
			// *************************************************************
		},
		beforeDestory(){
			clearTimeout(this.timer)
			this.timer = null;
		}
	}
</script>

<style scoped>
	.nav-content{margin: 0 auto 60px;/* padding-top: 20px; */background: rgb(245, 245, 245);/* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */}
	.m-b0{margin-bottom: 0;}
	
	.height510{height: 510px;box-sizing: border-box;}
	.article-box{margin: auto;padding: 40px 0;width: 1200px;text-align: left;color: rgb(0, 0, 0);font-weight: 400;font-size: 16px;font-style: normal;text-decoration: none;}
	.article-title{margin-bottom: 10px;font-weight: 700;font-size: 20px;}
	.article-p{margin-bottom: 10px;line-height: 30px;text-align: justify;}
	.article-p:last-child{margin-bottom: 0px;}
	
	/* 欢迎页-内容 */
	.welcome-box{margin: auto;/* padding-bottom: 40px; */width: 1200px;}
	.welcome-box::after{display: block;content: '';clear: both;}
	
	/* 当前登录企业 */
	.welcome-floatpart{float: left;}
	.curenterpart-entercon{}
	.curenterpart-entercon::after{display: block;content: '';clear: both;}
	.entercon-entertle{display: block;float: left;margin-right: 10px;width: 160px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: #fff;box-sizing: border-box;font-size: 16px;}
	.entercon-companyname{display: block;float: left;width: 400px;height: 40px;line-height: 38px;box-sizing: border-box;text-align: left;font-size: 16px;}
	/* .entercon-nocompany{position: relative;display: inline-block;width: 460px;} */
	.companyname-resname{display: inline-block;width: 400px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: #fff;box-sizing: border-box;text-align: center;font-size: 16px;}
	.companyname-bindinput{display: inline-block;width: 400px;height: 40px;background: #fff;}
	.companyname-bindinput /deep/ .el-input__inner{padding: 0 10px;height: 40px;line-height: 40px;border-radius: 0;box-sizing: border-box;font-size: 16px;}
	.curenterpart-enterbtnbox{margin-top: 20px;overflow: hidden;}
	.companyname-logoutbtn{display: block;float: left;margin-right: 10px;padding: 0;width: 160px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: rgb(204, 204, 204);box-sizing: border-box;font-size: 16px;}
	.companyname-bindbtn{/* position: absolute;right: 0px; */display: block;float: right;padding: 0;width: 200px;height: 40px;background: rgb(65, 199, 219);box-sizing: border-box;color: #ffffff;font-size: 16px;}
	
	.retrcon-retrinput{float: left;width: 300px;height: 30px;}
	.retrcon-retrinput /deep/ .el-input__inner{padding: 0 10px;height: 30px;border-radius: 0px;;box-sizing: border-box}
	.retrcon-retrcodebtn{display: block;float: left;margin-left: 10px;padding: 0 10px;line-height: 30px;background: rgb(65, 199, 219);/* background: #E72D6B; */color: #ffffff;}
	.retrcon-retrcodebtn-active{display: block;float: left;margin-left: 10px;padding: 0 10px;height: 30px;line-height: 30px;background: #c8c9cc;box-sizing: border-box;color: #ffffff;}
	.retrcon-retrcodebtn-active.el-button{border-radius: 0px !important;}
	.retrcon-retrhint{display: block;float: left;margin-left: 10px;line-height: 30px;color: #E72D6B;}
	.retrcon-retrtishi{color: rgb(102, 102, 102);}
	
	/* right页面显示 */
	.welcome-rightpart{float: right;position: relative;width: 400px;/* height: 180px; */box-sizing: border-box;}
	.pagepart-pagecom{display: block;width: 400px;height: 40px;line-height: 40px;box-sizing: border-box;background: rgb(102, 204, 51);color: #ffffff;font-weight: 400;font-size: 16px;}
	.pagepart-pagespec{margin-top: 20px;background: rgb(241, 194, 50);}
	.pagepart-pdisabled{background: #cccccc;cursor: not-allowed;}
	
	.companypart-box{margin-bottom: 20px;}
	.companypart-box:last-child{margin-bottom: 0px;}
	.companypart-box::after{display: block;content: '';clear: both;}
	.companypart-loginput{display: inline-block;margin-right: 20px;width: 400px;height: 40px;}
	.companypart-loginput /deep/ .el-input__inner{padding: 0 10px;height: 40px;line-height: 40px;border-radius: 0;box-sizing: border-box;font-size: 16px;}
	
	.companypart-submitbtn{display: block;position: absolute;bottom: 2px;right: 0;padding: 0;width: 150px;height: 30px;line-height: 30px;background: rgb(65, 199, 219);/* background: #E72D6B; */color: rgb(255, 255, 255);font-weight: 400;font-size: 16px;}
	.companypart-hint{/* position: absolute;top: 54px;left: 0; */margin-top: 4px;text-align: left;color: #E72D6B;font-size: 16px;}
	
	/* 确认提交-弹框 */
	.popup-submitconfirm{}
	.popup-submitconfirm /deep/ .el-dialog{margin-top: 30vh !important;width: 600px;/* width: 60%;min-width: 730px; */}
	.popup-submitconfirm /deep/ .el-dialog__header{padding: 0;}
	.popup-submitconfirm /deep/ .el-dialog__headerbtn{top: 24px;}
	.popup-submitconfirm /deep/ .el-dialog__body{padding: 20px 20px;text-align: left;}
	.popup-submitconfirm /deep/ .el-dialog__footer{padding: 0px 20px 20px;}
	.submitconfirm-confirmcon{}
	.confirmcon-poptle{color: #000000;font-weight: 700;font-size: 20px;}
	.confirmcon-puthint{margin-top: 20px;color: #000000;font-weight: 400;font-size: 16px;}
	.confirmcon-fillinhint{margin-top: 10px;color: #000000;font-weight: 400;font-size: 16px;}
	
	.confirmcon-confirfooter{text-align: right;}
	.confirmcon-confirfooter::after{display: block;content: '';clear: both;}
	.regfooter-reghint{display: block;float: left;/* margin-left: 10px; */line-height: 40px;color: #E72D6B;}
	.regfooter-regbtnbox{display: block;float: right;overflow: hidden;}
	.confirfooter-footbtn{display: block;float: right;margin-left: 10px;padding: 0;width: 100px;height: 40px;line-height: 40px;background: rgb(102, 204, 51);/* rgb(65, 199, 219)蓝色  background: #E72D6B; */box-sizing: border-box;color: #ffffff;font-size: 16px;}
	.confirfooter-cancelbtn{line-height: 38px;border: 1px solid rgb(241, 194, 50);/* rgb(102, 102, 102) border: 1px solid #E72D6B; */background: #ffffff;color: #333333;}
	
	
	
</style>
