<template>
  <div class="nav-content m-b0">
    <Banner :bannerTitle="bannerTitle"></Banner>
    <div class="height700">
      <div class="article-box">
        <div class="article-p">《企业技术创新力在线自测系统》从创新投入、知识产权、创新效益、创新组织与管理等角度切入，蕴含有关人才、学历、薪酬、研发经费、设备、技术来源、专利、商标、其他知识产权、技术运营、产品销售、利润、出口、效率、能耗、战略、管理、文化、资本市场、重大项目等多项关联指标，结合科学的评价模型和覆盖全国、全产业链技术领域的创新大数据，能够帮助企业实现快捷、客观、定量、可持续的技术创新能力自我评价，并为企业提供线上自助填测、测评结果分析、报告自动生成和下载等服务支持。</div>
        <div class="article-p">科学、严谨、持续性的技术创新力自我评价能够帮助企业发现自身技术创新能力的优劣势，助力企业扬长补短，提升科技创新能力，推动高质量发展。</div>
      </div>
      <div class="register-login-box">
        <div class="register-regpart">
          <video src="../assets/video/xuanchuan.mp4"
                 :autoplay="videolist.autoplay"
                 :controls="videolist.controls"
                 :controlslist="videolist.controlslist"
                 poster="../assets/images/video_poster.png"
                 style="width:100%;height:100%"></video>
          <!-- <button class="regpart-regbtn">新用户注册</button>  target="_blank" -->
        </div>
        <div class="login-logpart">
          <div class="logpart-box">
            <span class="logpart-loginname"><span class="loginname-between">用户名:</span></span>
            <el-input class="logpart-loginput"
                      v-model="userPhoneVal"
                      placeholder="请输入手机号"></el-input>
          </div>
          <div class="logpart-box">
            <span class="logpart-loginname"><span class="loginname-between">密码:</span></span>
            <el-input class="logpart-loginput"
                      v-model="userPasswordVal"
                      type="password"
                      placeholder="请输入密码"></el-input>
          </div>
          <div class="logpart-box">
            <span class="logpart-loginname"><span class="loginname-between">验证码:</span></span>
            <el-input class="logpart-loginput logpart-codeinput"
                      v-model="userCodeImgVal"
                      placeholder="请输入验证码"></el-input>
            <img class="logpart-codeimg"
                 @click="codeImgChange"
                 :src="codeUrl">
            <!--图形验证码-->
          </div>
          <div class="logpart-box logpart-hintbox">
            <div class="logpart-hint">{{loginHint}}</div>
          </div>
          <!--手机号/密码的组合不正确！-->
          <div class="logpart-box logpart-btnbox">
            <div class="operation-login">
              <router-link class="regpart-regbtn"
                           to="/register">新用户注册</router-link>
              <button class="logpart-logbtn"
                      @click="loginHandle">登录</button>
            </div>
            <div class="logpart-box-retrievebtn"><span class="logpart-retrievebtn"
                    @click="retrievePwdClick">找回密码</span></div>
          </div>
          <!-- <div class="logpart-box">
						<el-input class="logpart-loginput" v-model="userPhoneVal" placeholder="请输入手机号"></el-input>
						<button class="logpart-logbtn" @click="loginHandle">登录</button>
					</div>
					<div class="logpart-box">
						<el-input class="logpart-loginput" v-model="userPasswordVal" type="password" placeholder="请输入密码"></el-input>
						<button class="logpart-retrievebtn" @click="retrievePwdClick">找回密码</button>
					</div> -->
          <!-- show-password -->
          <!-- <div class="logpart-box">
						<div class="logpart-hint">{{loginHint}}</div>
					</div> -->
          <!--手机号/密码的组合不正确！-->
        </div>
      </div>
    </div>
    <!-- 找回密码-弹框 -->
    <el-dialog class="popup-retrievepwd"
               :visible.sync="pwdRetrieveShow"
               :close-on-click-modal="false">
      <span class="retrievepwd-retrtitle">找回密码</span>
      <div class="retrievepwd-retrcon">
        <div class="retrcon-retrbox">
          <el-input class="retrcon-retrinput"
                    placeholder="手机号"
                    v-model="retrievePhoneVal"
                    @blur="phoneChange">
          </el-input><!-- clearable -->
          <span class="retrcon-retrhint">{{phoneHint}}</span>
          <!--该手机号未注册！-->
        </div>
        <div class="retrcon-retrbox">
          <el-input class="retrcon-retrinput"
                    placeholder="验证码"
                    v-model="retrieveCodeVal"
                    @blur="codeChange">
          </el-input>
          <button class="retrcon-retrcodebtn"
                  v-show="show"
                  @click="getCode">获取验证码</button>
          <el-button class="retrcon-retrcodebtn retrcon-retrcodebtn-active"
                     v-show="!show"
                     type="info"
                     disabled>重新发送{{count}}s</el-button>
          <!-- <button :class="['retrcon-retrcodebtn', show == false ? 'retrcon-retrcodebtn-active' : '']" disabled="disabled" v-show="!show">重新发送{{count}}s</button> -->
          <!-- <button class="retrcon-retrcodebtn" @click="settime">{{codeBtnText}}</button> -->
          <span class="retrcon-retrhint"
                v-if="codeHint != ''">{{codeHint}}</span>
          <!--验证码不正确！-->
        </div>
        <div class="retrcon-retrbox">
          <el-input class="retrcon-retrinput"
                    placeholder="新密码"
                    type="password"
                    v-model="newPasswordVal"
                    @blur="newPasswordChange">
          </el-input>
          <span :class="['retrcon-retrhint', pwdHint == '8-16字符，至少同时包含字母和数字' ? 'retrcon-retrtishi' : 'color-red']">{{pwdHint}}</span>
          <!--8-16字符，至少同时包含字母和数字-->
        </div>
        <div class="retrcon-retrbox">
          <el-input class="retrcon-retrinput"
                    placeholder="确认密码"
                    type="password"
                    v-model="repeatPasswordVal"
                    @blur="repeatPasswordChange">
          </el-input>
          <span class="retrcon-retrhint"
                v-if="pwdRepeatHint != ''">{{pwdRepeatHint}}</span>
          <!--两次输入的密码不一致-->
        </div>
      </div>
      <div slot="footer"
           class="retrievepwd-retfooter">
        <button class="retfooter-footbtn"
                type="primary"
                @click="retrievePwdHandle">确 定</button>
        <button class="retfooter-footbtn retfooter-cancelbtn"
                @click="pwdRetrieveShow = false">取 消</button>
        <span class="logincon-retrhint"
              v-show="successHint">找回密码成功！{{pageCount}}秒后自动进入下一页...</span>
        <!--找回密码成功！3秒后自动进入下一页...-->
        <!-- <el-button class="pefooter-submitbtn" type="primary" @click="submitCreateHandle">确 定</el-button>
				<el-button @click="goodsCreatePopupShow = false">取 消</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Banner from '../components/banner.vue'

export default {
  data () {
    return {
      bannerTitle: '企业技术创新力在线自测系统', // banner背景图title
      userPhoneVal: '', // 登录-手机号
      userPasswordVal: '', // 登录-密码
      userCodeImgVal: '', // 登录-图形验证码
      loginHint: '', // 登录错误提示

      pwdRetrieveShow: false, // 找回密码弹框
      retrievePhoneVal: '', // 修改密码-手机号
      retrieveCodeVal: '', // 修改密码-验证码
      newPasswordVal: '', // 修改密码-新密码
      repeatPasswordVal: '', // 修改密码-重复密码
      codeBtnText: '获取验证码', // 修改密码-获取验证码按钮
      countdown: 60, // 修改密码-获取验证码-倒计时
      show: true, // 发送验证码倒计时显示
      count: 0, // 发送验证码倒计时显示

      phoneHint: '', // 手机号验证信息提示
      codeHint: '', // 输入验证码验证信息提示
      pwdHint: '8-16字符，至少同时包含字母和数字', //  输入新密码验证信息提示
      pwdRepeatHint: '',  //  输入重复密码验证信息提示
      successHint: false, // 找回密码成功-提示

      sendCode: false, // 如果手机号未注册，则为false,不可发验证码

      timerClose: '',

      pageCount: 3,
      timerPageShow: false, // 注册成功，3s跳转页面提示
      countTimer: '',

      codeUrl: '',
      videolist: {
        autoplay: false, // 自动播放
        controls: true, //操作(是否显示控件 播放按钮、进度条)
        controlslist: 'nodownload   noplaybackrate'
        /*
        1、nodownload: 不要下载按钮
        2、nofullscreen: 不要全屏按钮
        3、noremoteplayback: 不要远程回放
        4、disablePictureInPicture 不要画中画按钮
        5、noplaybackrate 不要播放速度按钮
        */
      }
    }
  },
  computed: {

  },
  created () {
    // console.log(localStorage.getItem('tel'))
    this.codeImgChange()
  },
  mounted () {
    // console.log(localStorage.getItem('tel'))
  },
  updated () {
    // console.log(localStorage.getItem('tel'))
    // if((!!this.$route.params.tel) && (!!this.$route.params.pwd)) {
    // 	this.loginHandle()
    // }
  },
  components: {
    Banner
  },
  methods: {
    codeImgChange () { // 图形验证码
      this.codeUrl = '/login/vercode?' + Math.random()
    },
    loginHandle () { // 登录操作
      let _this = this
      // console.log('登录')
      if (_this.userPhoneVal == '' || _this.userPasswordVal == '' || _this.userCodeImgVal == '') {
        _this.loginHint = '用户名、密码及验证码不能为空！'
        return
      }
      _this.loginGo(_this.userPhoneVal, _this.userPasswordVal, _this.userCodeImgVal)
    },
    loginGo (mobile, password, codeImg) { // 登录-登录接口
      let _this = this
      let qsData = _this.$qs.stringify({
        mobile: mobile,
        password: password,
        code: codeImg
      });
      _this.$axios({
        method: 'post',
        url: '/login',
        data: qsData,
        baseUrl: _this.urlCommon,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        // headers: {'Content-Type':'application/json;'}
      }).then(function (res) {
        console.log(res)
        // console.log(res.data.code == 200)
        if (res.data.code == 200) {
          _this.loginHint = ''
          // console.log(res.data.url)
          if (res.data.url == '/') {
            _this.$router.push('/welcome');
            localStorage.setItem("datedPopupCount", 1)
          } else {
            //直接跳转
            _this.$router.push(res.data.url);
          }
          // _this.$router.push({name: 'Welcome', params: {url: '/'}});
          //带参数跳转
          // _this.$router.push({path:'/testDemo',query:{setid:123456}});
          // _this.$router.push({name:'testDemo',params:{setid:111222}});
        } else {
          _this.loginHint = res.data.msg
          _this.codeImgChange()
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },
    phoneChange (val) { // 验证手机号是否已注册接口
      console.log('修改密码-手机号=' + val)
      let _this = this
      let qsData = _this.$qs.stringify({
        mobile: _this.retrievePhoneVal,
        type: 'retrieve',
      });
      _this.$axios({
        method: 'post',
        url: '/user/test_mobile',
        data: qsData,
        // baseUrl: _this.urlCommon,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      }).then(function (res) {
        console.log(res)
        // console.log(res.data.code == 200)
        if (res.data.code == 200) {
          _this.phoneHint = ''
          _this.sendCode = true
        } else {
          _this.sendCode = false
          if (res.data.msg == '参数重复') {
            _this.phoneHint = '该手机号未注册'
          } else {
            _this.phoneHint = res.data.msg
          }
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },
    codeChange (val) { // 验证验证码
      console.log('修改密码-验证码=' + val)
      let _this = this
      if (_this.retrieveCodeVal == '') {
        _this.codeHint = '验证码不能为空！'
      } else {
        _this.codeHint = ''
      }
    },
    newPasswordChange (val) { // 用户找回密码-新密码验证
      console.log('新用户注册-新密码=' + val)
      let _this = this
      let regPwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
      if (_this.newPasswordVal == '') {
        _this.pwdHint = '8-16字符，至少同时包含字母和数字'
      } else if (regPwd.test(_this.newPasswordVal)) {
        _this.pwdHint = '8-16字符，至少同时包含字母和数字'
      } else {
        _this.pwdHint = '密码格式不符合要求！'
      }
    },
    repeatPasswordChange (val) { //用户找回密码-重复密码验证
      console.log('新用户注册-重复密码=' + val)
      let _this = this
      if (_this.repeatPasswordVal != '') {
        if (_this.repeatPasswordVal == _this.newPasswordVal) {
          _this.pwdRepeatHint = ''
        } else {
          _this.pwdRepeatHint = '两次输入的密码不一致!'
        }
      } else {
        _this.pwdRepeatHint = '确认密码不能为空！'
      }
    },
    retrievePwdClick () { // 找回密码-弹框显示
      this.pwdRetrieveShow = true
      this.retrievePhoneVal = ''
      this.retrieveCodeVal = ''
      this.newPasswordVal = ''
      this.repeatPasswordVal = ''
      this.codeHint = ''
      this.successHint = false
      this.show = true;
      this.count = 0
      clearInterval(this.setTimer);
      this.setTimer = null
    },
    retrievePwdHandle () { // 找回密码-修改密码接口
      console.log('找回密码');
      let _this = this
      if (_this.retrievePhoneVal != '' && _this.retrieveCodeVal != '' && _this.newPasswordVal != '' && _this.repeatPasswordVal != '') {
        let qsData = _this.$qs.stringify({
          mobile: _this.retrievePhoneVal,
          msgcode: _this.retrieveCodeVal,
          password: _this.newPasswordVal,
          repassword: _this.repeatPasswordVal,
        });
        _this.$axios({
          method: 'post',
          url: '/user/retrieveapwd',
          data: qsData,
          // baseUrl: _this.urlCommon,
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        }).then(function (res) {
          console.log(res)
          // console.log(res.data.code == 200)
          if (res.data.code == 200) {
            _this.codeHint = ''
            _this.successHint = true
            _this.userPasswordVal = ''
            _this.loginHint = ''
            console.log('************' + _this.loginHint)
            console.log(_this.retrievePhoneVal)
            console.log(_this.newPasswordVal)
            _this.goPage()
            // _this.timerClose = setTimeout(function() {
            // 	_this.pwdRetrieveShow = false
            // }, 1000);
          } else {
            if (res.data.msg == '验证码输入错误') {
              _this.codeHint = '验证码输入错误!'
            }
            if (res.data.msg == '验证码已过期') {
              _this.codeHint = '验证码已过期!'
            }
            _this.successHint = false
            _this.pwdRetrieveShow = true
          }
        }).catch(function (e) {
          console.log('error------------------------------error');
          console.log(e.response)
        });
      } else {
        _this.retrievePhoneVal == '' ? _this.phoneHint = '请输入手机号！' : _this.phoneHint = ''
        _this.retrieveCodeVal == '' ? _this.codeHint = '请输入验证码！' : _this.codeHint = ''
        _this.newPasswordVal == '' ? _this.pwdHint = '请输入密码！' : _this.pwdHint = '8-16字符，至少同时包含字母和数字'
        _this.repeatPasswordVal == '' ? _this.pwdRepeatHint = '请输入确认密码！' : _this.pwdRepeatHint = ''
      }
    },
    goPage () { // 注册成功跳转页面-倒计时
      const count_down = 3;
      if (!this.countTimer) {
        this.pageCount = count_down;
        this.countTimer = setInterval(() => {
          if (this.pageCount > 0 && this.pageCount <= count_down) {
            this.pageCount--
          } else {
            this.successHint = false
            clearInterval(this.countTimer)
            this.countTimer = null
            this.pwdRetrieveShow = false
            this.$router.push("/welcome")
            // this.$router.push({name:'Login', params:{tel: this.retrievePhoneVal});
            // this.loginGo(this.retrievePhoneVal, this.newPasswordVal, '')
          }
        }, 1000)
      }
    },
    getCode () { // 获取验证码-倒计时-获取验证码接口
      let _this = this;
      const TIME_COUNT = 120;
      console.log(_this.sendCode + '===========================')
      if (!_this.setTimer && !!_this.sendCode) {
        _this.count = TIME_COUNT;
        _this.show = false;
        _this.setTimer = setInterval(() => {
          if (_this.count > 0 && _this.count <= TIME_COUNT) {
            _this.count--;
          } else {
            _this.show = true;
            clearInterval(_this.setTimer);
            _this.setTimer = null;
            _this.codeHint = ''
          }
        }, 1000)
      }
      if (_this.count > 0 && _this.count <= TIME_COUNT) {
        let qsData = _this.$qs.stringify({
          mobile: _this.retrievePhoneVal,
          type: 'retrieve'
        });
        _this.$axios({
          method: 'post',
          url: '/user/sendmsg',
          data: qsData,
          // baseUrl: _this.urlCommon,
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        }).then(function (res) {
          console.log(res)
          // console.log(res.data.code == 200)
          if (res.data.code == 200) {
            _this.codeHint = ''
          } else {
            _this.codeHint = res.data.msg
          }
        }).catch(function (e) {
          console.log('error------------------------------error');
          console.log(e.response)
        });
      }
    },
  },
  watch: {

  },
  beforeDestory () {
    clearTimeout(this.setTimer)
    this.setTimer = null;
    clearTimeout(this.countTimer)
    this.countTimer = null;
  }
}
</script>

<style scoped>
.nav-content {
  margin: 0 auto 60px; /* padding-top: 20px; */
  background: rgb(
    245,
    245,
    245
  ); /* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */
}
.m-b0 {
  margin-bottom: 0;
}

.height700 {
  height: 700px;
  box-sizing: border-box;
}
.article-box {
  margin: auto;
  padding: 40px 0;
  width: 1200px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  text-decoration: none;
}
.article-p {
  margin-bottom: 10px;
  line-height: 30px;
  text-align: justify;
}
.article-p:last-child {
  margin-bottom: 0px;
}

/* 登录-内容 */
.register-login-box {
  margin: auto; /* padding-bottom: 40px; */
  width: 1200px;
}
.register-login-box::after {
  display: block;
  content: "";
  clear: both;
}

/* 新用户注册 */
.register-regpart {
  float: left;
  width: 703px;
  height: 395px;
}
.regpart-regbtn {
  display: inline-block;
  width: 170px;
  height: 40px;
  line-height: 40px;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 0px;
  background: rgb(255, 255, 255); /* background: #E72D6B; */
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 16px;
}

/* 用户登录 */
.login-logpart {
  float: right;
}
.login-logpart::after {
  display: block;
  content: "";
  clear: both;
}

.logpart-box {
  margin-bottom: 10px;
  position: relative;
}
.logpart-box:nth-child(3) {
  margin-bottom: 5px;
}
.logpart-box::after {
  display: block;
  content: "";
  clear: both;
}
.logpart-hintbox {
  margin-bottom: 0px; /* height: 21px; */
}
.logpart-btnbox {
  margin-left: 74px;
  margin-top: 30px;
  margin-bottom: 0;
}
.logpart-loginname {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}
.loginname-between {
  display: block;
  text-align: justify;
  text-align-last: justify;
}
.logpart-loginput {
  display: block;
  float: left;
  margin-left: 74px; /* margin-right: 20px; */
  width: 370px;
  height: 40px;
  text-align: left;
  font-size: 16px;
}
.logpart-loginput /deep/ .el-input__inner {
  padding: 0 10px;
  height: 40px;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 16px;
}
.logpart-codeinput {
  display: block;
  float: left;
  width: 200px;
}
.logpart-codeimg {
  display: block;
  float: right;
  height: 40px;
  cursor: pointer;
}
/* 新用户注册按钮和登录按钮 */
.operation-login {
  display: flex;
  justify-content: space-between;
}
.logpart-logbtn {
  /* display: block;float: left; */
  padding: 0 10px;
  width: 170px; /* width: 150px; */
  height: 40px;
  line-height: 38px;
  background: rgb(65, 199, 219);
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-weight: 400;
  font-size: 16px;
}
.logpart-box-retrievebtn {
  text-align: right;
}
.logpart-retrievebtn {
  display: inline-block;
  margin-top: 5px;
  text-align: right;
  cursor: pointer;
}
/* 找回密码按钮.logpart-retrievebtn{display: block;float: left;padding: 0 10px;width: 150px;height: 40px;line-height: 38px;background: rgb(255, 255, 255);color: rgb(0, 0, 0);font-weight: 400;font-size: 16px;} */
.logpart-hint {
  position: absolute;
  top: -3px;
  left: 74px;
  text-align: left;
  color: #e72d6b;
  font-size: 16px;
}

/* 找回密码-弹框 */
.popup-retrievepwd {
}
.popup-retrievepwd /deep/ .el-dialog {
  margin-top: 25vh !important; /* width: 60%; */
  width: 700px;
}
.popup-retrievepwd /deep/ .el-dialog__header {
  padding: 0;
}
.popup-retrievepwd /deep/ .el-dialog__headerbtn {
  top: 22px;
}
.popup-retrievepwd /deep/ .el-dialog__body {
  padding: 20px 20px;
  text-align: left;
}
.retrievepwd-retrtitle {
  color: #333333;
  font-weight: 700;
  font-size: 16px;
}
.retrievepwd-retrcon {
}
.retrcon-retrbox {
  margin-top: 20px;
}
.retrcon-retrbox::after {
  display: block;
  content: "";
  clear: both;
}
.retrcon-retrinput {
  float: left;
  width: 300px;
  height: 40px;
}
.retrcon-retrinput /deep/ .el-input__inner {
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 16px;
}
.retrcon-retrcodebtn {
  display: inline-block;
  margin-left: 10px;
  padding: 0; /* padding: 0 10px; */
  width: 120px;
  height: 40px;
  line-height: 40px;
  background: rgb(65, 199, 219);
  box-sizing: border-box;
  color: #ffffff;
  font-size: 16px;
}
.retrcon-retrcodebtn-active {
  background: #c8c9cc;
}
.retrcon-retrcodebtn-active.el-button {
  border-radius: 0px !important;
}
.retrcon-retrhint {
  display: inline-block;
  margin-left: 10px;
  line-height: 40px;
  color: #e72d6b;
  font-size: 16px;
}
.logincon-retrhint {
  display: block;
  float: left;
  margin-left: 10px;
  line-height: 40px;
  color: #e72d6b;
  font-size: 16px;
}
.retrcon-retrtishi {
  color: rgb(102, 102, 102);
}
.color-red {
  color: #e72d6b;
}
/* 保存/取消-按钮 */
.retrievepwd-retfooter {
  text-align: left;
}
.retrievepwd-retfooter::after {
  display: block;
  content: "";
  clear: both;
}
.retfooter-footbtn {
  display: block;
  float: left;
  margin-left: 10px;
  padding: 0;
  width: 100px;
  height: 40px;
  line-height: 40px;
  background: rgb(65, 199, 219); /* background: #E72D6B; */
  box-sizing: border-box;
  color: #ffffff;
  font-size: 16px;
}
.retfooter-footbtn:first-child {
  margin-left: 90px;
}
.retfooter-cancelbtn {
  border: 1px solid rgb(65, 199, 219); /* border: 1px solid #E72D6B; */
  background: #ffffff;
  color: #333333;
}
</style>
