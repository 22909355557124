<template>
  <div class="nav-content">
    <Banner :bannerTitle="bannerTitle"></Banner>
    <!-- 基础版-测评向导页 -->
    <div class="welcome-box">
      <div class="welcome-floatpart welcome-curenterpart">
        <div class="curenterpart-entercon">
          <span class="entercon-entertle">当前登录企业</span>
          <span class="entercon-companyname">{{companyName}}</span>
          <!--北京科华万象科技有限公司-->
        </div>
      </div>
      <div class="welcome-rightpart welcome-pagepart">
        <button class="pagepart-pagecom pagepart-recordlast"
                @click="backStep">返回</button>
      </div>
    </div>
    <!-- 测评向导 -->
    <div class="evaguide-guidemain">
      <div class="guidemain-hideoprea">请根据向导提示，提交测评所需的相关数据：</div>
      <div class="guidemain-gcontent">
        <div class="gcontent-contle">
          <!-- adminNullLength >=5 && financeNullLength >=5 && manageNullLength >=5 所填项皆为空，确认提交按钮不可点 -->
          <button :class="['contle-submitbtn', adminNullLength >= 4 && financeNullLength >= 14 && manageNullLength >= 5 ? 'contle-submitbtn-disable' : '']"
                  :disabled="adminNullLength >= 4 && financeNullLength >= 14 && manageNullLength >= 5"
                  @click="submitDirectHandle">确认提交</button>
          <el-tabs class="contle-ctabs"
                   type="border-card"
                   v-model="activeName"
                   @tab-click="handleClick"
                   :before-leave="beforeTabLeave">
            <el-tab-pane class="ctabs-cpane"
                         name="1">
              <!--adminNullLength大于0，则存在未填项-颜色为灰色,否则为绿色-->
              <!-- 1当前页-未填-黑，加粗、2当前页-全填-绿，加粗、3其他页-未填-灰，正常、4其他页-全填-绿，正常、 -->
              <span v-if="activeName == '1' && adminNullLength > 0"
                    class="cpane-cnav weight-font color-black"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-kegouxuan iconfont icon-tabs"></i>
                <span class="cnav-tabname">行政类</span>
              </span>
              <span v-if="activeName == '1' && adminNullLength == 0"
                    class="cpane-cnav weight-font color-green"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-gouxuan1 iconfont icon-tabs"></i>
                <span class="cnav-tabname">行政类</span>
              </span>
              <span v-if="activeName != '1' && adminNullLength > 0"
                    class="cpane-cnav normal-font color-gray"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-kegouxuan iconfont icon-tabs"></i>
                <span class="cnav-tabname">行政类</span>
              </span>
              <span v-if="activeName != '1' && adminNullLength == 0"
                    class="cpane-cnav normal-font color-green"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-gouxuan1 iconfont icon-tabs"></i>
                <span class="cnav-tabname">行政类</span>
              </span>
              <BasicAdmin ref="adminChild"
                          :adminObj="adminObj"
                          :adminTrueShow="adminTrueShow"></BasicAdmin>
              <div class="cpane-combox cpane-tablemode">
                <span class="tablemode-articledel">您也可以点击右侧按钮下载本类数据的Excel表格文件，填写后再上传。请注意，上传时在本页面中已填写的内容将被替换。</span>
                <form id="fileAdminImport"
                      class="tablemode-operabtn tablemode-upload"
                      name=""
                      data-type="行政"
                      method="POST"
                      enctype="multipart/from-data"
                      action="/company/online/importExcel"
                      @submit="submitUser">
                  <div class="upload-upfilebox">
                    <label id="fileUploadAdd"
                           class="upfilebox-filebtn">上传表格</label>
                    <span class="upfilebox-filename"></span>
                    <input id="fileUploadAdminVal"
                           class="upfilebox-fileinput"
                           type="file"
                           data-type="行政"
                           name="tmpfile"
                           @change="changeFileHandel($event)"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                  </div>
                </form>
                <button class="tablemode-operabtn tablemode-download"
                        @click="downloadAdminTable">下载表格</button>
              </div>
              <div class="cpane-cstep">
                <button class="cstep-stepbtn cstep-nextbtn"
                        type="primary"
                        @click="nextHandle1">下一步</button>
              </div>
            </el-tab-pane>
            <el-tab-pane name="2">
              <span v-if="activeName == '2' && financeNullLength > 0"
                    class="cpane-cnav weight-font color-black"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-kegouxuan iconfont icon-tabs"></i>
                <span class="cnav-tabname">财务类</span>
              </span>
              <span v-if="activeName == '2' && financeNullLength == 0"
                    class="cpane-cnav weight-font color-green"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-gouxuan1 iconfont icon-tabs"></i>
                <span class="cnav-tabname">财务类</span>
              </span>
              <span v-if="activeName != '2' && financeNullLength > 0"
                    class="cpane-cnav normal-font color-gray"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-kegouxuan iconfont icon-tabs"></i>
                <span class="cnav-tabname">财务类</span>
              </span>
              <span v-if="activeName != '2' && financeNullLength == 0"
                    class="cpane-cnav normal-font color-green"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-gouxuan1 iconfont icon-tabs"></i>
                <span class="cnav-tabname">财务类</span>
              </span>
              <BasicFinance ref="financeChild"
                            :financeObj="financeObj"
                            :financeTrueShow="financeTrueShow"
                            :adminTypeClassfy="getTypeClassifyName"></BasicFinance>
              <div class="cpane-combox cpane-tablemode">
                <span class="tablemode-articledel">您也可以点击右侧按钮下载本类数据的Excel表格文件，填写后再上传。请注意，上传时在本页面中已填写的内容将被替换。</span>
                <form id="fileFinanceImport"
                      class="tablemode-operabtn tablemode-upload"
                      name=""
                      data-type="财务"
                      method="POST"
                      enctype="multipart/from-data"
                      action="/company/online/importExcel"
                      @submit="submitUser">
                  <div class="upload-upfilebox">
                    <label id="fileUploadAdd"
                           class="upfilebox-filebtn">上传表格</label>
                    <span class="upfilebox-filename"></span>
                    <input id="fileUploadFinanceVal"
                           class="upfilebox-fileinput"
                           type="file"
                           data-type="财务"
                           name="tmpfile"
                           @change="changeFileHandel($event)"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                  </div>
                </form>
                <button class="tablemode-operabtn tablemode-download"
                        @click="downloadFinanceTable">下载表格</button>
              </div>
              <div class="cpane-cstep">
                <button class="cstep-stepbtn cstep-prevbtn"
                        type="primary"
                        @click="prevHandle2">上一步</button>
                <button class="cstep-stepbtn cstep-nextbtn"
                        type="primary"
                        @click="nextHandle2">下一步</button>
              </div>
            </el-tab-pane>
            <el-tab-pane name="3">
              <span v-if="activeName == '3' && manageNullLength > 0"
                    class="cpane-cnav weight-font color-black"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-kegouxuan iconfont icon-tabs"></i>
                <span class="cnav-tabname">管理类</span>
              </span>
              <span v-if="activeName == '3' && manageNullLength == 0"
                    class="cpane-cnav weight-font color-green"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-gouxuan1 iconfont icon-tabs"></i>
                <span class="cnav-tabname">管理类</span>
              </span>
              <span v-if="activeName != '3' && manageNullLength > 0"
                    class="cpane-cnav normal-font color-gray"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-kegouxuan iconfont icon-tabs"></i>
                <span class="cnav-tabname">管理类</span>
              </span>
              <span v-if="activeName != '3' && manageNullLength == 0"
                    class="cpane-cnav normal-font color-green"
                    slot="label">
                <i class="icon-jiantou iconfont icon-guide"></i>
                <i class="icon-gouxuan1 iconfont icon-tabs"></i>
                <span class="cnav-tabname">管理类</span>
              </span>
              <BasicManage :manageObj="manageObj"
                           :manageTrueShow="manageTrueShow"></BasicManage>
              <div class="cpane-combox cpane-tablemode">
                <span class="tablemode-articledel">您也可以点击右侧按钮下载本类数据的Excel表格文件，填写后再上传。请注意，上传时在本页面中已填写的内容将被替换。</span>
                <form id="fileManageImport"
                      class="tablemode-operabtn tablemode-upload"
                      name=""
                      data-type="管理"
                      method="POST"
                      enctype="multipart/from-data"
                      action="/company/online/importExcel"
                      @submit="submitUser">
                  <div class="upload-upfilebox">
                    <label id="fileUploadAdd"
                           class="upfilebox-filebtn">上传表格</label>
                    <span class="upfilebox-filename"></span>
                    <input id="fileUploadManageVal"
                           class="upfilebox-fileinput"
                           type="file"
                           data-type="管理"
                           name="tmpfile"
                           @change="changeFileHandel($event)"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                  </div>
                </form>
                <button class="tablemode-operabtn tablemode-download"
                        @click="downloadManageTable">下载表格</button>
              </div>
              <div class="cpane-cstep">
                <button class="cstep-stepbtn cstep-prevbtn"
                        type="primary"
                        @click="prevHandle3">上一步</button>
                <button :class="['cstep-stepbtn', 'cstep-nextbtn', adminNullLength >= 4 && financeNullLength >= 14 && manageNullLength >= 5 ? 'cstep-submitbtn-disable' : '']"
                        :disabled="adminNullLength >= 4 && financeNullLength >= 14 && manageNullLength >= 5"
                        type="primary"
                        @click="submitHandle">确认提交</button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <!-- 确认提交-弹框 -->
    <el-dialog class="popup-submitconfirm"
               :visible.sync="submitPopupShow"
               :close-on-click-modal="false">
      <div class="submitconfirm-confirmcon">
        <div class="confirmcon-poptle">确认提交数据？</div>
        <div class="confirmcon-puthint">提交后的新数据将会覆盖原来的数据。</div>
        <div class="confirmcon-fillinhint"
             v-if="adminNullLength > 0 || financeNullLength > 0 || manageNullLength > 0">
          <span>另外，您还有</span>
          <span v-if="adminNullLength > 0">行政类的 {{adminNullLength}} 项</span>
          <span v-if="adminNullLength > 0 && (financeNullLength > 0 || manageNullLength > 0)">、</span>
          <span v-if="financeNullLength > 0">财务类的 {{financeNullLength}} 项</span>
          <span v-if="financeNullLength > 0 && manageNullLength > 0">、</span>
          <span v-if="manageNullLength > 0">管理类的 {{manageNullLength}} 项</span>
          <span>数据未填写或选择。为获得更加准确的测评结果，提交前请尽量完整填写数据！</span>
        </div>
      </div>
      <div slot="footer"
           class="confirmcon-confirfooter">
        <button class="confirfooter-footbtn confirfooter-cancelbtn"
                @click="submitPopupShow = false">取 消</button>
        <button class="confirfooter-footbtn"
                type="primary"
                @click="confirmHandle">确 定</button>
      </div>
    </el-dialog>

    <!-- 返回-返回上一页-弹框 -->
    <el-dialog class="popup-submitconfirm"
               :visible.sync="goBackPopupShow"
               :close-on-click-modal="false">
      <div class="submitconfirm-confirmcon">
        <div class="confirmcon-poptle">确认返回上一页？</div>
        <div class="confirmcon-puthint">若您在本页面填写或改动过数据（包括上传表格），请点击”确认提交“按钮来保存。现在返回首页会导致您本次的填写和改动全部丢失。</div>
      </div>
      <div slot="footer"
           class="confirmcon-confirfooter">
        <button class="confirfooter-footbtn confirfooter-cancelbtn"
                @click="goBackPopupShow = false">取 消</button>
        <button class="confirfooter-footbtn"
                type="primary"
                @click="goBackHandle">确 定</button>
      </div>
    </el-dialog>

    <!-- 未登录-跳转登录页-弹框 -->
    <LoginHint :goLoginPopupShow="goLoginPopupShow"></LoginHint>

    <!-- 数据是否合法-提示 -->
    <el-dialog class="popup-submitconfirm"
               :visible.sync="rationalPopupShow"
               :close-on-click-modal="false">
      <div class="submitconfirm-confirmcon">
        <div class="confirmcon-poptle">提示</div>
        <div class="confirmcon-puthint">您填写的部分数据可能存在错误，或超出了合理范围。</div>
        <div class="confirmcon-puthint">为保证您获得准确的测评结果，请再次检查包含红色标记的数据。</div>
      </div>
      <div slot="footer"
           class="confirmcon-confirfooter">
        <el-checkbox v-model="verifyChecked">今天不再给出此类提示</el-checkbox>
        <button class="confirfooter-footbtn"
                type="primary"
                @click="verifyHandle">确 定</button>
      </div>
    </el-dialog>

    <!-- 缺失关键数据-提示 -->
    <el-dialog class="popup-submitconfirm"
               :visible.sync="keyDatasShow"
               :close-on-click-modal="false">
      <div class="submitconfirm-confirmcon">
        <div class="confirmcon-poptle">提示</div>
        <div class="confirmcon-puthint">您有尚未填写的关键数据项目，已用蓝色标出。</div>
        <div class="confirmcon-puthint">这些数据是您获得准确测评结果的必需，强烈建议您完整、正确填写。</div>
      </div>
      <div slot="footer"
           class="confirmcon-confirfooter">
        <el-checkbox v-model="verifyKeyChecked">今天不再给出此类提示</el-checkbox>
        <button class="confirfooter-footbtn"
                type="primary"
                @click="verifyKeyDataHandle">确 定</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Banner from '../../components/banner.vue'
import BasicAdmin from '../../components/basicAdmin.vue'
import BasicFinance from '../../components/basicFinance.vue'
import BasicManage from '../../components/basicManage.vue'
import LoginHint from '../../components/loginHint.vue'

export default {
  data () {
    return {
      bannerTitle: '企业技术创新力在线自测系统 - 基础版', // banner背景图title
      companyName: '', // 当前绑定企业名称
      activeName: '1', // tab选项

      adminLength: 6, // 行政类总项
      adminArray: [], // 行政类-已填写的插入数组
      adminNullLength: 6, // 行政类-未填写的项数
      adminObj: { // 行政类
        industryType: '', // 所属行业分类
        industry: '', // 所属行业
        province: '', // 所属省份
        employee_count: '', // 职工总数
        dev_count: '', // 研发人数
        dev_payment: '', // 研发人员薪酬收入
        employee_payment: '', // 职工薪酬总收入
      },
      financeLength: 14,
      financeArray: [],
      financeNullLength: 14, // 财务类-未填写的项数
      financeObj: { // 财务类
        curr_dev_cost: '', // 研发经费支出-当年
        prev1_dev_cost: '', // 研发经费支出-上一年
        prev2_dev_cost: '', // 研发经费支出-上上年
        cur_new_amount: '', // 新产品销售额-当年
        prev1_new_amount: '', // 新产品销售额-上一年
        prev2_new_amount: '', // 新产品销售额-上上年
        operating_income: '', // 营业收入
        cur_total_profit: '', // 利润总额-当年
        prev1_total_profit: '', // 利润总额-上一年
        cur_gross_output: '', // 工业总产值-当年
        prev1_gross_output: '', // 工业总产值-上一年
        cur_energy_consumption: '', // 综合能耗-当年
        prev1_energy_consumption: '', // 综合能耗-上一年
        total_assets: '', // 资产总额
      },
      manageLength: 5,
      manageArray: [],
      manageNullLength: 5, // 管理类-未填写的项数
      manageObj: { // 管理类
        cur_pt1_count: '', // 发明专利申请量-当年
        prev1_pt1_count: '', // 发明专利申请量-上一年
        prev2_pt1_count: '', // 发明专利申请量-上上年
        pat_year5_count: '', // 维持5年以上专利量
        pat_pct_count: '', // pct专利量
      },
      indDatas: [ // 行业列表
        // {id:'', text: '全部行业'},
      ],
      sectorValue: '-请选择上市板块-',
      sectorOptions: [ // 上市板块列表
        { value: '', label: '-请选择上市板块-' },
        { value: '主板', label: '主板' },
        { value: '中小板', label: '中小板' },
        { value: '创业板', label: '创业板' },
        { value: '科创板', label: '科创板' },
        { value: '新三板', label: '新三板' },
        { value: '新四板', label: '新四板' },
        { value: '海外', label: '海外' },
        { value: '其他', label: '其他' },
      ],
      platformValue: '省部级',
      platformOptions: [ // 研发平台列表
        { value: '省部级', label: '省部级' },
        { value: '国家级', label: '国家级' }
      ],

      submitPopupShow: false, // 确认提交-弹框显示/隐藏
      goBackPopupShow: false, // 返回上一页-弹框显示/隐藏
      goLoginPopupShow: false, // 未登录-跳转登录页-弹框显示/隐藏

      adminTrueShow: { // 判断每个输入框输入的内容是否合理，合理为true，不合理为false
        employeeShowCount: false,
        devShowCount: false,
        devShowPayment: false,
        employeeShowPayment: false,
      },
      financeTrueShow: { // 财务类
        currShowDevCost: false,
        prev1ShowDevCost: false,
        prev2ShowDevDost: false,
        curShowNewAmount: false,
        prev1ShowNewAmount: false,
        prev2ShowNewAmount: false,
        operatingShowIncome: false,
        cur_totalShowProfit: false,
        prev1ShowTotalProfit: false,
        curShowGrossOutput: false,
        prev1ShowGrossOutput: false,
        curShowEnergyConsumption: false,
        prev1ShowEnergyConsumption: false,
        totalShowAssets: false,
      },
      manageTrueShow: { // 管理类
        curShowPt1Count: false,
        prev1ShowPt1Count: false,
        prev2ShowPt1Count: false,
        patShowYear5Count: false,
        patShowPctCount: false,
      },
      verifyShow: false, // 判断输入内容是否合法，不合法true，合法false
      verifyChecked: false, // 是否勾选今天内不再显示弹框

      popupCount: 0, // 为0时弹出弹框，不等于0时弹框不出现
      rationalPopupShow: false, // 显示/隐藏弹框（提示不合理数据的弹框）
      todayShow: true, // 今天内不再显示弹框，true显示，false不显示

      // 缺失关键数据提示弹框
      nokeyDatas: [], // 缺失关键数据
      keyDatasShow: false, // 缺失关键数据弹框显示
      verifyKeyChecked: false, // 是否勾选今天内不再显示弹框
      popupKeyCount: 0, // 为0时弹出弹框，不等于0时弹框不出现
      keyDatasCount: 2, // 共有几个关键数据
      todayKeyShow: true, // 今天内不再显示弹框，true显示，false不显示

      getTypeClassifyName: '', // 获取所属行业类型
    }
  },
  computed: {

  },
  created () {
    this.getCompany() // 获取绑定企业名称
    this.historicalData() // 返回页面数据
  },
  mounted () {
  },
  updated () {
    this.isHaveNull()
  },
  components: {
    Banner,
    BasicAdmin,
    BasicFinance,
    BasicManage,
    LoginHint
  },
  filters: {
    keepTwoNum (value) {
      value = Number(value)
      return value.toFixed(2)
    }
  },
  methods: {
    getCompany () { // 获取当前绑定企业名称
      let _this = this
      _this.$axios({
        method: 'post',
        url: '/user/online/find',
        // baseUrl: _this.urlCommon,
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      }).then(function (res) {
        console.log(res)
        // console.log(res.data.code == 200)
        if (res.data.code == 401) {
          _this.goLoginPopupShow = true
          return
        }
        if (res.data.code == 200) {
          _this.companyName = res.data.data.company
        } else {
          _this.companyName = ''
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },
    backStep () { // 返回上一页-弹框提示
      let _this = this
      _this.goBackPopupShow = true
    },
    goBackHandle () { // 返回上一页-基础版欢迎页
      let _this = this
      _this.goBackPopupShow = false
      _this.$router.push('/welcomeBasic');
    },

    historicalData () { // 返回页面数据-查询数据接口
      let _this = this
      _this.$axios({
        method: 'post',
        url: '/company/online/historicaldata',
        // baseUrl: _this.urlCommon,
        // headers: {'X-Requested-With': 'XMLHttpRequest'}
        headers: { 'Content-Type': 'application/json' },
      }).then(function (res) {
        console.log(res)
        if (res.data.code == 401) {
          _this.goLoginPopupShow = true // 请登录后操作
          return
        }
        if (res.data.code == 200) {
          _this.adminObj = res.data.data.adminJson;
          _this.financeObj = res.data.data.financeJson;
          _this.manageObj = res.data.data.manageJson;
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },

    handleClick () { // tab切换
      let _this = this
      // console.log(_this.activeName)
      _this.isHaveNull()
      // this.popupCount = 0
    },
    beforeTabLeave (activeName, oldActiveName) {
      let _this = this
      _this.activeName = oldActiveName
      if (oldActiveName == '1') {
        _this.verifyAdminValue()
      } else if (oldActiveName == '2') {
        _this.verifyFinanceValue()
      } else if (oldActiveName == '3') {
        _this.verifyManageValue()
      }
      _this.gudgeDate() // 切换tabs时判断是否过了今天
      console.log('============' + _this.todayShow + '==' + _this.popupCount)

      // let nokeyDatas = _this.nokeyDatas
      // let keyDatasLength = _this.keyDatasCount
      let nokeyDatas
      let keyDatasLength
      // _this.isLegalDeficient(nokeyDatas, keyDatasLength, nowTab, toTab)

      // console.log(nowTab == true)
      if (!_this.verifyShow) { // 数据合法   // 如果数据合法 或者 数据不合法且已经弹出过数据不合法提示 在判断是否缺失关键数据
        _this.rationalPopupShow = false
        _this.popupCount = 0
        _this.isKeyDataHave(oldActiveName) // 判断是否缺失关键数据,获取缺失的关键数据
        nokeyDatas = _this.nokeyDatas
        keyDatasLength = _this.keyDatasCount
        if (nokeyDatas.length >= keyDatasLength) { // 未缺失关键数据  keyDatasLength=2/1
          console.log('数据合法,未缺失关键数据,跳转页面')
          _this.keyDatasShow = false
          _this.popupKeyCount = 0
          // 跳转页面
        } else { // 缺失关键数据
          if (_this.popupKeyCount === 0 && _this.todayKeyShow == true) { // popupKeyCount为0 && 已经过了今天，缺失关键数据弹框显示
            console.log('数据合法,缺失关键数据=' + _this.popupKeyCount)
            _this.keyDatasShow = true
            _this.verifyKeyChecked = false
            return false
          } else {
            console.log('跳转页面' + _this.popupKeyCount)
            _this.keyDatasShow = false
            // 跳转页面
          }
        }
      } else { // 数据不合法
        if (_this.popupCount === 0 && _this.todayShow == true) { // 已经过了今天，数据不合法弹框显示
          console.log('数据不合法,弹框显示')
          _this.rationalPopupShow = true
          _this.verifyChecked = false
          _this.popupCount = 1
          return false
        } else {
          _this.isKeyDataHave(oldActiveName) // 判断是否缺失关键数据,获取缺失的关键数据
          nokeyDatas = _this.nokeyDatas
          keyDatasLength = _this.keyDatasCount
          if (nokeyDatas.length >= keyDatasLength) { // 未缺失关键数据
            console.log('未缺失关键数据,跳转页面')
            _this.keyDatasShow = false
            // 跳转页面
          } else { // 缺失关键数据
            if (_this.popupKeyCount === 0 && _this.todayKeyShow == true) { // popupKeyCount为0 && 已经过了今天，缺失关键数据弹框显示
              console.log('缺失关键数据,跳转页面')
              _this.keyDatasShow = true
              _this.verifyKeyChecked = false
              _this.popupKeyCount = 1
              return false
            } else {
              _this.keyDatasShow = false
              console.log('跳转页面')
              // 跳转页面
            }
          }
        }
      }

      // if(!_this.verifyShow) {
      // 	_this.popupCount = 0
      // 	_this.rationalPopupShow = false
      // }else{
      // 	if(_this.popupCount == 0 && _this.todayShow == true) {
      // 		// _this.popupCount += 1
      // 		// console.log(显示提示)
      // 		_this.rationalPopupShow = true
      // 		_this.verifyChecked = false
      // 		return false
      // 	}else{
      // 		// _this.popupCount = 0
      // 		_this.rationalPopupShow = false
      // 	}
      // }
    },

    // 如果输入数据合法 或者 数据输入不合法，数据弹框已经显示过，则进行是否缺失关键数据的判断
    isKeyDataHave (oldActiveName) {
      let _this = this
      if (oldActiveName == '1') {
        _this.nokeyDatas = _this.$refs.adminChild.noKeyData() // 获取缺失的关键数据-行政类
        _this.keyDatasCount = _this.$refs.adminChild.classifyRadio == '国民经济行业分类' ? 2 : 1
      } else if (oldActiveName == '2') {
        _this.nokeyDatas = _this.$refs.financeChild.noKeyData() // 获取缺失的关键数据-财务类
        _this.keyDatasCount = _this.$refs.adminChild.classifyRadio == '国民经济行业分类' ? 2 : 0
      } else {
        _this.nokeyDatas = [] // 获取缺失的关键数据
        _this.keyDatasCount = 0
      }
    },
    verifyKeyDataHandle () { // 判断是否缺失关键数据-弹框中-点击确定
      this.keyDatasShow = false
      this.popupKeyCount = 1 // 数据合法弹框已显示1次-点击确定之后，关闭弹框，设置popupCount = 1，跳转tab页
      console.log('缺失数据======' + this.popupCount)
      console.log(this.verifyKeyChecked)
      if (this.verifyKeyChecked == true) { // 点击确定，判断是否已勾选“今天内不显示”，已勾选则获取点击确定时的时间
        let date = new Date();
        let year = date.getFullYear(); // 年
        let month = date.getMonth() + 1; // 月
        month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
        let day = date.getDate(); // 日
        day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
        // let hour = date.getHours(); // 时
        // hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
        // let setDate = year + '' + month + '' + day + '' + hour + '0000'
        let setDate = year + '' + month + '' + day + '000000'
        localStorage.setItem("dateKeyShow", setDate)
      }
    },

    // 判断数据是否合法、是否缺失关键数据
    // isLegalDeficient(nokeyDatas, keyDatasLength, nowTab, toTab) {
    isLegalDeficient (nowTab, toTab) {
      // let nokeyDatas = this.nokeyDatas
      // let keyDatasLength = this.keyDatasCount
      // let nowTab = '1'
      // let toTab = '2'
      console.log('**********')
      console.log('当前页=' + this.activeName)
      console.log(nokeyDatas)
      console.log(keyDatasLength)
      let nokeyDatas
      let keyDatasLength
      console.log(nowTab + '---' + toTab)
      if (!this.verifyShow) { // 数据合法   // 如果数据合法 或者 数据不合法且已经弹出过数据不合法提示 在判断是否缺失关键数据
        this.rationalPopupShow = false
        this.popupCount = 0
        this.isKeyDataHave(nowTab) // 判断是否缺失关键数据,获取缺失的关键数据
        nokeyDatas = this.nokeyDatas // 获取缺失的关键数据
        keyDatasLength = this.keyDatasCount
        if (nokeyDatas.length >= keyDatasLength) { // 未缺失关键数据  keyDatasLength=2/1
          console.log('数据合法,未缺失关键数据,跳转页面')
          this.keyDatasShow = false
          this.popupKeyCount = 0
          // 跳转页面
          if (this.activeName == nowTab) { // nowTab = '1'
            this.activeName = toTab // toTab = '2'
          }
        } else { // 缺失关键数据
          if (this.popupKeyCount === 0 && this.todayKeyShow == true) { // popupKeyCount为0 && 已经过了今天，缺失关键数据弹框显示
            console.log('数据合法,缺失关键数据=' + this.popupKeyCount)
            this.keyDatasShow = true
            this.verifyKeyChecked = false
            return false
          } else {
            console.log('跳转页面' + this.popupKeyCount)
            this.keyDatasShow = false
            // 跳转页面
            if (this.activeName == nowTab) { // nowTab = '1'
              this.activeName = toTab // toTab = '2'
            }
          }
        }
      } else { // 数据不合法
        if (this.popupCount === 0 && this.todayShow == true) { // popupCount为0 && 已经过了今天，数据不合法弹框显示
          console.log('数据不合法,弹框显示')
          this.rationalPopupShow = true
          this.verifyChecked = false
          this.popupCount = 1
          return false
        } else {
          this.isKeyDataHave(nowTab) // 判断是否缺失关键数据,获取缺失的关键数据
          nokeyDatas = this.nokeyDatas // 获取缺失的关键数据
          keyDatasLength = this.keyDatasCount
          if (nokeyDatas.length >= keyDatasLength) { // 未缺失关键数据
            console.log('未缺失关键数据,跳转页面')
            this.keyDatasShow = false
            this.popupKeyCount = 0
            // 跳转页面
            if (this.activeName == nowTab) { // nowTab = '1'
              this.activeName = toTab // toTab = '2'
            }
          } else { // 缺失关键数据
            if (this.popupKeyCount === 0 && this.todayKeyShow == true) { // popupKeyCount为0 && 已经过了今天，缺失关键数据弹框显示
              console.log('缺失关键数据,跳转页面')
              this.keyDatasShow = true
              this.verifyKeyChecked = false
              return false
            } else {
              this.keyDatasShow = false
              console.log('跳转页面')
              // 跳转页面
              if (this.activeName == nowTab) { // nowTab = '1'
                this.activeName = toTab // toTab = '2'
              }
            }
          }
        }
      }
      // if(数据合法) {  // 如果数据合法 或者 数据不合法且已经弹出过数据不合法提示 在判断是否缺失关键数据
      // 	判断是否缺失关键数据
      // 	if(未缺失关键数据) {跳转页面}
      // 	else{
      // 		if(缺失关键数据的弹框 == 0 && 已经过了今天) {缺失数据弹框显示}
      // 		else{跳转页面}
      // 	}
      // }else{
      // 	if(数据不合法的弹框 == 0 && 已过了今天) {数据不合法弹框显示}
      // 	else{
      // 		判断是否缺失关键数据
      // 		if(未缺失关键数据) {跳转页面}
      // 		else{
      // 			if(缺失关键数据的弹框 == 0 && 已经过了今天) {缺失数据弹框显示}
      // 			else{跳转页面}
      // 		}
      // 	}
      // }
    },

    prevHandle3 () { // 上一步3
      this.verifyManageValue()
      this.gudgeDate()
      console.log('============' + this.todayShow + '==' + this.popupCount)
      if (!this.verifyShow) { // 合法-直接跳转
        if (this.activeName == '3') {
          this.activeName = '2'
        }
      } else {
        if (this.popupCount == 0 && this.todayShow == true) {
          // this.popupCount += 1
          // console.log(提示)
          this.rationalPopupShow = true
          this.verifyChecked = false
        } else {
          if (this.activeName == '3') {
            this.activeName = '2'
          }
          // this.popupCount = 0
          this.rationalPopupShow = false
        }
      }
    },
    prevHandle2 () { // 上一步2
      this.verifyFinanceValue()
      this.gudgeDate()
      console.log('============' + this.todayShow + '==' + this.popupCount)

      this.isLegalDeficient('2', '1') // 判断数据是否合法、是否缺失关键数据

      // if(!this.verifyShow) {
      // 	if(this.activeName == '2') {
      // 		this.activeName = '1'
      // 	}
      // }else{
      // 	if(this.popupCount === 0 && this.todayShow == true) {
      // 		// this.popupCount += 1
      // 		// console.log(提示)
      // 		this.rationalPopupShow = true
      // 	}else{
      // 		if(this.activeName == '2') {
      // 			this.activeName = '1'
      // 		}
      // 		// this.popupCount = 0
      // 		this.rationalPopupShow = false
      // 	}
      // }
    },
    nextHandle1 () { // 下一步1
      this.verifyAdminValue()
      this.gudgeDate()
      console.log('============' + this.todayShow + '==' + this.popupCount)

      this.isLegalDeficient('1', '2') // 判断数据是否合法、是否缺失关键数据

      // if(!this.verifyShow) {
      // 	if(this.activeName == '1') {
      // 		this.activeName = '2'
      // 	}
      // }else{
      // 	if(this.popupCount === 0 && this.todayShow == true) {
      // 		// this.popupCount += 1
      // 		// console.log(提示)
      // 		this.rationalPopupShow = true
      // 		this.verifyChecked = false
      // 	}else{
      // 		if(this.activeName == '1') {
      // 			this.activeName = '2'
      // 		}
      // 		// this.popupCount = 0
      // 		this.rationalPopupShow = false
      // 	}
      // }
    },
    nextHandle2 () { // 下一步2
      this.verifyFinanceValue()
      this.gudgeDate()
      console.log(this.verifyShow)
      console.log('============' + this.todayShow + '==' + this.popupCount)

      this.isLegalDeficient('2', '3') // 判断数据是否合法、是否缺失关键数据

      // if(!this.verifyShow) {
      // 	if(this.activeName == '2') {
      // 		this.activeName = '3'
      // 	}
      // }else{
      // 	if(this.popupCount === 0 && this.todayShow == true) {
      // 		// this.popupCount += 1
      // 		// console.log(提示)
      // 		this.rationalPopupShow = true
      // 		this.verifyChecked = false
      // 	}else{
      // 		if(this.activeName == '2') {
      // 			this.activeName = '3'
      // 		}
      // 		// this.popupCount = 0
      // 		this.rationalPopupShow = false
      // 	}
      // }
    },
    submitHandle () { // 步骤三-确认提交
      this.verifyManageValue()
      this.gudgeDate()
      console.log('============' + this.todayShow + '==' + this.popupCount)
      if (!this.verifyShow) {
        if (this.activeName == '3') {
          // this.activeName = '4'
          this.submitPopupShow = true
        }
      } else {
        if (this.popupCount === 0 && this.todayShow == true) {
          // this.popupCount += 1
          // console.log(提示)
          this.rationalPopupShow = true
          this.verifyChecked = false
        } else {
          if (this.activeName == '3') {
            // this.activeName = '4'
            this.submitPopupShow = true
          }
          // this.popupCount = 0
          this.rationalPopupShow = false
        }
      }
    },
    verifyHandle () { // 判断填入数据是否合理-弹框-确定
      this.rationalPopupShow = false
      this.popupCount = 1
      console.log(this.verifyChecked)
      if (this.verifyChecked == true) {
        let date = new Date();
        let year = date.getFullYear(); // 年
        let month = date.getMonth() + 1; // 月
        month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
        let day = date.getDate(); // 日
        day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
        // let hour = date.getHours(); // 时
        // hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
        let setDate = year + '' + month + '' + day + '000000'
        localStorage.setItem("dateShow", setDate)
      }
    },
    gudgeDate () { // 判断是否已经过了今天
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
      let day = date.getDate(); // 日
      day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
      // let hour = date.getHours(); // 时
      // hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let nowDate = year + '' + month + '' + day + '000000'
      let nowKeyDate = year + '' + month + '' + day + '000000'
      // let nowKeyDate = year + '' + month + '' + day + '' + hour + '0000'
      let lastDate = localStorage.getItem("dateShow")
      if (lastDate) { // 判断是否有点击“今天不在提示”的时间（判断是否有点击“今天不在提示”的时间，并且现在的时间大于当时点击“今天不再提示”的时间）
        if (nowDate > lastDate) { // 过了今天了
          this.todayShow = true // 判断是否已经过了今天，如果已过今天，数据合法弹框继续显示
        } else { // 还未过今天
          this.todayShow = false // 判断是否已经过了今天，如果已过今天，数据合法弹框不显示
        }
      } else {
        this.todayShow = true
      }
      let lastKeyDate = localStorage.getItem("dateKeyShow") // 获取点击缺失数据弹框-今天不在显示-的时间
      console.log('以前时间-' + lastKeyDate + ' == 当前时间-' + nowKeyDate)
      if (lastKeyDate) { // 判断是否有点击“今天不在提示”的时间（判断是否有点击“今天不在提示”的时间，并且现在的时间大于当时点击“今天不再提示”的时间）
        if (nowKeyDate > lastKeyDate) { // 过了今天了
          this.todayKeyShow = true // 判断是否已经过了今天，如果已过今天，数据合法弹框继续显示
        } else { // 还未过今天
          this.todayKeyShow = false // 判断是否已经过了今天，如果已过今天，数据合法弹框不显示
        }
      } else {
        this.todayKeyShow = true
      }
    },
    verifyAdminValue () { // 判断填入的数据是否合理-行政类
      let adminArray = []
      !this.adminObj.employee_count || (this.adminObj.employee_count >= 0 && this.adminObj.employee_count <= 99999) ? this.adminTrueShow.employeeShowCount = false : this.adminTrueShow.employeeShowCount = true
      adminArray.push(this.adminTrueShow.employeeShowCount)
      !this.adminObj.dev_count || (this.adminObj.dev_count >= 0 && this.adminObj.dev_count <= 99999) ? this.adminTrueShow.devShowCount = false : this.adminTrueShow.devShowCount = true
      adminArray.push(this.adminTrueShow.devShowCount)
      !this.adminObj.dev_payment || (this.adminObj.dev_payment >= 0 && this.adminObj.dev_payment <= 99999) ? this.adminTrueShow.devShowPayment = false : this.adminTrueShow.devShowPayment = true
      adminArray.push(this.adminTrueShow.devShowPayment)
      !this.adminObj.employee_payment || (this.adminObj.employee_payment >= 0 && this.adminObj.employee_payment <= 99999) ? this.adminTrueShow.employeeShowPayment = false : this.adminTrueShow.employeeShowPayment = true
      adminArray.push(this.adminTrueShow.employeeShowPayment)
      this.verifyShow = false
      if (adminArray.length > 0) {
        for (let i = 0; i < adminArray.length; i++) {
          if (adminArray[i] == true) {
            this.verifyShow = true
          }
        }
      }
    },
    verifyFinanceValue () { // 判断填入的数据是否合理-财务类
      let financeArray = []
      !this.financeObj.curr_dev_cost || (this.financeObj.curr_dev_cost >= 0 && this.financeObj.curr_dev_cost <= 99999) ? this.financeTrueShow.currShowDevCost = false : this.financeTrueShow.currShowDevCost = true
      financeArray.push(this.financeTrueShow.currShowDevCost)
      !this.financeObj.prev1_dev_cost || (this.financeObj.prev1_dev_cost >= 0 && this.financeObj.prev1_dev_cost <= 99999) ? this.financeTrueShow.prev1ShowDevCost = false : this.financeTrueShow.prev1ShowDevCost = true
      financeArray.push(this.financeTrueShow.prev1ShowDevCost)
      !this.financeObj.prev2_dev_cost || (this.financeObj.prev2_dev_cost >= 0 && this.financeObj.prev2_dev_cost <= 99999) ? this.financeTrueShow.prev2ShowDevDost = false : this.financeTrueShow.prev2ShowDevDost = true
      financeArray.push(this.financeTrueShow.prev2ShowDevDost)
      !this.financeObj.cur_new_amount || (this.financeObj.cur_new_amount >= 0 && this.financeObj.cur_new_amount <= 99999) ? this.financeTrueShow.curShowNewAmount = false : this.financeTrueShow.curShowNewAmount = true
      financeArray.push(this.financeTrueShow.curShowNewAmount)
      !this.financeObj.prev1_new_amount || (this.financeObj.prev1_new_amount >= 0 && this.financeObj.prev1_new_amount <= 99999) ? this.financeTrueShow.prev1ShowNewAmount = false : this.financeTrueShow.prev1ShowNewAmount = true
      financeArray.push(this.financeTrueShow.prev1ShowNewAmount)
      !this.financeObj.prev1_new_amount || (this.financeObj.prev2_new_amount >= 0 && this.financeObj.prev2_new_amount <= 99999) ? this.financeTrueShow.prev2ShowNewAmount = false : this.financeTrueShow.prev2ShowNewAmount = true
      financeArray.push(this.financeTrueShow.prev2ShowNewAmount)
      !this.financeObj.operating_income || (this.financeObj.operating_income >= -99999 && this.financeObj.operating_income <= 99999) ? this.financeTrueShow.operatingShowIncome = false : this.financeTrueShow.operatingShowIncome = true
      financeArray.push(this.financeTrueShow.operatingShowIncome)
      !this.financeObj.cur_total_profit || (this.financeObj.cur_total_profit >= -99999 && this.financeObj.cur_total_profit <= 99999) ? this.financeTrueShow.cur_totalShowProfit = false : this.financeTrueShow.cur_totalShowProfit = true
      financeArray.push(this.financeTrueShow.cur_totalShowProfit)
      !this.financeObj.prev1_total_profit || (this.financeObj.prev1_total_profit >= -99999 && this.financeObj.prev1_total_profit <= 99999) ? this.financeTrueShow.prev1ShowTotalProfit = false : this.financeTrueShow.prev1ShowTotalProfit = true
      financeArray.push(this.financeTrueShow.prev1ShowTotalProfit)
      !this.financeObj.cur_gross_output || (this.financeObj.cur_gross_output >= 0 && this.financeObj.cur_gross_output <= 99999) ? this.financeTrueShow.curShowGrossOutput = false : this.financeTrueShow.curShowGrossOutput = true
      financeArray.push(this.financeTrueShow.curShowGrossOutput)
      !this.financeObj.prev1_gross_output || (this.financeObj.prev1_gross_output >= 0 && this.financeObj.prev1_gross_output <= 99999) ? this.financeTrueShow.prev1ShowGrossOutput = false : this.financeTrueShow.prev1ShowGrossOutput = true
      financeArray.push(this.financeTrueShow.prev1ShowGrossOutput)
      !this.financeObj.cur_energy_consumption || (this.financeObj.cur_energy_consumption >= 0 && this.financeObj.cur_energy_consumption <= 99999) ? this.financeTrueShow.curShowEnergyConsumption = false : this.financeTrueShow.curShowEnergyConsumption = true
      financeArray.push(this.financeTrueShow.curShowEnergyConsumption)
      !this.financeObj.prev1_energy_consumption || (this.financeObj.prev1_energy_consumption >= 0 && this.financeObj.prev1_energy_consumption <= 99999) ? this.financeTrueShow.prev1ShowEnergyConsumption = false : this.financeTrueShow.prev1ShowEnergyConsumption = true
      financeArray.push(this.financeTrueShow.prev1ShowEnergyConsumption)
      !this.financeObj.total_assets || (this.financeObj.total_assets >= -99999 && this.financeObj.total_assets <= 99999) ? this.financeTrueShow.totalShowAssets = false : this.financeTrueShow.totalShowAssets = true
      financeArray.push(this.financeTrueShow.totalShowAssets)
      this.verifyShow = false
      if (financeArray.length > 0) {
        for (let i = 0; i < financeArray.length; i++) {
          if (financeArray[i] === true) {
            this.verifyShow = true
          }
        }
      }
    },
    verifyManageValue () { // 判断填入的数据是否合理-管理类
      let manageArray = []
      !this.manageObj.cur_pt1_count || (this.manageObj.cur_pt1_count >= 0 && this.manageObj.cur_pt1_count <= 99999) ? this.manageTrueShow.curShowPt1Count = false : this.manageTrueShow.curShowPt1Count = true
      manageArray.push(this.manageTrueShow.curShowPt1Count)
      !this.manageObj.prev1_pt1_count || (this.manageObj.prev1_pt1_count >= 0 && this.manageObj.prev1_pt1_count <= 99999) ? this.manageTrueShow.prev1ShowPt1Count = false : this.manageTrueShow.prev1ShowPt1Count = true
      manageArray.push(this.manageTrueShow.prev1ShowPt1Count)
      !this.manageObj.prev2_pt1_count || (this.manageObj.prev2_pt1_count >= 0 && this.manageObj.prev2_pt1_count <= 99999) ? this.manageTrueShow.prev2ShowPt1Count = false : this.manageTrueShow.prev2ShowPt1Count = true
      manageArray.push(this.manageTrueShow.prev2ShowPt1Count)
      !this.manageObj.pat_year5_count || (this.manageObj.pat_year5_count >= 0 && this.manageObj.pat_year5_count <= 99999) ? this.manageTrueShow.patShowYear5Count = false : this.manageTrueShow.patShowYear5Count = true
      manageArray.push(this.manageTrueShow.patShowYear5Count)
      !this.manageObj.pat_pct_count || (this.manageObj.pat_pct_count >= 0 && this.manageObj.pat_pct_count <= 99999) ? this.manageTrueShow.patShowPctCount = false : this.manageTrueShow.patShowPctCount = true
      manageArray.push(this.manageTrueShow.patShowPctCount)
      this.verifyShow = false
      if (manageArray.length > 0) {
        for (let i = 0; i < manageArray.length; i++) {
          if (manageArray[i] == true) {
            this.verifyShow = true
          }
        }
      }
    },

    isHaveNull () { // 判断是否有未填项，以及有多少未填项
      let _this = this
      // 获取行政类数据，判断有几项未填（总共5项），提交时进行提示
      _this.adminArray = []
      if (_this.adminObj.industry != '') { // 所属行业
        _this.adminArray.push(_this.adminObj.industry)
      }
      let itemList = document.getElementsByClassName('getAdminValue') // 获取行政类input节点
      for (let i = 0; i < itemList.length; i++) { // 遍历获取的getAdminValue元素
        // console.log(itemList[i].children[0].value) // 获取每个输入框中的值
        if (itemList[i].children[0].value != '' && itemList[i].children[0].value != -1) { // 若输入框内容不为空，填进数组
          _this.adminArray.push(itemList[i].children[0].value)
        }
      }
      // console.log(itemList)
      // console.log('------------------------')
      // console.log(_this.adminArray)
      if (_this.adminArray.length < _this.adminLength) {
        _this.adminNullLength = _this.adminLength - _this.adminArray.length
      } else {
        _this.adminNullLength = 0
      }
      // console.log('行政类未填项------------------------')
      // console.log(_this.adminNullLength)

      // 获取财务类数据，判断有几项未填（总共5项），提交时进行提示
      _this.financeArray = []
      let financeItemList = document.getElementsByClassName('getFinanceValue') // 获取行政类input节点
      for (let i = 0; i < financeItemList.length; i++) { // 遍历获取的getAdminValue元素
        // console.log(financeItemList[i].children[0].value) // 获取每个输入框中的值
        if (financeItemList[i].children[0].value != '' && financeItemList[i].children[0].value != -1) { // 若输入框内容不为空，填进数组
          _this.financeArray.push(financeItemList[i].children[0].value)
        }
      }
      // console.log(financeItemList)
      // console.log('------------------------')
      // console.log(_this)
      if (_this.financeArray.length < _this.financeLength) {
        _this.financeNullLength = _this.financeLength - _this.financeArray.length
      } else {
        _this.financeNullLength = 0
      }
      // console.log('财务类未填项------------------------')
      // console.log(_this.financeNullLength)

      // 获取管理类数据，判断有几项未填（总共5项），提交时进行提示
      _this.manageArray = []
      let manageItemList = document.getElementsByClassName('getManageValue') // 获取行政类input节点
      for (let i = 0; i < manageItemList.length; i++) { // 遍历获取的getAdminValue元素
        // console.log(manageItemList[i].children[0].value) // 获取每个输入框中的值
        if (manageItemList[i].children[0].value != '' && manageItemList[i].children[0].value != -1) { // 若输入框内容不为空，填进数组
          _this.manageArray.push(manageItemList[i].children[0].value)
        }
      }
      // console.log(financeItemList)
      // console.log('------------------------')
      // console.log(_this.manageArray)
      if (_this.manageArray.length < _this.manageLength) {
        _this.manageNullLength = _this.manageLength - _this.manageArray.length
      } else {
        _this.manageNullLength = 0
      }
      // console.log('管理类未填项------------------------')
      // console.log(_this.manageNullLength)
    },
    submitDirectHandle () { // 直接-确认提交
      let _this = this
      // _this.submitPopupShow = true
      _this.isHaveNull()

      if (_this.activeName == '1') {
        _this.verifyAdminValue()
      } else if (_this.activeName == '2') {
        _this.verifyFinanceValue()
      } else if (_this.activeName == '3') {
        _this.verifyManageValue()
      }
      _this.gudgeDate()
      console.log(_this.verifyShow)
      console.log('============' + _this.todayShow + '--' + _this.popupCount)

      let nokeyDatas
      let keyDatasLength
      if (!_this.verifyShow) { // 数据合法
        _this.rationalPopupShow = false
        _this.popupCount = 0
        _this.isKeyDataHave(_this.activeName) // 判断是否缺失关键数据,获取缺失的关键数据
        nokeyDatas = _this.nokeyDatas
        keyDatasLength = _this.keyDatasCount
        if (nokeyDatas.length >= keyDatasLength) { // 未缺失关键数据  keyDatasLength=2/1
          console.log('数据合法,未缺失关键数据,跳转页面')
          _this.keyDatasShow = false
          _this.popupKeyCount = 0
          // 跳转页面
          _this.submitPopupShow = true // 显示提交数据的弹框
        } else { // 缺失关键数据
          if (_this.popupKeyCount === 0 && _this.todayKeyShow == true) { // popupKeyCount为0 && 已经过了今天，缺失关键数据弹框显示
            console.log('数据合法,缺失关键数据=' + _this.popupKeyCount)
            _this.keyDatasShow = true
            _this.verifyKeyChecked = false
            return false
          } else {
            console.log('跳转页面' + _this.popupKeyCount)
            _this.keyDatasShow = false
            // 跳转页面
            _this.submitPopupShow = true // 显示提交数据的弹框
          }
        }
      } else { // 数据不合法
        if (_this.popupCount === 0 && _this.todayShow == true) { // 已经过了今天，数据不合法弹框显示
          console.log('数据不合法,弹框显示')
          _this.rationalPopupShow = true
          _this.verifyChecked = false
          _this.popupCount = 1
          return false
        } else {
          _this.isKeyDataHave(_this.activeName) // 判断是否缺失关键数据,获取缺失的关键数据
          nokeyDatas = _this.nokeyDatas
          keyDatasLength = _this.keyDatasCount
          if (nokeyDatas.length >= keyDatasLength) { // 未缺失关键数据
            console.log('未缺失关键数据,跳转页面')
            _this.keyDatasShow = false
            // 跳转页面
            _this.submitPopupShow = true // 显示提交数据的弹框
          } else { // 缺失关键数据
            if (_this.popupKeyCount === 0 && _this.todayKeyShow == true) { // popupKeyCount为0 && 已经过了今天，缺失关键数据弹框显示
              console.log('缺失关键数据,跳转页面')
              _this.keyDatasShow = true
              _this.verifyKeyChecked = false
              _this.popupKeyCount = 1
              return false
            } else {
              _this.keyDatasShow = false
              console.log('跳转页面')
              // 跳转页面
              _this.submitPopupShow = true // 显示提交数据的弹框
            }
          }
        }
      }

      // if(!_this.verifyShow) {
      // 	_this.submitPopupShow = true
      // }else{
      // 	if(_this.popupCount == 0 && this.todayShow == true) {
      // 		// _this.popupCount += 1
      // 		// console.log(提示)
      // 		_this.rationalPopupShow = true
      // 		_this.verifyChecked = false
      // 	}else{
      // 		_this.submitPopupShow = true
      // 		// _this.popupCount = 0
      // 		_this.rationalPopupShow = false
      // 	}
      // }
    },
    confirmHandle () { // 确认提交弹框-提交
      let _this = this
      _this.saveSubmit()
    },
    saveSubmit () { // 提交数据-接口
      let _this = this
      _this.adminObj.industryType = _this.$refs.adminChild.classifyRadio
      if (_this.adminObj.industryType == '战略性新兴产业分类') {
        _this.adminObj.industry = _this.$refs.adminChild.typeIndustryVal2
      } else {
        _this.adminObj.industry = _this.$refs.adminChild.typeIndustryVal1
      }
      let obj = {
        adminJson: {
          industryType: _this.adminObj.industryType,
          industry: _this.adminObj.industry,
          province: _this.adminObj.province,
          employee_count: _this.adminObj.employee_count,
          dev_count: _this.adminObj.dev_count,
          dev_payment: _this.adminObj.dev_payment,
          employee_payment: _this.adminObj.employee_payment
        },
        financeJson: {
          curr_dev_cost: _this.financeObj.curr_dev_cost,
          prev1_dev_cost: _this.financeObj.prev1_dev_cost,
          prev2_dev_cost: _this.financeObj.prev2_dev_cost,
          cur_new_amount: _this.financeObj.cur_new_amount,
          prev1_new_amount: _this.financeObj.prev1_new_amount,
          prev2_new_amount: _this.financeObj.prev2_new_amount,
          operating_income: _this.financeObj.operating_income,
          cur_total_profit: _this.financeObj.cur_total_profit,
          prev1_total_profit: _this.financeObj.prev1_total_profit,
          cur_gross_output: _this.financeObj.cur_gross_output,
          prev1_gross_output: _this.financeObj.prev1_gross_output,
          cur_energy_consumption: _this.financeObj.cur_energy_consumption,
          prev1_energy_consumption: _this.financeObj.prev1_energy_consumption,
          total_assets: _this.financeObj.total_assets,
        },
        manageJson: {
          cur_pt1_count: _this.manageObj.cur_pt1_count,
          prev1_pt1_count: _this.manageObj.prev1_pt1_count,
          prev2_pt1_count: _this.manageObj.prev2_pt1_count,
          pat_year5_count: _this.manageObj.pat_year5_count,
          pat_pct_count: _this.manageObj.pat_pct_count,
        },
      }
      console.log('数据*************************')
      console.log(obj)
      let qsData = _this.$qs.stringify({
        edition: 'b' // b:基础版 p:专业版
      });
      _this.$axios({
        method: 'post',
        url: '/company/online/submit/data?' + qsData,
        data: obj,
        // baseUrl: _this.urlCommon,
        // headers: {'X-Requested-With': 'XMLHttpRequest'}
        headers: { 'Content-Type': 'application/json' },
      }).then(function (res) {
        console.log(res)
        if (res.data.code == 401) {
          _this.goLoginPopupShow = true
          return
        }
        if (res.data.code == 200) {
          _this.$router.push('/basic/basicEvalResult');
          _this.submitPopupShow = false
        }
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },
    submitUser (e) {
      e.preventDefault() || (e.returnValue = false);
    },
    // 上传表格（文件） 并判断文件类型
    changeFileHandel (e) {
      let _this = this
      let type = e.target.dataset.type
      let str = e.target.value
      let qsData
      if (str !== '') {
        let arr = str.split('\\')
        let name = arr[arr.length - 1]
        let typeArr = name.split('.')
        let fileType = typeArr[typeArr.length - 1]
        if (fileType.toLowerCase() == 'xlsx' || fileType.toLowerCase() == 'xls') {
          let formData
          if (type == '行政') {
            formData = new FormData(document.getElementById("fileAdminImport"));
            qsData = _this.$qs.stringify({
              type: '行政', // 取值：行政、财务、管理
              edition: 'b', // 传值：b:基础版 p:专业版
            });
          }
          if (type == '财务') {
            formData = new FormData(document.getElementById("fileFinanceImport"));
            qsData = _this.$qs.stringify({
              type: '财务', // 取值：行政、财务、管理
              edition: 'b', // 传值：b:基础版 p:专业版
            });
          }
          if (type == '管理') {
            formData = new FormData(document.getElementById("fileManageImport"));
            qsData = _this.$qs.stringify({
              type: '管理', // 取值：行政、财务、管理
              edition: 'b', // 传值：b:基础版 p:专业版
            });
          }
          _this.getUpload(formData, qsData, type)
        } else {
          _this.$message({
            type: 'warning',
            message: '格式不符，请重新下载、填写后上传！'
          });
        }
      }
    },
    // 上传文件-接口
    getUpload (formData, qsData, type) {
      let _this = this
      let urlCommon = _this.urlCommon
      _this.$axios({
        method: 'post',
        url: '/company/online/importExcel?' + qsData,
        // baseURL: 'http://192.168.2.190:9940',
        baseURL: urlCommon,
        headers: { 'x-requested-with': 'XMLHttpRequest' },
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
      }).then(res => {
        console.log(res);
        if (res.data.code == 401) {
          _this.goLoginPopupShow = true
          return
        }
        if (res.data.code == 200) {
          _this.$message({
            type: 'success',
            message: '上传成功！',
          })
          if (type == '行政') {
            _this.adminObj = res.data.data.adminJson;
            _this.verifyAdminValue()
          }
          if (type == '财务') {
            _this.financeObj = res.data.data.financeJson;
            _this.verifyFinanceValue()
          }
          if (type == '管理') {
            _this.manageObj = res.data.data.manageJson;
            _this.verifyManageValue()
          }
        } else {
          _this.$message({
            type: 'warning',
            message: res.data.msg,
          })
        }
        document.getElementById('fileUploadAdminVal').value = ''
        document.getElementById('fileUploadFinanceVal').value = ''
        document.getElementById('fileUploadManageVal').value = ''
      }).catch(function (e) {
        console.log(e)
        _this.$message({
          type: 'warning',
          message: '格式不符，请重新下载、填写后上传！',
        });
        document.getElementById('fileUploadAdminVal').value = ''
        document.getElementById('fileUploadFinanceVal').value = ''
        document.getElementById('fileUploadManageVal').value = ''
      })
    },

    // 下载表格
    downloadAdminTable () { // 行政
      let _this = this
      _this.adminObj.industryType = _this.$refs.adminChild.classifyRadio
      if (_this.adminObj.industryType == '战略性新兴产业分类') {
        _this.adminObj.industry = _this.$refs.adminChild.typeIndustryVal2
      } else {
        _this.adminObj.industry = _this.$refs.adminChild.typeIndustryVal1
      }
      let url = '/company/online/download/b/xz'
      let adminJson = {
        industryType: _this.adminObj.industryType,
        industry: _this.adminObj.industry,
        province: _this.adminObj.province,
        employee_count: _this.adminObj.employee_count,
        dev_count: _this.adminObj.dev_count,
        dev_payment: _this.adminObj.dev_payment,
        employee_payment: _this.adminObj.employee_payment
      }
      _this.downloadTable(url, adminJson)
    },
    downloadFinanceTable () { // 财务
      let _this = this
      let url = '/company/online/download/b/cw'
      let financeJson = {
        curr_dev_cost: _this.financeObj.curr_dev_cost,
        prev1_dev_cost: _this.financeObj.prev1_dev_cost,
        prev2_dev_cost: _this.financeObj.prev2_dev_cost,
        cur_new_amount: _this.financeObj.cur_new_amount,
        prev1_new_amount: _this.financeObj.prev1_new_amount,
        prev2_new_amount: _this.financeObj.prev2_new_amount,
        operating_income: _this.financeObj.operating_income,
        cur_total_profit: _this.financeObj.cur_total_profit,
        prev1_total_profit: _this.financeObj.prev1_total_profit,
        cur_gross_output: _this.financeObj.cur_gross_output,
        prev1_gross_output: _this.financeObj.prev1_gross_output,
        cur_energy_consumption: _this.financeObj.cur_energy_consumption,
        prev1_energy_consumption: _this.financeObj.prev1_energy_consumption,
        total_assets: _this.financeObj.total_assets,
      }
      _this.downloadTable(url, financeJson)
    },
    downloadManageTable () { // 管理
      let _this = this
      let url = '/company/online/download/b/gl'
      let manageJson = {
        cur_pt1_count: _this.manageObj.cur_pt1_count,
        prev1_pt1_count: _this.manageObj.prev1_pt1_count,
        prev2_pt1_count: _this.manageObj.prev2_pt1_count,
        pat_year5_count: _this.manageObj.pat_year5_count,
        pat_pct_count: _this.manageObj.pat_pct_count,
      }
      _this.downloadTable(url, manageJson)
    },
    downloadTable (url, data) { // 下载表格接口
      let _this = this
      _this.$axios({
        method: 'post',
        url: url, // edition  传值：b:基础版 p:专业版
        data: data,
        // baseUrl: _this.urlCommon,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json', //请求的数据类型为form data格式
        },
        'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
      }).then(function (res) {
        console.log(res)
        let fileNames = res.headers['content-disposition'].split(';')[1]
        let fileName = decodeURI(fileNames.substring(fileNames.indexOf('=') + 1))
        const blob = new Blob([res.data]);
        const linkNode = document.createElement('a');

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = 'none';
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click();  //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      }).catch(function (e) {
        console.log('error------------------------------error');
        console.log(e.response)
      });
    },
  },
  watch: {
    activeName () {
      clearTimeout(this.popCountTimer)
      this.popCountTimer = null
      this.popCountTimer = setTimeout(() => {
        this.popupCount = 0
        this.popupKeyCount = 0
        this.getTypeClassifyName = this.$refs.adminChild.classifyRadio
        console.log(this.popupCount + '--监听--' + this.popupKeyCount)
      }, 200)
    },
  },
  beforeDestory () {
    clearTimeout(this.popCountTimer)
    this.popCountTimer = null
  }
}
</script>

<style scoped>
.nav-content {
  margin: 0 auto 60px; /* padding-top: 20px; */
  background: rgb(
    245,
    245,
    245
  ); /* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */
}
.article-box {
  margin: auto;
  padding: 40px 0;
  width: 1200px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  text-decoration: none;
}
.article-title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
}
.article-p {
  margin-bottom: 10px;
  line-height: 30px;
}
.article-p:last-child {
  margin-bottom: 0px;
}

/* 欢迎页-内容 */
.welcome-box {
  margin: 0 auto;
  padding: 40px 0;
  width: 1200px;
}
.welcome-box::after {
  display: block;
  content: "";
  clear: both;
}

/* 当前登录企业 */
.welcome-floatpart {
  float: left;
}
.curenterpart-entercon {
  margin-top: 10px;
}
.curenterpart-entercon::after {
  display: block;
  content: "";
  clear: both;
}
.curenterpart-entercon:first-child {
  margin-top: 0;
}
.entercon-entertle {
  display: block;
  float: left;
  margin-right: 10px;
  width: 160px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #dcdfe6;
  background: #fff;
  box-sizing: border-box;
  font-size: 16px;
}
.entercon-backindex {
  background: rgb(200, 198, 198);
}
.entercon-companyname {
  display: block;
  float: left;
  width: 400px;
  height: 40px;
  line-height: 38px;
  border: 1px solid #dcdfe6;
  background: #fff;
  box-sizing: border-box;
  font-size: 16px;
}
.entercon-evaluate {
  margin-left: 170px;
  background: rgb(102, 204, 51);
  color: #fff;
}

/* right页面显示 */
.welcome-rightpart {
  float: right;
  position: relative;
  width: 150px;
  box-sizing: border-box;
}
.pagepart-pagecom {
  display: block;
  width: 150px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  background: rgb(200, 198, 198);
  border: 1px solid #dcdfe6;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.pagepart-recordlast {
  padding: 0;
  line-height: 38px;
}
.pagepart-download {
  margin-top: 10px;
  background: rgb(65, 199, 219);
  color: #ffffff;
}

/* 测评向导 */
.evaguide-guidemain {
  margin: 0 auto; /* padding-bottom: 40px; */
  width: 1200px;
}
.guidemain-hideoprea {
  text-align: left;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 20px;
  font-family: "Segoe UI";
}
/* 行政类数据 */
.guidemain-gcontent {
  margin-top: 20px;
}
.gcontent-contle {
  position: relative;
}
/* 确认提交 */
.contle-submitbtn {
  position: absolute;
  right: 0;
  padding: 0;
  width: 296px;
  height: 50px;
  line-height: 50px;
  background: rgb(228, 228, 228);
  color: rgb(102, 204, 51);
  font-weight: 700;
  font-size: 20px;
  z-index: 10;
}
.contle-submitbtn-disable {
  color: #999999;
}
/* tab切换 */
.contle-ctabs {
}
.ctabs-cpane {
}
.contle-ctabs.el-tabs--border-card {
  border: none;
  box-shadow: none;
  background: none;
}
.contle-ctabs /deep/ .el-tabs__header {
  margin-bottom: 20px;
  box-sizing: border-box;
  background: rgb(228, 228, 228);
}
.contle-ctabs /deep/ .el-tabs__nav-wrap {
}
.contle-ctabs /deep/ .el-tabs__nav-scroll {
  width: 900px;
  overflow: visible;
  box-sizing: border-box;
}
.contle-ctabs /deep/ .el-tabs__nav {
  float: none;
  height: 50px;
  box-sizing: border-box;
}
.contle-ctabs /deep/ .el-tabs__item {
  padding: 0;
  width: 300px !important;
  height: 50px;
  border: none;
  box-sizing: border-box;
}
.contle-ctabs /deep/ .el-tabs__item:last-child {
  padding: 0 !important;
}
.contle-ctabs.el-tabs--border-card
  /deep/
  .el-tabs__header
  .el-tabs__item.is-active {
  background: none;
}
.cpane-cnav {
  display: block;
  height: 50px;
  color: #999999;
  font-weight: 400;
  font-size: 20px;
}
.el-tabs__item.is-active .cpane-cnav {
  font-weight: 700;
  color: #000000;
}
.cpane-cnav .icon-tabs {
  height: 50px;
  font-weight: 700;
  font-size: 20px;
}
.cpane-cnav .icon-guide {
  float: right;
  margin-right: -8px;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  color: rgb(204, 204, 204);
  font-size: 20px;
}
.el-tabs__item.is-active {
  font-weight: 700;
}
.weight-font {
  font-weight: 700 !important;
}
.normal-font {
  font-weight: 400 !important;
}
.color-green {
  color: rgb(102, 204, 51) !important;
}
.color-gray {
  color: #999999 !important;
}
.color-black {
  color: #000000 !important;
}
.weight-normal {
  font-weight: 400;
}
.color-green {
  color: rgb(102, 204, 51);
}
.cnav-tabname {
  display: inline-block;
  margin-left: 5px;
  height: 50px;
  line-height: 50px;
}
.cpane-csubmit {
  color: rgb(102, 204, 51) !important;
  font-weight: 700 !important;
}
/* .color-grey{color: #999999;} */
/* tabs内容 */
.contle-ctabs /deep/ .el-tabs__content {
  padding: 0;
}
.cpane-combox {
  padding: 50px 40px;
  box-sizing: border-box;
  background: #e4e4e4;
}
.cpane-admincon {
  text-align: left;
}
.combox-contitle {
  position: relative;
  padding-left: 35px;
  line-height: 30px;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
}
.combox-contitle .icon-title {
  position: absolute;
  left: 0;
  font-size: 22px;
}
.combox-contitle .icon-fintitle {
  position: absolute;
  left: 0;
  font-size: 26px;
}
.combox-conmain {
}
.conmain-mainitem {
  margin-top: 40px;
}
.conmain-mainitem:first-child {
  margin-top: 50px;
}
.conmain-mainitem::after {
  display: block;
  content: "";
  clear: both;
}
.mainitem-itemname {
  position: absolute;
  width: 120px;
  line-height: 30px;
  text-align: right;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
} /*input框标题字号*/
.dis-block {
  display: block;
}
.dis-block:last-child {
  margin-top: -5px;
}
.mainitem-itemdetail {
  position: relative;
  margin-left: 130px;
}
.itemdetail-cdetail {
  line-height: 30px;
  font-size: 16px;
}
.itemdetail-cdetail::after {
  display: block;
  content: "";
  clear: both;
}
.cdetail-iptbox {
  display: block;
  float: left;
  margin-right: 10px;
  width: 300px;
}
.cdetail-iptbox /deep/ .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-iptkeyword {
  display: block;
  float: left;
  margin-right: 5px;
  width: 200px;
}
.cdetail-iptkeyword /deep/ .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-iptstyle {
  display: block;
  float: left;
  margin-right: 10px;
  width: 300px;
}
.cdetail-iptstyle /deep/ .el-input__inner {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.icon-search {
  display: block;
  float: left;
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
}
.cdetail-hightech {
  display: block;
  float: left;
  margin-right: 10px;
}
.cdetail-hightech /deep/ .el-radio-button__inner {
  padding: 0 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-hightech
  /deep/
  .el-radio-button__orig-radio:checked
  + .el-radio-button__inner {
  background: rgb(102, 204, 51);
  border-color: rgb(102, 204, 51);
  box-shadow: -1px 0 0 0 rgb(102, 204, 51);
}
.cdetail-marketsector {
  display: block;
  float: left;
  margin-right: 10px;
}
.cdetail-industrybox {
  width: 400px;
}
.cdetail-marketsector /deep/ .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 0;
  font-size: 16px;
}
.cdetail-marketsector /deep/ .el-input__icon {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
}
/* 提示 */
.hint-msg {
  margin-top: 5px;
  display: block;
  color: #999999;
  font-weight: 400;
  font-size: 16px;
}
.hint-msg:first-child {
  margin-top: 5px;
}
.hint-red {
  color: rgb(255, 0, 0);
}

/* 财务类 */
.cpane-adminfinance {
}
.w-140 {
  width: 140px;
}
.cdetail-namestyle {
  display: block;
  float: left;
  margin: 0 10px 0 0;
}
.cdetail-namestyle:last-child {
  margin: 0;
}

.cpane-tablemode {
  margin-top: 20px;
  padding: 20px 40px;
  overflow: hidden;
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.tablemode-articledel {
  display: block;
  float: left;
  height: 30px;
  line-height: 30px;
}
.tablemode-operabtn {
  display: block;
  float: right;
  padding: 0;
  width: 100px;
  height: 30px;
  line-height: 28px;
  border: 1px solid #dcdfe6;
  background: #ffffff;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}
.tablemode-download {
  margin-right: 10px;
}
/* 上传文件 */
.upload-upfilebox {
  position: relative;
}
.upfilebox-filebtn {
  display: block;
  width: 100px;
  height: 30px;
}
.upfilebox-filename {
}
.upfilebox-fileinput {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 30px;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

/* 上一页/下一页 */
.cpane-cstep {
  margin-top: 20px;
  overflow: hidden;
}
.cstep-stepbtn {
  display: block;
  padding: 0 100px;
  height: 40px;
  line-height: 40px;
  background: rgb(102, 204, 51); /* background: #E72D6B; */
  box-sizing: border-box;
  color: #ffffff;
  font-size: 16px;
}
.cstep-prevbtn {
  float: left;
}
.cstep-nextbtn {
  float: right;
}
.cstep-submitbtn-disable {
  background: #999999;
}

/* 确认提交-弹框 */
.popup-submitconfirm {
}
.popup-submitconfirm /deep/ .el-dialog {
  margin-top: 30vh !important;
  width: 600px; /* width: 60%;min-width: 730px; */
}
.popup-submitconfirm /deep/ .el-dialog__header {
  padding: 0;
}
.popup-submitconfirm /deep/ .el-dialog__headerbtn {
  top: 24px;
}
.popup-submitconfirm /deep/ .el-dialog__body {
  padding: 20px 20px;
  text-align: left;
}
.popup-submitconfirm /deep/ .el-dialog__footer {
  padding: 0px 20px 20px;
}
.submitconfirm-confirmcon {
}
.confirmcon-poptle {
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}
.confirmcon-puthint {
  margin-top: 20px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}
.confirmcon-fillinhint {
  margin-top: 10px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}

.confirmcon-confirfooter {
  text-align: right;
}
.confirmcon-confirfooter::after {
  display: block;
  content: "";
  clear: both;
}
.regfooter-reghint {
  display: block;
  float: left; /* margin-left: 10px; */
  line-height: 40px;
  color: #e72d6b;
}
.regfooter-regbtnbox {
  display: block;
  float: right;
  overflow: hidden;
}
.confirfooter-footbtn {
  display: block;
  float: right;
  margin-left: 10px;
  padding: 0;
  width: 100px;
  height: 40px;
  line-height: 40px;
  background: rgb(
    102,
    204,
    51
  ); /* rgb(65, 199, 219)蓝色  background: #E72D6B; */
  box-sizing: border-box;
  color: #ffffff;
  font-size: 16px;
}
.confirfooter-cancelbtn {
  line-height: 38px;
  border: 1px solid rgb(102, 204, 51); /* rgb(102, 102, 102) border: 1px solid #E72D6B; */
  background: #ffffff;
  color: #333333;
}

.confirmcon-confirfooter .el-checkbox {
  line-height: 40px;
}
.confirmcon-confirfooter .el-checkbox /deep/.el-checkbox__label {
  padding-left: 5px;
}

/* 未登录-提示弹框 */
.popup-nologin /deep/ .el-dialog {
  margin-top: 30vh !important;
  width: 600px; /* width: 30%;min-width: 500px; */
}
.popup-nologin /deep/ .el-dialog__header {
  padding: 0;
}
.popup-nologin /deep/ .el-dialog__headerbtn {
  top: 20px;
}
.popup-nologin /deep/ .el-dialog__body {
  padding: 20px 20px;
  text-align: center;
}
.popup-nologin /deep/ .el-dialog__footer {
  padding: 0px 20px 20px;
}
.nologin-logincon {
}
.logincon-loghint {
  margin: 40px 0;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
}
.color-blue {
  color: #1d59b2;
}

/*  */
.myselect {
}
</style>
