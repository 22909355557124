<template>
	<!-- <div> -->
		<div class="cpane-combox cpane-admincon cpane-adminfinance">
			<div class="combox-contitle"><i class="icon-renyuanguanli iconfont icon-fintitle"></i>管理类数据</div>
			<div class="combox-conmain">
				<div class="conmain-mainitem">
					<span class="mainitem-itemname mainitem-fmzlsqlname"><span class="dis-block">发明专利</span><span class="dis-block">申请量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.curShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.cur_pt1_count" placeholder="100" @input="manageObj.cur_pt1_count = checkNumber($event, manageObj.cur_pt1_count)" @blur="manageObj.cur_pt1_count = clearNumber(manageObj.cur_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.prev1ShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.prev1_pt1_count" placeholder="100" @input="manageObj.prev1_pt1_count = checkNumber($event, manageObj.prev1_pt1_count)" @blur="manageObj.prev1_pt1_count = clearNumber(manageObj.prev1_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件，上上年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.prev2ShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.prev2_pt1_count" placeholder="100" @input="manageObj.prev2_pt1_count = checkNumber($event, manageObj.prev2_pt1_count)" @blur="manageObj.prev2_pt1_count = clearNumber(manageObj.prev2_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件</span>
						</div>
						<span class="hint-msg">当年、上一年度及上上年度企业申请的发明专利总量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">维持5年以上</span><span class="dis-block">专利量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.patShowYear5Count ? 'warning-hintbg' : '']" v-model="manageObj.pat_year5_count" placeholder="100" @input="manageObj.pat_year5_count = checkNumber($event, manageObj.pat_year5_count)" @blur="manageObj.pat_year5_count = clearNumber(manageObj.pat_year5_count)"></el-input>
							件
						</div>
						<span class="hint-msg">截至当年年末，企业申请且自授权后维持时长在5年及以上专利量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">PCT专利量</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.patShowPctCount ? 'warning-hintbg' : '']" v-model="manageObj.pat_pct_count" placeholder="100" @input="manageObj.pat_pct_count = checkNumber($event, manageObj.pat_pct_count)" @blur="manageObj.pat_pct_count = clearNumber(manageObj.pat_pct_count)"></el-input>
							件
						</div>
						<span class="hint-msg">截至当年年末，企业经PCT途径申请的专利量。</span>
					</div>
				</div>
			</div>
		</div>
	<!-- </div> -->
</template>

<script>
	export default {
		data(){
			return{
				
			}
		},
		props:{
			manageObj: {
				type: Object,
				default() {
					return {}
				}
			},
			manageTrueShow: {
				type: Object,
				default() {
					return {}
				}
			},
		},
		components:{
	
		},
		created() {
			
		},
		// mounted() {
		// 	console.log(this.$parent)
		// 	this.$parent.$parent.$parent.isHaveNull()
		// },
		updated() {
			this.$parent.$parent.$parent.isHaveNull()
		},
		methods: {
			
			// input控制输入内容
			checkNumber(e, val) { // 输入整数
				this.$forceUpdate()
				// if(val) {
					// console.log(val)
					// val = val.replace(/^0/g, '')
					// val = val.replace(/^0/g, "") // 必须保证第一个为数字而不是0
					val = (val.match(/^\d{0,7}/g)[0]) || null
					// val = (val.match(/^[1-9]([0-9]*)|^[0-9]/g)[0]) || null
					return val
				// }
			},
			clearNumber(val) { // 保留小数点后两位，不满两位则补零
				this.$parent.$parent.$parent.verifyManageValue()
				if(val) {
					return parseInt(val)
				}else{
					return val
				}
			},
			checkFloat(val) { // 最多输入两位小数
				// if(val) {
					val = val.toString()
					val = val.replace(/^\./g, "") // 必须保证第一个为数字而不是点
					// val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || null
					val = (val.match(/^\d{0,9}\.\d\d|^\d{0,9}\.\d|^\d{0,9}\.|^\d{0,9}/g)[0]) || null
					// console.log(val)
				// }
				return val
			},
			clearFloat(val) { // 保留小数点后两位，不满两位则补零
				// console.log(val)
				this.$parent.$parent.$parent.verifyManageValue()
				if(val) {
					val = val.toString()
					if(val.indexOf(".") < 0) {
						val = val + ".00"
					}else if(val.indexOf(".") > 0) {
						let decimalNum = val.split('.')[1]
						if(decimalNum != '') {
							if(decimalNum.length== 1) {
								val = val + "0"
							}
						}else {
							val = val + "00"
						}
					}
					return parseFloat(val).toFixed(2)
					// return val
				}else{
					return val
				}
			},
		},
		watch: {
			
		}
	}
</script>

<style>
	/* tabs内容 */
	.contle-ctabs /deep/ .el-tabs__content{padding: 0;}
	.cpane-combox{padding: 20px;box-sizing: border-box;background: #e4e4e4;}
	.cpane-admincon{text-align: left;}
	.combox-contitle{position: relative;padding-left: 35px;line-height: 30px;color: #000000;font-weight: 700;font-size: 22px;}
	.combox-contitle .icon-title{position: absolute;left: 0;font-size: 22px;}
	.combox-contitle .icon-fintitle{position: absolute;left: 0;font-size: 26px;}
	.combox-conmain{}
	.conmain-mainitem{position: relative;margin-top: 40px;}
	.conmain-mainitem:first-child{margin-top: 50px;}
	.conmain-mainitem::after{display: block;content: '';clear: both;}
	.mainitem-itemname{position: absolute;left: -5px;width: 120px;line-height: 30px;text-align: right;color: #000000;font-weight: 700;font-size: 20px;}/*input框标题字号*/
	/* .mainitem-fmzlsqlname{left: -5px;} */ /* 发明专利申请量 */
	.dis-block{display: block;}
	.dis-block:last-child{margin-top: -5px;}
	.mainitem-itemdetail{position: relative;margin-left: 130px;}
	.itemdetail-cdetail{line-height: 30px;font-size: 16px;}
	.itemdetail-cdetail::after{display: block;content: '';clear: both;}
	.cdetail-iptbox{display: block;float: left;margin-right: 10px;width: 300px;}
	.cdetail-iptbox .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-iptkeyword{display: block;float: left;margin-right: 5px;width: 200px;}
	.cdetail-iptkeyword .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-iptstyle{display: block;float: left;margin-right: 10px;width: 300px;}
	.cdetail-iptstyle .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.warning-hintbg .el-input__inner{background: rgb(255, 204, 204);}
	.cdetail-iptstyle .el-input__inner:focus{border: 1px solid #409eff;background: #fff !important;}
	.icon-search{display: block;float: left;width: 60px;height: 30px;line-height: 30px;font-size: 24px;}
	.cdetail-hightech{display: block;float: left;margin-right: 10px;}
	.cdetail-hightech .el-radio-button__inner{padding: 0 30px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-hightech .el-radio-button__orig-radio:checked+.el-radio-button__inner{background: rgb(102, 204, 51);border-color: rgb(102, 204, 51);box-shadow: -1px 0 0 0 rgb(102, 204, 51);}
	.cdetail-marketsector{display: block;float: left;margin-right: 10px;}
	.cdetail-industrybox{width: 400px;}
	.cdetail-marketsector .el-input__inner{height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-marketsector .el-input__icon{height: 30px;line-height: 30px;font-size: 16px;}
	/* 提示 */
	.hint-msg{margin-top: 5px;display: block;color: #999999;font-weight: 400;font-size: 16px;}
	.hint-msg:first-child{margin-top: 5px;}
	.hint-red{color: rgb(255, 0, 0);}
	/* border: 1px solid #DCDFE6;box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%); */
	
	/* 财务类 */
	.cpane-adminfinance{}
	.w-140{width: 140px;}
	.cdetail-namestyle{display: block;float: left;margin: 0 10px 0 0;}
	.cdetail-namestyle:last-child{margin: 0;}
	
	.cpane-tablemode{margin-top: 20px;overflow: hidden;text-align: left;color: rgb(0, 0, 0);font-size: 16px;}
	.tablemode-articledel{display: block;float: left;height: 30px;line-height: 30px;}
	.tablemode-operabtn{display: block;float: right;padding: 0;width: 100px;height: 30px;line-height: 28px;border: 1px solid #DCDFE6;background: #ffffff;box-sizing: border-box;text-align: center;cursor: pointer;}
	.tablemode-download{margin-right: 10px;}
	/* 上传文件 */
	.upload-upfilebox{position: relative;}
	.upfilebox-filebtn{display: block;width: 100px;height: 30px;}
	.upfilebox-filename{}
	.upfilebox-fileinput{display: block;position: absolute;top: 0;left: 0;width: 100px;height: 30px;opacity: 0;z-index: 20;cursor: pointer;}
	
</style>
