<template>
	<div class="nav-content m-b0">
		<Banner :bannerTitle="bannerTitle"></Banner>
		<div class="height510">
			<div class="article-box">
				<div class="article-title">欢迎您，来自{{userSource}}的用户！</div>
				<div class="article-p">《企业技术创新力在线自测系统》从创新投入、知识产权、创新效益、创新组织与管理等角度切入，蕴含有关人才、学历、薪酬、研发经费、设备、技术来源、专利、商标、其他知识产权、技术运营、产品销售、利润、出口、效率、能耗、战略、管理、文化、资本市场、重大项目等多项关联指标，结合科学的评价模型和覆盖全国、全产业链技术领域的创新大数据，能够帮助企业实现快捷、客观、定量、可持续的技术创新能力自我评价，并为企业提供线上自助填测、测评结果分析、报告自动生成和下载等服务支持。</div>
				<div class="article-p">《企业技术创新力在线自测系统 - 基础版》提供了较为精简的11项评价指标，可为企业节省填写指标数据的时间和精力，帮助企业开展更为快捷的技术创新力在线测评工作。</div>
			</div>
			<div class="welcome-box">
				<div class="welcome-floatpart welcome-curenterpart">
					<div class="curenterpart-entercon">
						<span class="entercon-entertle">当前登录企业</span>
						<span class="entercon-companyname">{{companyName}}</span><!--北京科华万象科技有限公司-->
					</div>
					<div class="curenterpart-entercon"><!-- target="_blank"-->
						<router-link class="entercon-entertle entercon-backindex" to="/welcome">返回</router-link>
						<router-link class="entercon-companyname entercon-evaluate" to="/basic/basicEvalGuide">开始基础版测评</router-link>
					</div>
				</div>
				<div class="welcome-rightpart welcome-pagepart">
					<span class="pagepart-pagecom pagepart-recordlast">{{prevReportDate}}</span><!--上次测评报告：<span><span>26天</span>前</span>-->
					<a class="pagepart-pagecom pagepart-download" v-if="prevReportId != ''" :href="'/report/download?id=' + prevReportId">下载上次测评报告</a>
					<span class="pagepart-pagecom pagepart-download download-noreport" v-else>下载上次测评报告</span>
				</div>
			</div>
		</div>
		
		<!-- 未登录-跳转登录页-弹框 -->
		<LoginHint :goLoginPopupShow="goLoginPopupShow"></LoginHint>
	</div>
</template>

<script>
	import Banner from '../components/banner.vue'
	import LoginHint from '../components/loginHint.vue'
	
	export default {
		data() {
			return {
				bannerTitle: '企业技术创新力在线自测系统 - 基础版', // banner背景图title
				companyName: '', // 当前绑定企业名称
				prevReportDate: '', // 上次报告时间
				prevReportId: '', // 上次报告id
				goLoginPopupShow: false, // 未登录-跳转登录页-弹框显示/隐藏
				userSource: '', // 进入系统用户来源
			}
		},
		computed:{
			
		},
		created() {
			this.getCompany()
			this.prevReport()
		},
		mounted(){
			
		},
		components: {
			Banner,
			LoginHint
		},
		methods: {
			getCompany() { // 获取企业名称
				let _this = this
				// let qsData = _this.$qs.stringify({
				// 	mobile: _this.retrievePhoneVal,
				// });
				_this.$axios({
					method: 'post',
					url: '/user/online/find',
					// data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					_this.goLoginPopupShow = false
					if(res.data.code == 200){
						_this.companyName = res.data.data.company
						if(res.data.data.source == 'self') {
							_this.userSource = '中国科协企业创新服务中心网站'
						}
						if(res.data.data.source == 'kczg') { // 这个科协说暂时不接入，就先不做对接。
							_this.userSource = '科创中国'
						}
						if(res.data.data.source == 'lpt') {
							_this.userSource = '中小企业服务平台'
						}
						if(res.data.data.source == 'scei') {
							_this.userSource = '海外专利信息资源库'
						}
					}else{
						_this.companyName = ''
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			prevReport() { // 返回上次报告时间
				let _this = this
				let qsData = _this.$qs.stringify({
					templateName: '科协基础版模板', // 模板名称（科协基础版模板/科协专业版模板）
				});
				_this.$axios({
					method: 'post',
					url: '/user/online/days',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					_this.goLoginPopupShow = false
					if(res.data.code == 200){
						if(res.data.data.date == '无上次测评报告') {
							_this.prevReportDate = res.data.data.date
							_this.prevReportId = ''
						}else{
							_this.prevReportDate = res.data.data.date
							_this.prevReportId = res.data.data.id
						}
					}else{
						_this.prevReportDate = res.data.data.date
						_this.prevReportId = ''
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
		},
		beforeDestory(){
			
		}
	}
</script>

<style scoped>
	.nav-content{margin: 0 auto 60px;/* padding-top: 20px; */background: rgb(245, 245, 245);/* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */}
	.m-b0{margin-bottom: 0;}
	
	.height510{height: 510px;box-sizing: border-box;}
	.article-box{margin: auto;padding: 40px 0;width: 1200px;text-align: left;color: rgb(0, 0, 0);font-weight: 400;font-size: 16px;font-style: normal;text-decoration: none;}
	.article-title{margin-bottom: 10px;font-weight: 700;font-size: 20px;}
	.article-p{margin-bottom: 10px;line-height: 30px;text-align: justify;}
	.article-p:last-child{margin-bottom: 0px;}
	
	/* 欢迎页-内容 */
	.welcome-box{margin: auto;/* padding-bottom: 40px; */width: 1200px;}
	.welcome-box::after{display: block;content: '';clear: both;}
	
	/* 当前登录企业 */
	.welcome-floatpart{float: left;}
	.curenterpart-entercon{position: relative;margin-top: 20px;}
	.curenterpart-entercon:first-child{margin-top: 0;}
	.entercon-entertle{display: block;position: absolute;top: 0;left: 0;/* margin-right: 10px; */width: 160px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: #fff;box-sizing: border-box;font-size: 16px;}
	.entercon-backindex{background: rgb(200, 198, 198);}
	.entercon-companyname{display: block;margin-left: 170px;width: 400px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: #fff;box-sizing: border-box;font-size: 16px;}
	.entercon-evaluate{background: rgb(102, 204, 51);color: #fff;}
		
	/* right页面显示 */
	.welcome-rightpart{float: right;position: relative;width: 400px;/* height: 180px; */box-sizing: border-box;}
	.pagepart-pagecom{display: block;width: 400px;height: 40px;line-height: 40px;box-sizing: border-box;background: #ffffff;border: 1px solid #DCDFE6;font-weight: 400;font-size: 16px;}
	.pagepart-recordlast{line-height: 38px;}
	.pagepart-download{margin-top: 20px;background: rgb(65, 199, 219);color: #ffffff;}
	.download-noreport{background: #cccccc;cursor: not-allowed;cursor: pointer;}
	.download-noreport{background: #c8c6c6;cursor: not-allowed}
	
	
</style>
