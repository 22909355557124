<template>
	<div class="content-banner">
		<div class="banner-box">
			<div class="banner-img"></div>
			<!-- <img class="banner-img" src="../assets/images/banner.png"/> -->
			<div class="banner-title">
				<div class="banner-titlebox align-style" v-if="bannerTitle == '企业技术创新力在线自测系统'">
					<div class="banner-name-title align-between">{{bannerTitle}}</div>
					<div class="banner-name-exp">服务强化企业创新主体地位&nbsp;&nbsp;&nbsp;&nbsp;推动高水平科技自立自强</div>
				</div>
				<div class="banner-titlebox" v-else>
					<div class="banner-name-title">{{bannerTitle}}</div>
					<div class="banner-name-exp">服务强化企业创新主体地位&nbsp;&nbsp;&nbsp;&nbsp;推动高水平科技自立自强</div>
				</div>
				<div class="banner-operabox">
					<a class="banner-name-manual" :href="'/user/download/manual'">使用指南</a>
					<a class="banner-name-manual banner-name-feedback" target="_blank" :href="'https://wj.qq.com/s2/9344581/63fc/'">意见反馈</a>
					<span class="banner-name-manual banner-name-techsupport" @click="techSupportPop">技术支持</span>
				</div>
			</div>
		</div>
		<!-- 返回-返回上一页-弹框 -->
		<el-dialog
			class="popup-techsupport"
			:visible.sync="techSupportShow"
			:close-on-click-modal="false">
			<!-- <span class="submitconfirm-confirmtitle">找回密码</span> -->
			<div class="techsupport-techcon">
				<div class="techcon-poptle">获取技术支持</div>
				<div class="techcon-putinfo">遇到问题？请扫码添加我们的企业微信，以获得即时帮助。</div>
				<div class="techcon-putinfo techcon-putinfo-mt0">您也可以直接电话：18611831017，与我们联系。</div>
				<div class="techcon-wechatcode">
					<div class="wechatcode-box">
						<img class="wechatcode-size" src="../assets/images/wxy_wechat.png">
						<span class="wechatcode-name">请用微信扫码</span>
					</div>
					<button class="techcon-footbtn footbtn-closebtn" @click="techSupportShow = false">关 闭</button>
				</div>
			</div>
			<!-- <div slot="footer" class="techsupport-techfooter">
				<button class="techfooter-footbtn techfooter-cancelbtn" @click="techSupportShow = false">关 闭</button>
			</div> -->
		</el-dialog>
	</div>
	
</template>

<script>
	export default {
		data(){
			return{
				techSupportShow: false
			}
		},
		props:{
			bannerTitle: {
				type: String,
				default() {
					return '企业技术创新力在线自测系统'
				}
			},
		},
		components:{
	
		},
		mounted() {
			
		},
		methods: {
			techSupportPop() {
				let _this = this
				_this.techSupportShow = true
			}
		},
		watch: {
			
		}
	}
</script>

<style>
	.content-banner{margin: 0 auto;width: 100%;/* width: 1200px; */text-align: left;color: rgb(0, 0, 0);font-weight: 400;font-size: 20px;font-style: normal;text-decoration: none;}
	.banner-box{
		position: relative;
		margin: 0 auto;
		width: 100%;
		/* width: 1200px; */
		height: 400px;
		/* background: url(../assets/images/banner.png); */
	}
	.banner-img{width: 100%;height: 100%;background: url(../assets/images/banner.png);}
	.banner-title{position: relative;/* position: absolute;bottom: 40px;left: 50px; */margin: -108px auto 0;width: 1200px;white-space: nowrap;/*overflow: hidden;*/text-align: left;color: rgb(0, 0, 0);font-family: "Microsoft YaHei";text-decoration: none;}
	.banner-title::after{display: block;content: '';clear: both;}
	.banner-titlebox{float: left;margin-top: -76px;}
	.align-style{/* width: 720px; height: 116px;*//* text-align-last: justify;text-justify: inter-ideograph;*/}
	.align-between{/* text-align: justify;display: flex;width: 720px;flex-direction: column;justify-content: space-between; */}
	.banner-name-title{/* float: left; */margin-bottom: 10px;font-weight: 700;font-size: 56px !important;font-family: "Microsoft YaHei" !important;}
	
	.banner-name-exp{color: rgb(255, 0, 0);font-weight: 700;font-size: 30px;font-family: "Microsoft YaHei" !important;}
	
	.banner-operabox{position: absolute;bottom: 0;right: 0;}
	.banner-name-manual{display: block;float: right;margin-top: 13px;color: rgb(0, 0, 0) !important;font-size: 20px;text-decoration: underline !important;font-family: "Microsoft YaHei" !important;}
	.banner-name-feedback{position:relative;margin-right: 30px;}
	.banner-name-feedback::before{content: "热";position: absolute;top: -13px;right: -25px;width: 22px;height: 18px;line-height: 18px;background: rgb(255, 0, 0);border-radius: 2px;text-align: center;color: rgb(255, 255, 255);font-size: 14px;}
	.banner-name-feedback::after{content: "";position: absolute;top: -2px;right: -8px;transform: rotate(135deg);width: 0px;height: 0px;border-top: 5px solid rgb(255, 0, 0);border-left: 5px solid transparent;border-right: 5px solid transparent;border-bottom: 5px solid transparent;}
	.banner-name-techsupport{margin-right: 30px;cursor: pointer;}
	
	
	/* 确认提交-弹框 */
	.popup-techsupport{font-family: "Microsoft YaHei";}
	.popup-techsupport .el-dialog{margin-top: 30vh !important;width: 480px;/* width: 60%;min-width: 730px; */}
	.popup-techsupport .el-dialog__header{padding: 0;}
	.popup-techsupport .el-dialog__headerbtn{top: 24px;}
	.popup-techsupport .el-dialog__body{padding: 20px 20px;text-align: left;}
	.popup-techsupport .el-dialog__footer{padding: 0px 20px 20px;}
	.techsupport-techcon{}
	.techcon-poptle{color: #000000;font-weight: 700;font-size: 20px;}
	.techcon-putinfo{margin-top: 20px;color: #000000;font-weight: 400;font-size: 16px;}
	.techcon-putinfo-mt0{margin-top: 0px;}
	.techcon-wechatcode{margin-top: 20px;text-align: left;overflow: hidden;}
	.wechatcode-box{float: left;width: 167px;}
	.wechatcode-size{margin-left: 0;width: 167px;height: 167px;}
	.wechatcode-name{display: block;margin-top: 10px;width: 167px;text-align: center;font-size: 16px;color: rgb(153, 153, 153);font-weight: 400;font-family: "Segoe UI";}
	.techcon-footbtn{display: block;float: right;margin-top: 158px;padding: 0;width: 100px;height: 40px;line-height: 40px;background: rgb(102, 204, 51);box-sizing: border-box;color: #ffffff;font-size: 16px;}
	.footbtn-closebtn{line-height: 38px;border: 1px solid rgb(102, 102, 102);background: #ffffff;color: #000000;}
	
	
	.techsupport-techfooter{text-align: right;}
	.techsupport-techfooter::after{display: block;content: '';clear: both;}
	.techfooter-footbtn{display: block;float: right;margin-left: 10px;padding: 0;width: 100px;height: 40px;line-height: 40px;background: rgb(102, 204, 51);/* rgb(65, 199, 219)蓝色  background: #E72D6B; */box-sizing: border-box;color: #ffffff;font-size: 16px;}
	.techfooter-cancelbtn{line-height: 38px;border: 1px solid rgb(102, 102, 102);/* rgb(102, 102, 102) border: 1px solid #E72D6B; */background: #ffffff;color: #000000;}
	
	/* .article-box{padding-bottom: 20px;}
	.article-p{margin-bottom: 20px;line-height: 30px;}
	.article-p:last-child{margin-bottom: 0px;} */
</style>
