<template>
	<!-- <div> -->
		<div class="cpane-combox cpane-admincon cpane-adminfinance">
			<div class="combox-contitle"><i class="icon-renyuanguanli iconfont icon-fintitle"></i>管理类数据</div>
			<div class="combox-conmain">
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">专利申请量</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">发明</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.apShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.ap_pt1_count" placeholder="100" @input="manageObj.ap_pt1_count = checkNumber($event, manageObj.ap_pt1_count)" @blur="manageObj.ap_pt1_count = clearNumber(manageObj.ap_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件，实用新型</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.apShowPt2Count ? 'warning-hintbg' : '']" v-model="manageObj.ap_pt2_count" placeholder="100" @input="manageObj.ap_pt2_count = checkNumber($event, manageObj.ap_pt2_count)" @blur="manageObj.ap_pt2_count = clearNumber(manageObj.ap_pt2_count)"></el-input>
							<span class="cdetail-namestyle">件，外观设计</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.apShowPt3Count ? 'warning-hintbg' : '']" v-model="manageObj.ap_pt3_count" placeholder="100" @input="manageObj.ap_pt3_count = checkNumber($event, manageObj.ap_pt3_count)" @blur="manageObj.ap_pt3_count = clearNumber(manageObj.ap_pt3_count)"></el-input>
							<span class="cdetail-namestyle">件</span>
						</div>
						<span class="hint-msg">当年企业提交的专利申请量，分为发明、实用新型和外观设计申请量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">专利授权量</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">发明</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.athShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.ath_pt1_count" placeholder="100" @input="manageObj.ath_pt1_count = checkNumber($event, manageObj.ath_pt1_count)" @blur="manageObj.ath_pt1_count = clearNumber(manageObj.ath_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件，实用新型</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.athShowPt2Count ? 'warning-hintbg' : '']" v-model="manageObj.ath_pt2_count" placeholder="100" @input="manageObj.ath_pt2_count = checkNumber($event, manageObj.ath_pt2_count)" @blur="manageObj.ath_pt2_count = clearNumber(manageObj.ath_pt2_count)"></el-input>
							<span class="cdetail-namestyle">件，外观设计</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.athShowPt3Count ? 'warning-hintbg' : '']" v-model="manageObj.ath_pt3_count" placeholder="100" @input="manageObj.ath_pt3_count = checkNumber($event, manageObj.ath_pt3_count)" @blur="manageObj.ath_pt3_count = clearNumber(manageObj.ath_pt3_count)"></el-input>
							<span class="cdetail-namestyle">件</span>
						</div>
						<span class="hint-msg">当年企业获得授权的专利数量，分为发明、实用新型和外观设计授权量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname mainitem-fmzlsqlname"><span class="dis-block">发明专利</span><span class="dis-block">申请量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">当年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.curShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.cur_pt1_count" placeholder="100" @input="manageObj.cur_pt1_count = checkNumber($event, manageObj.cur_pt1_count)" @blur="manageObj.cur_pt1_count = clearNumber(manageObj.cur_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件，上一年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.prev1ShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.prev1_pt1_count" placeholder="100" @input="manageObj.prev1_pt1_count = checkNumber($event, manageObj.prev1_pt1_count)" @blur="manageObj.prev1_pt1_count = clearNumber(manageObj.prev1_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件，上上年</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.prev2ShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.prev2_pt1_count" placeholder="100" @input="manageObj.prev2_pt1_count = checkNumber($event, manageObj.prev2_pt1_count)" @blur="manageObj.prev2_pt1_count = clearNumber(manageObj.prev2_pt1_count)"></el-input>
							<span class="cdetail-namestyle">件</span>
						</div>
						<span class="hint-msg">当年、上一年度及上上年度企业申请的发明专利总量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">有效发明</span><span class="dis-block">专利数量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.ls2ShowPt1Count ? 'warning-hintbg' : '']" v-model="manageObj.ls2_pt1_count" placeholder="100" @input="manageObj.ls2_pt1_count = checkNumber($event, manageObj.ls2_pt1_count)" @blur="manageObj.ls2_pt1_count = clearNumber(manageObj.ls2_pt1_count)"></el-input>
							件
						</div>
						<span class="hint-msg">截至当年年末，企业申请且当前有效的发明专利总量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">维持5年以上</span><span class="dis-block">专利量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.patShowYear5Count ? 'warning-hintbg' : '']" v-model="manageObj.pat_year5_count" placeholder="100" @input="manageObj.pat_year5_count = checkNumber($event, manageObj.pat_year5_count)" @blur="manageObj.pat_year5_count = clearNumber(manageObj.pat_year5_count)"></el-input>
							件
						</div>
						<span class="hint-msg">截至当年年末，企业申请且自授权后维持时长在5年及以上专利量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">PCT专利量</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.patShowPctCount ? 'warning-hintbg' : '']" v-model="manageObj.pat_pct_count" placeholder="100" @input="manageObj.pat_pct_count = checkNumber($event, manageObj.pat_pct_count)" @blur="manageObj.pat_pct_count = clearNumber(manageObj.pat_pct_count)"></el-input>
							件
						</div>
						<span class="hint-msg">截至当年年末，企业经PCT途径申请的专利量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">境外专利</span><span class="dis-block">授权量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.outsideShowAthCount ? 'warning-hintbg' : '']" v-model="manageObj.outside_ath_count" placeholder="100" @input="manageObj.outside_ath_count = checkNumber($event, manageObj.outside_ath_count)" @blur="manageObj.outside_ath_count = clearNumber(manageObj.outside_ath_count)"></el-input>
							件
						</div>
						<span class="hint-msg">当年企业在中国大陆以外地区，包含港澳台、其他国家和国际组织授权专利总件数。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">运营专利量</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<span class="cdetail-namestyle">转让</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.transferShowCount ? 'warning-hintbg' : '']" v-model="manageObj.transfer_count" placeholder="100" @input="manageObj.transfer_count = checkNumber($event, manageObj.transfer_count)" @blur="manageObj.transfer_count = clearNumber(manageObj.transfer_count)"></el-input>
							<span class="cdetail-namestyle">件，许可</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.licenseShowCount ? 'warning-hintbg' : '']" v-model="manageObj.license_count" placeholder="100" @input="manageObj.license_count = checkNumber($event, manageObj.license_count)" @blur="manageObj.license_count = clearNumber(manageObj.license_count)"></el-input>
							<span class="cdetail-namestyle">件，质押</span>
							<el-input :class="['cdetail-iptstyle', 'w-140', 'getManageValue', manageTrueShow.pledgeShowCount ? 'warning-hintbg' : '']" v-model="manageObj.pledge_count" placeholder="100" @input="manageObj.pledge_count = checkNumber($event, manageObj.pledge_count)" @blur="manageObj.pledge_count = clearNumber(manageObj.pledge_count)"></el-input>
							<span class="cdetail-namestyle">件</span>
						</div>
						<span class="hint-msg">当年企业涉及转让（含出让和受让）、许可（含许可和被许可）、质押专利量的加和。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname">注册商标量</span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.regShowTmCount ? 'warning-hintbg' : '']" v-model="manageObj.reg_tm_count" placeholder="100" @input="manageObj.reg_tm_count = checkNumber($event, manageObj.reg_tm_count)" @blur="manageObj.reg_tm_count = clearNumber(manageObj.reg_tm_count)"></el-input>
							件
						</div>
						<span class="hint-msg">当年企业获得注册商标总量。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">境外注册</span><span class="dis-block">商标量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-input :class="['cdetail-iptstyle', 'getManageValue', manageTrueShow.outsideShowRegTmCount ? 'warning-hintbg' : '']" v-model="manageObj.outside_reg_tm_count" placeholder="100" @input="manageObj.outside_reg_tm_count = checkNumber($event, manageObj.outside_reg_tm_count)" @blur="manageObj.outside_reg_tm_count = clearNumber(manageObj.outside_reg_tm_count)"></el-input>
							件
						</div>
						<span class="hint-msg">当年企业在中国大陆以外地区，包含港澳台、其他国家和国际组织的注册商标总件数。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">其他知识产权</span><span class="dis-block">数量</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<div class="cdetail-cdetinput">
								<div class="cdetail-addbox">
									<div class="addbox-multiple" v-for="(item, index) in manageObj.other_counts" :key="index">		
										<el-select class="cdetail-marketsector cdetail-deveplatform" v-model="item.name" @visible-change="otherCountCirculate(index)" @change="otherCountChange(item.name, manageObj.other_counts, index)" clearable placeholder="请选择">
											<el-option
												v-for="item in otherNumOptions"
												:key="item.value"
												:label="item.label"
												:value="item.value">
											</el-option>
										</el-select>
										<el-input :class="['cdetail-iptstyle', 'input-w150', manageTrueShow.otherShowCounts[index].countShow ? 'warning-hintbg' : '']" v-model="item.count" placeholder="10" @input="item.count = checkNumber($event, item.count)" @blur="item.count = clearNumber(item.count)"></el-input>
										<span class="cdetail-iptsunit">项</span>
										<i class="icon-jiahao1 iconfont cdetail-iconstyle" v-if="index == 0" @click="addOtherCountHandle"></i>
										<i class="icon-jianhao iconfont cdetail-iconjian" v-if="index != 0" @click="delOtherCountHandle(index)"></i>
									</div>
								</div>
								<!-- <i class="icon-tianjia iconfont cdetail-iconstyle" @click="addOtherCountHandle"></i> -->
							</div>
						</div>
						<span class="hint-msg">当年企业申请的软件著作权、集成电路布图、植物新品种、国家级农作物品种、国家新药和国家一级中药保护品种数量。注意此处不含商标数量。请分别填写每种知识产权数量，点击”+“号可添加更多。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">创新战略制定</span><span class="dis-block">实施情况</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-radio-group class="cdetail-radiobox" v-model="manageObj.innovation_strategy">
								<el-radio-button class="radiobox-radiobtn" @click.native.prevent="cancelInnovStra('非常好')" label="非常好"></el-radio-button>
								<el-radio-button class="radiobox-radiobtn" @click.native.prevent="cancelInnovStra('较好')" label="较好"></el-radio-button>
								<el-radio-button class="radiobox-radiobtn" @click.native.prevent="cancelInnovStra('一般')" label="一般"></el-radio-button>
								<el-radio-button class="radiobox-radiobtn" @click.native.prevent="cancelInnovStra('较差')" label="较差"></el-radio-button>
								<el-radio-button class="radiobox-radiobtn" @click.native.prevent="cancelInnovStra('非常差')" label="非常差"></el-radio-button>
							</el-radio-group>
						</div>
						<span class="hint-msg">企业创新战略制定与实施情况，包括是否有明确创新目标、创新战略与企业总体战略的一致性、是否制定了创新战略的详细执行措施，及实施效果等。填写时企业需根据实际情况，选择所属相应档位。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">产学研合作</span><span class="dis-block">情况</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-radio-group class="cdetail-radiobox" v-model="manageObj.industry_university_research">
								<el-radio-button @click.native.prevent="cancelIndUniRes('非常好')" label="非常好"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelIndUniRes('较好')" label="较好"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelIndUniRes('一般')" label="一般"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelIndUniRes('较差')" label="较差"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelIndUniRes('非常差')" label="非常差"></el-radio-button>
							</el-radio-group>
						</div>
						<span class="hint-msg">企业与大专院校、科研院所创新合作情况，含产学研合作形式、合作项目/课题及专利申请情况等。填写时企业需根据实际情况，选择所属相应档位。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">研发管理</span><span class="dis-block">体系情况</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-radio-group class="cdetail-radiobox" v-model="manageObj.research_development">
								<el-radio-button @click.native.prevent="cancelResDevel('十分健全')" label="十分健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelResDevel('比较健全')" label="比较健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelResDevel('基本健全')" label="基本健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelResDevel('不够健全')" label="不够健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelResDevel('很不健全')" label="很不健全"></el-radio-button>
							</el-radio-group>
						</div>
						<span class="hint-msg">企业研发管理体系建设情况，含研发团队建设、研发流程设计、研发项目管理、研发成本管理、研发绩效管理及研发风险管理等方面。填写时企业需根据实际情况，选择所属相应档位。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">知识产权</span><span class="dis-block">管理情况</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-radio-group class="cdetail-radiobox" v-model="manageObj.itellectual_property">
								<el-radio-button @click.native.prevent="cancelItelPro('十分健全')" label="十分健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelItelPro('比较健全')" label="比较健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelItelPro('基本健全')" label="基本健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelItelPro('不够健全')" label="不够健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelItelPro('很不健全')" label="很不健全"></el-radio-button>
							</el-radio-group>
						</div>
						<span class="hint-msg">企业知识产权管理制度制定情况，含工作目标、组织结构、管理办法及其实施要点等。填写时企业需根据实际情况，选择所属相应档位。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">创新激励</span><span class="dis-block">机制情况</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-radio-group class="cdetail-radiobox" v-model="manageObj.inovation_ncentive">
								<el-radio-button @click.native.prevent="cancelInovNcen('十分健全')" label="十分健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelInovNcen('比较健全')" label="比较健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelInovNcen('基本健全')" label="基本健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelInovNcen('不够健全')" label="不够健全"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelInovNcen('很不健全')" label="很不健全"></el-radio-button>
							</el-radio-group>
						</div>
						<span class="hint-msg">企业鼓励职工积极开展技术攻关、技术革新、技术发明等创新激励机制的制定及实施情况，包括股权激励、奖金激励、职位晋升等。填写时企业需根据实际情况，选择所属相应档位。</span>
					</div>
				</div>
				<div class="conmain-mainitem">
					<span class="mainitem-itemname"><span class="dis-block">企业文化</span><span class="dis-block">氛围情况</span></span>
					<div class="mainitem-itemdetail">
						<div class="itemdetail-cdetail">
							<el-radio-group class="cdetail-radiobox" v-model="manageObj.enterprise_culture">
								<el-radio-button @click.native.prevent="cancelEnterCul('非常好')" label="非常好"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelEnterCul('较好')" label="较好"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelEnterCul('一般')" label="一般"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelEnterCul('较差')" label="较差"></el-radio-button>
								<el-radio-button @click.native.prevent="cancelEnterCul('非常差')" label="非常差"></el-radio-button>
							</el-radio-group>
						</div>
						<span class="hint-msg">企业文化氛围，包括是否有明确企业创新文化，是否得到员工广泛认同，能否体现创新精神等。填写时企业需根据实际情况，选择所属相应档位。</span>
					</div>
				</div>
			</div>
		</div>
	<!-- </div> -->
</template>

<script>
	export default {
		data(){
			return{
				// proOtherCount: 1, // 其他知识产权数量-添加更多-列
				otherNumOptions: [],
				// tempOptions: [
				// 	{value: '软件著作权',label: '软件著作权'},
				// 	{value: '集成电路布图',label: '集成电路布图'},
				// 	{value: '植物新品种',label: '植物新品种'},
				// 	{value: '国家级农作物品种',label: '国家级农作物品种'},
				// 	{value: '国家新药',label: '国家新药'},
				// 	{value: '国家一级中药保护品种',label: '国家一级中药保护品种'},
				// ]
			}
		},
		props:{
			manageObj: {
				type: Object,
				default() {
					return {}
				}
			},
			manageTrueShow: {
				type: Object,
				default() {
					return {}
				}
			},
			manageOptions: {
				type: Object,
				default() {
					return {}
				}
			},
			proOtherCount: {
				type: Number,
				default() {
					return 1
				}
			},
		},
		components:{
	
		},
		created() {
			// this.otherCountCirculate()
		},
		updated() {
			this.$parent.$parent.$parent.isHaveNull()
		},
		methods: {
			otherCountChange(name, array, index) {
				console.log(name + '-----------' + index)
				console.log(array)
			},
			otherCountCirculate(index) {
				console.log(index)
				let otherOptions = [];
				for(let a = 0; a < this.manageObj.other_counts.length; a++) {
					if(a != index) {
						otherOptions.push(this.manageObj.other_counts[a])
					}
				}
				let allOptions = [
					{value: '软件著作权',label: '软件著作权'},
					{value: '集成电路布图',label: '集成电路布图'},
					{value: '植物新品种',label: '植物新品种'},
					{value: '国家级农作物品种',label: '国家级农作物品种'},
					{value: '国家新药',label: '国家新药'},
					{value: '国家一级中药保护品种',label: '国家一级中药保护品种'}
				]
				for(let i = 0; i < otherOptions.length; i++) {
					for(let j = 0; j < allOptions.length; j++) {
						if(allOptions[j].value == otherOptions[i].name) {
							allOptions.splice(j, 1)
						}
					}
				}
				this.otherNumOptions = allOptions
			},
			delOtherCountHandle(index) {
				let _this = this;
				_this.manageObj.other_counts.splice(index, 1)
				_this.$forceUpdate()
				let tempArray = _this.manageTrueShow.otherShowCounts
				for(let i = 0; i < _this.manageObj.other_counts.length; i++) {
					tempArray.push({countShow: false})
				}
				if(_this.manageObj.other_counts.length > 0) {
					let otherArray = []
					for(let i = 0; i < _this.manageObj.other_counts.length; i++) {
						// otherArray.push({countShow: false})
						if(!_this.manageObj.other_counts[i].count || (_this.manageObj.other_counts[i].count >= 0 && _this.manageObj.other_counts[i].count <= 99999)) {
							// otherArray[i].countShow = false
							otherArray.push({countShow: false})
						}else{
							// otherArray[i].countShow = true
							otherArray.push({countShow: true})
						}
					}
					console.log(otherArray)
					_this.manageTrueShow.otherShowCounts = otherArray
				}
				// _this.$parent.$parent.$parent.verifyManageValue()
				// _this.manageTrueShow.otherShowCounts = tempArray
				
				console.log(_this.manageObj.other_counts)
			},
			addOtherCountHandle() { // 点击“+”添加更多-添加列-其他知识产权数量
				let _this = this
				console.log('-------点击“+”添加更多')
				if(_this.manageObj.other_counts.length < 6) {
					// console.log(_this.manageObj.other_counts)
					// _this.proOtherCount ++
					
					let otherOptions = _this.manageObj.other_counts;
					let allOptions = [
						{value: '软件著作权',label: '软件著作权'},
						{value: '集成电路布图',label: '集成电路布图'},
						{value: '植物新品种',label: '植物新品种'},
						{value: '国家级农作物品种',label: '国家级农作物品种'},
						{value: '国家新药',label: '国家新药'},
						{value: '国家一级中药保护品种',label: '国家一级中药保护品种'}
					]
					for(let i = 0; i < otherOptions.length; i++) {
						for(let j = 0; j < allOptions.length; j++) {
							if(allOptions[j].value == otherOptions[i].name) {
								allOptions.splice(j, 1)
							}
						}
					}
					_this.otherNumOptions = allOptions
					
					_this.manageObj.other_counts.push({name: _this.otherNumOptions[0].value, count: ''})
					let tempArray = _this.manageTrueShow.otherShowCounts
					for(let i = 0; i < _this.manageObj.other_counts.length; i++) {
						tempArray.push({countShow: false})
					}
					// if(_this.manageObj.other_counts.length > 0) {
					// 	let otherArray = []
					// 	for(let i = 0; i < _this.manageObj.other_counts.length; i++) {
					// 		otherArray.push({countShow: false})
					// 		if(!_this.manageObj.other_counts[i].count || (_this.manageObj.other_counts[i].count >= 0 && _this.manageObj.other_counts[i].count <= 99999)) {
					// 			otherArray[i].countShow = false
					// 		}else{
					// 			otherArray[i].countShow = true
					// 		}
					// 	}
					// 	console.log(otherArray)
					// 	_this.manageTrueShow.otherShowCounts = otherArray
					// }
					// _this.$parent.$parent.$parent.verifyManageValue()
					_this.manageTrueShow.otherShowCounts = tempArray
					_this.$forceUpdate()
					
					// _this.$parent.$parent.$parent.isHaveNull()
					// console.log('-------点击“+”添加更多')
					// console.log(_this.manageObj.other_counts)
				}
			},
			cancelInnovStra(e) { // 单选-取消
				e === this.manageObj.innovation_strategy ? this.manageObj.innovation_strategy = '' : this.manageObj.innovation_strategy = e
			},
			cancelIndUniRes(e) { // 单选-取消
				e === this.manageObj.industry_university_research ? this.manageObj.industry_university_research = '' : this.manageObj.industry_university_research = e
			},
			cancelResDevel(e) { // 单选-取消
				e === this.manageObj.research_development ? this.manageObj.research_development = '' : this.manageObj.research_development = e
			},
			cancelItelPro(e) { // 单选-取消
				e === this.manageObj.itellectual_property ? this.manageObj.itellectual_property = '' : this.manageObj.itellectual_property = e
			},
			cancelInovNcen(e) { // 单选-取消
				e === this.manageObj.inovation_ncentive ? this.manageObj.inovation_ncentive = '' : this.manageObj.inovation_ncentive = e
			},
			cancelEnterCul(e) { // 单选-取消
				e === this.manageObj.enterprise_culture ? this.manageObj.enterprise_culture = '' : this.manageObj.enterprise_culture = e
			},
			
			// input控制输入内容
			checkNumber(e, val) { // 输入整数
				this.$forceUpdate()
				// if(val) {
					// val = (val.match(/^\d*/g)[0]) || null
					val = (val.match(/^\d{0,7}/g)[0]) || null
					return val
				// }
			},
			clearNumber(val) { // 保留小数点后两位，不满两位则补零
				this.$parent.$parent.$parent.verifyManageValue()
				if(val) {
					return parseInt(val)
				}else{
					return val
				}
			},
			checkFloat(e, val) { // 最多输入两位小数
				this.$forceUpdate()
				// if(val) {
					val = val.toString()
					val = val.replace(/^\./g, "") // 必须保证第一个为数字而不是点
					// val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || null
					val = (val.match(/^\d{0,9}\.\d\d|^\d{0,9}\.\d|^\d{0,9}\.|^\d{0,9}/g)[0]) || null
				// }
				return val
			},
			clearFloat(val) { // 保留小数点后两位，不满两位则补零
				// console.log(val)
				this.$parent.$parent.$parent.verifyManageValue()
				if(val) {
					val = val.toString()
					if(val.indexOf(".") < 0) {
						val = val + ".00"
					}else if(val.indexOf(".") > 0) {
						let decimalNum = val.split('.')[1]
						if(decimalNum != '') {
							if(decimalNum.length== 1) {
								val = val + "0"
							}
						}else {
							val = val + "00"
						}
					}
					return parseFloat(val).toFixed(2)
					// return val
				}else{
					return val
				}
			},
		},
		watch: {
			manageTrueShow: {
				handler(val, oldVal){
					// console.log('判断当前目录qId是否=ed_year,以此来判断数据展示（年份）')//但是这两个值打印出来却都是一样的
					console.log(val)
					console.log(oldVal)
				}
			},
		}
	}
</script>

<style scoped>
	/* tabs内容 */
	.contle-ctabs /deep/ .el-tabs__content{padding: 0;}
	.cpane-combox{padding: 20px;box-sizing: border-box;background: #e4e4e4;}
	.cpane-admincon{text-align: left;}
	.combox-contitle{position: relative;padding-left: 35px;line-height: 30px;color: #000000;font-weight: 700;font-size: 22px;}
	.combox-contitle .icon-title{position: absolute;left: 0;font-size: 22px;}
	.combox-contitle .icon-fintitle{position: absolute;left: 0;font-size: 26px;}
	.combox-conmain{}
	.conmain-mainitem{position: relative;margin-top: 40px;}
	.conmain-mainitem:first-child{margin-top: 50px;}
	.conmain-mainitem::after{display: block;content: '';clear: both;}
	.mainitem-itemname{position: absolute;left: -5px;width: 120px;line-height: 30px;text-align: right;color: #000000;font-weight: 700;font-size: 20px;}/*input框标题字号*/
	/* .mainitem-fmzlsqlname{left: -5px;} */ /* 发明专利申请量 */
	.dis-block{display: block;}
	.dis-block:last-child{margin-top: -5px;}
	.mainitem-itemdetail{position: relative;margin-left: 130px;}
	.itemdetail-cdetail{line-height: 30px;font-size: 16px;}
	.itemdetail-cdetail::after{display: block;content: '';clear: both;}
	.cdetail-iptbox{display: block;float: left;margin-right: 10px;width: 300px;}
	.cdetail-iptbox /deep/ .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-iptkeyword{display: block;float: left;margin-right: 5px;width: 200px;}
	.cdetail-iptkeyword /deep/ .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-cdetinput::after{display: block;content: '';clear: both;}
	/* 点击“+”号添加更多box-------------- */
	.cdetail-addbox{display: block;float: left;}
	.cdetail-addbox::after{display: block;content: '';clear: both;}
	.addbox-multiple{margin-bottom: 10px;}
	.addbox-multiple:last-child{margin-bottom: 10px;}
	.addbox-multiple::after{display: block;content: '';clear: both;}
	/* 点击“+”号添加更多box-------------- */
	.cdetail-iptstyle{display: block;float: left;margin-right: 10px;width: 300px;}
	.cdetail-iptstyle /deep/ .el-input__inner{padding: 0 10px;height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.warning-hintbg /deep/ .el-input__inner{background: rgb(255, 204, 204);}
	.cdetail-iptstyle .el-input__inner::focus{border: 1px solid #409eff;background: #fff !important;}
	.icon-search{display: block;float: left;width: 60px;height: 30px;line-height: 30px;font-size: 24px;}
	.input-w150{width: 150px;}
	.input-w250{width: 250px;}
	.cdetail-iptsunit{display: block;float: left;margin-right: 20px;}
	.cdetail-radiobox{display: block;float: left;margin-right: 10px;}
	/* .cdetail-radiobox /deep/ .el-radio-button{background: transparent;} */
	.cdetail-radiobox /deep/ .el-radio-button__inner{padding: 0 30px;height: 30px;line-height: 30px;overflow: hidden;border: 1px solid rgb(204, 204, 204);background: transparent;box-sizing: border-box;border-radius: 0;color:rgb(0, 0, 0);outline: none;font-size: 16px;}
	.cdetail-radiobox /deep/ .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {box-shadow: none;}
	.cdetail-radiobox /deep/ .cdetail-radiobox[data-v-0801709e] .el-radio-button:focus {border: none;outline: none;}
	.cdetail-radiobox /deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner{background: rgb(241, 194, 50);border-color: rgb(241, 194, 50);box-shadow: -1px 0 0 0 rgb(241, 194, 50);outline: none;}
	.cdetail-marketsector{display: block;float: left;margin-right: 10px;width: 180px;}
	.cdetail-industrybox{width: 400px;}
	.cdetail-marketsector /deep/ .el-input__inner{height: 30px;line-height: 30px;border-radius: 0;font-size: 16px;}
	.cdetail-marketsector /deep/ .el-input__icon{height: 30px;line-height: 30px;font-size: 16px;}
	.addbox-multiple .cdetail-iconjian{display: block;float: left;line-height: 30px;font-size: 26px;cursor: pointer;}
	.cdetail-iconstyle{display: block;float: left;line-height: 30px;font-size: 26px;cursor: pointer;}
	
	/* el-radio-button is-active */
	/* 提示 */
	.hint-msg{margin-top: 5px;display: block;color: #999999;font-weight: 400;font-size: 16px;}
	.hint-msg:first-child{margin-top: 5px;}
	.hint-red{color: rgb(255, 0, 0);}
	/* border: 1px solid #DCDFE6;box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%); */
	
	/* 财务类 */
	.cpane-adminfinance{}
	.w-140{width: 140px;}
	.cdetail-namestyle{display: block;float: left;margin: 0 10px 0 0;}
	.cdetail-namestyle:last-child{margin: 0;}
</style>
