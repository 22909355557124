<template>
	<div class="nav-content m-b0">
		<Banner :bannerTitle="bannerTitle"></Banner>
		<div class="height510">
			<div class="register-box">
				<div class="register-reguserpart">
					<div class="reguserpart-regtitle font-size20">新用户注册</div>
					<div class="reguserpart-regcon">
						<div class="retrcon-retrbox">
							<el-input class="retrcon-retrinput"
								placeholder="手机号"
								v-model="retrievePhoneVal"
								@blur="phoneChange">
							</el-input><!-- clearable -->
							<span class="retrcon-retrhint" v-if="phoneHint != ''">{{phoneHint}}</span><!--该手机号未注册！-->
						</div>
						<div class="retrcon-retrbox">
							<el-input class="retrcon-retrinput"
								placeholder="验证码"
								v-model="retrieveCodeVal"
								@blur="codeChange">
							</el-input>
							<button class="retrcon-retrcodebtn" v-show="show" @click="getCode">获取验证码</button>
							<el-button class="retrcon-retrcodebtn retrcon-retrcodebtn-active" v-show="!show" type="info" disabled>重新发送{{count}}s</el-button>
							<span class="retrcon-retrhint" v-if="codeHint != ''">{{codeHint}}</span><!--验证码不正确！-->
						</div>
						<div class="retrcon-retrbox">
							<el-input class="retrcon-retrinput"
								placeholder="密码"
								type="password"
								v-model="newPasswordVal"
								@blur="newPasswordChange">
							</el-input>
							<span :class="['retrcon-retrhint', pwdHint == '8-16字符，至少同时包含字母和数字' ? 'retrcon-retrtishi' : 'color-red']">{{pwdHint}}</span><!--8-16字符，至少同时包含字母和数字-->
						</div>
						<div class="retrcon-retrbox">
							<el-input class="retrcon-retrinput"
								placeholder="确认密码"
								type="password"
								v-model="repeatPasswordVal"
								@blur="repeatPasswordBlur">
								<!-- @change="repeatPasswordChange" -->
							</el-input>
							<span class="retrcon-retrhint" v-if="pwdRepeatHint != ''">{{pwdRepeatHint}}</span><!--两次输入的密码不一致-->
						</div>
					</div>
				</div>
				<div class="register-companypart">
					<div class="companypart-box">
						<el-autocomplete
							class="companypart-loginput"
							v-model="companyInput"
							:fetch-suggestions="nameTipFn"
							:trigger-on-focus="false"
							placeholder="输入完整企业名称"
							@select="searchSelect"
						></el-autocomplete>
						<!-- <el-input class="companypart-loginput" v-model="userPhoneVal" placeholder="请输入要绑定的企业"></el-input> -->
					</div>
					<div class="companypart-hint">{{companyHint}}</div><!--请输入要绑定的企业！-->
					<button class="companypart-submitbtn" @click="regSubmitClick">提交注册</button>
				</div>
			</div>
		</div>
		
		<!-- 提交注册-提示结果-弹框 -->
		<el-dialog
			class="popup-registerres"
			:visible.sync="registerResultShow"
			:close-on-click-modal="false">
			<span class="register-regtitle">确认提交注册</span>
			<div class="register-regconres">
				<div class="regconres-p">请确认您输入的企业名称正确，并尽量使用我们建议的企业名称。</div>
				<div class="regconres-p">注册成功后，企业名称不能修改，敬请留意！</div>
			</div>
			<div slot="footer" class="register-regfooter">
				<!-- <span class="regfooter-reghint" v-show="registrHint">{{registrHint}}</span> -->
				<span class="regfooter-reghint" v-show="timerPageShow">注册成功！{{pageCount}}秒后自动进入下一页...</span><!--注册成功！{{pageCount}}秒后自动进入下一页...-->
				<span class="regfooter-regbtnbox">
					<button class="regfooter-footbtn" type="primary" :disabled="submitDisabled" @click="verifyClick">{{submitText}}</button><!--确 定-->
					<button class="regfooter-footbtn regfooter-cancelbtn" @click="registerResultShow = false">取 消</button>
				</span>
				<!-- <el-button class="pefooter-submitbtn" type="primary" @click="submitCreateHandle">确 定</el-button>
				<el-button @click="goodsCreatePopupShow = false">取 消</el-button> -->
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Banner from '../components/banner.vue'
	
	export default {
		data() {
			return {
				bannerTitle: '企业技术创新力在线自测系统', // banner背景图title
				userPhoneVal: '', // 登录-手机号
				retrievePhoneVal: '', // 注册-手机号
				retrieveCodeVal: '', // 注册-验证码
				newPasswordVal: '', // 注册-新密码
				repeatPasswordVal: '', // 注册-重复密码
				show: true, // 发送验证码倒计时显示
				count: 0, // 发送验证码倒计时显示
				sendCode: true, // 如果手机号已注册，则为false,不可发验证码
				
				registerResultShow: false, // 提交注册-结果提示框
				pageShow: true,
				pageCount: 3,
				timerPageShow: false, // 注册成功，3s跳转页面提示
				countTimer: '',
				setTimer: '',
				
				phoneTrue: false, // 判断输入的手机号是否已注册，未注册的话才倒计时
				
				companyInput: '', // 输入企业名称
				phoneHint: '', // 验证手机号提示
				codeHint: '', // 验证码提示
				pwdHint: '8-16字符，至少同时包含字母和数字', // 密码验证提示
				pwdRepeatHint: '', // 重复密码验证
				registrHint: '', // 注册接口验证提示
				companyHint: '',
				submitDisabled: false, // 点击注册时-设置按钮不可点，注册成功过后可再次点击
				submitText: '确 定'
			}
		},
		computed:{
			
		},
		created() {
			
		},
		mounted(){
			
		},
		components: {
			Banner
		},
		methods: {
			phoneChange(val) { // 新用户注册-手机号验证-验证手机号是否已注册接口
				let _this = this
				console.log('新用户注册-手机号=' + val)
				let qsData = _this.$qs.stringify({
					mobile: _this.retrievePhoneVal,
					type: 'register', // 注册
				});
				_this.$axios({
					method: 'post',
					url:'/user/test_mobile',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 200){
						_this.phoneHint = ''
						_this.phoneTrue = true
					}else{
						_this.phoneTrue = false
						if(res.data.msg == '参数重复') {
							_this.phoneHint = '该手机号已注册'
							// _this.sendCode = false
						}else{
							_this.phoneHint = res.data.msg
						}
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			codeChange(val) { // 新用户注册-验证码
				console.log('新用户注册-验证码=' + val)
				let _this = this
				if(_this.retrieveCodeVal == '') {
					_this.codeHint = '验证码不能为空！'
				}else{
					_this.codeHint = ''
				}
			},
			newPasswordChange(val) { // 新用户注册-新密码
				console.log('新用户注册-新密码=' + val)
				let _this = this
				let regPwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
				if(_this.newPasswordVal == '') {
					_this.pwdHint = '8-16字符，至少同时包含字母和数字'
				}else if(regPwd.test(_this.newPasswordVal)) {
					_this.pwdHint = '8-16字符，至少同时包含字母和数字'
				}else{
					_this.pwdHint = '密码格式不符合要求！'
				}
			},
			// repeatPasswordChange(val) { // 新用户注册-重复密码
			// 	console.log('新用户注册-重复密码=' + val)
			// 	let _this = this
			// 	_this.pwdRepeatHint = ''
			// 	if(_this.repeatPasswordVal != '') {
			// 		if(_this.repeatPasswordVal == _this.newPasswordVal) {
			// 			_this.pwdRepeatHint = ''
			// 		}else{
			// 			_this.pwdRepeatHint = '两次输入的密码不一致!'
			// 		}
			// 	}else{
			// 		_this.pwdRepeatHint = '确认密码不能为空！'
			// 	}
			// },
			repeatPasswordBlur() {
				let _this = this
				console.log('新用户注册-重复密码=' + _this.repeatPasswordVal + '=====' + _this.newPasswordVal)
				_this.pwdRepeatHint = ''
				if(_this.repeatPasswordVal != '') {
					if(_this.repeatPasswordVal == _this.newPasswordVal) {
						_this.pwdRepeatHint = ''
					}else{
						_this.pwdRepeatHint = '两次输入的密码不一致!'
					}
				}else{
					_this.pwdRepeatHint = '确认密码不能为空！'
				}
			},
			regSubmitClick() { // 点击提交注册-提示框显示
				let _this = this
				_this.submitDisabled = false
				_this.submitText = '确 定'
				if(_this.retrievePhoneVal != '' && _this.retrieveCodeVal != '' && _this.newPasswordVal != '' && _this.repeatPasswordVal != '' && _this.companyInput != '') {
					_this.registerResultShow = true;
				}else{
					if(_this.retrievePhoneVal == '') {
						_this.phoneHint = '手机号不能为空！'
					}
					if(_this.retrieveCodeVal == '') {
						_this.codeHint = '验证码不能为空！'
					}
					if(_this.newPasswordVal == '') {
						_this.pwdHint = '密码不能为空！'
					}
					if(_this.repeatPasswordVal == '') {
						_this.pwdRepeatHint = '重复密码不能为空！'
					}
					if(_this.companyInput == '') {
						_this.companyHint = '请输入要绑定的企业！'
					}
				}
			},
			getCode() { // 获取验证码-倒计时120s-发送验证码接口
				let _this = this
				const TIME_COUNT = 120
				console.log(_this.phoneTrue + '===========================')
				if(!_this.setTimer && !!_this.phoneTrue) { //  && !!_this.phoneTrue
					_this.count = TIME_COUNT
					_this.show = false;
					_this.setTimer = setInterval(() => {
						if (_this.count > 0 && _this.count <= TIME_COUNT) {
							_this.count--;
						}else {
							_this.phoneHint = '';
							_this.show = true;
							clearInterval(_this.setTimer);
							_this.setTimer = null;
						}
					}, 1000)
					if(_this.count > 0 && _this.count <= TIME_COUNT) {
						let qsData = _this.$qs.stringify({
							mobile: _this.retrievePhoneVal,
							type: 'register', // 注册
						});
						_this.$axios({
							method: 'post',
							url: '/user/sendmsg',
							data: qsData,
							// baseUrl: _this.urlCommon,
							headers: {'X-Requested-With': 'XMLHttpRequest'}
						}).then(function (res) {
							console.log(res)
							// console.log(res.data.code == 200)
							if(res.data.code == 200){
								_this.codeHint = ''
							}else{
								_this.codeHint = res.data.msg
							}
						}).catch(function (e) {
							console.log('error------------------------------error');
							console.log(e.response)
						});
					}
				}
			},
			verifyClick() { // 提交注册-确认-注册接口
				let _this = this
				_this.submitDisabled = true
				_this.submitText = '注册中...'
				let qsData = _this.$qs.stringify({
					mobile: _this.retrievePhoneVal,  // 注册-手机号
					username: _this.retrievePhoneVal,
					password: _this.newPasswordVal, // 注册-新密码
					repassword: _this.repeatPasswordVal, // 注册-重复密码
					company: _this.companyInput, // 输入企业名称
					msgcode : _this.retrieveCodeVal, // 注册-验证码
				});
				_this.$axios({
					method: 'post',
					url: '/user/register',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 200){
						// _this.codeHint = ''
						_this.goPage()
						_this.timerPageShow = true
						// _this.submitDisabled = false
						_this.submitText = '确 定'
						// localStorage.setItem('tel', _this.retrievePhoneVal);
						// localStorage.setItem('pwd', _this.newPasswordVal);
						// _this.registrHint = '注册成功！' + _this.pageCount + '秒后自动进入下一页...'
						// _this.retrievePhoneVal = ''
						// _this.retrieveCodeVal = ''
						// _this.newPasswordVal = ''
						// _this.repeatPasswordVal = ''
						// _this.companyInput = ''
					}else{
						// _this.$message({
						// 	message: res.data.msg,
						// 	type: 'error'
						// });
						_this.submitDisabled = false
						_this.submitText = '确 定'
						if(res.data.msg == '输入正确手机号') { 
							_this.phoneHint = res.data.msg
						}
						if(res.data.msg == '验证码输入错误') {
							_this.codeHint = res.data.msg + '！'
						}
						if(res.data.msg == '验证码已过期') {
							_this.codeHint = res.data.msg + '！'
						}
						if(res.data.msg == '验证码超时') {
							_this.codeHint = res.data.msg + '！'
						}
						if(res.data.msg == '两次密码不一致') {
							_this.pwdRepeatHint = res.data.msg
						}
						if(res.data.msg == '用户手机号已经存在') {
							_this.phoneHint = res.data.msg
						}
						_this.registerResultShow = false
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			goPage() { // 注册成功跳转页面-倒计时
				const count_down = 3;
				if (!this.countTimer) {
					this.pageCount = count_down;
					this.countTimer = setInterval(() => {
						if (this.pageCount > 0 && this.pageCount <= count_down) {
							this.pageCount--;
						}else {
							this.timerPageShow = false;
							clearInterval(this.countTimer);
							this.countTimer = null;
							this.submitDisabled = false
							// this.submitText = '确 定'
							this.$router.push("/welcome")
							// this.$router.push({name:'Login', params:{tel: this.retrievePhoneVal});
							// this.registerLogin() // 注册完成之后调登录接口
						}
					}, 1000)
				}
			},
			registerLogin() {
				let _this = this
				// console.log('登录')
				// if(_this.userPhoneVal == '' || _this.userPasswordVal == '') {
				// 	_this.loginHint = '用户名或密码不能为空！'
				// 	return
				// }
				console.log(_this.retrievePhoneVal + '===========' + _this.newPasswordVal)
				let qsData = _this.$qs.stringify({
					mobile: _this.retrievePhoneVal,
					password: _this.newPasswordVal,
				});
				_this.$axios({
					method: 'post',
					url:'/login',
					data: qsData,
					baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'},
					// headers: {'Content-Type':'application/json;'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 200){
						_this.loginHint = ''
						// console.log(res.data.url)
						// if(res.data.url == '/') {
						_this.$router.push('/welcome');
						// }else{
						// 	//直接跳转
						// 	_this.$router.push(res.data.url);
						// }
						// _this.$router.push({name: 'Welcome', params: {url: '/'}});
						//带参数跳转
						// _this.$router.push({path:'/testDemo',query:{setid:123456}});
						// _this.$router.push({name:'testDemo',params:{setid:111222}});
					}else{
						_this.loginHint = res.data.msg
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			
			// 绑定的企业 ********************************************
			searchSelect(item) {
				console.log(item)
				if(!item || item.length < 0){
					return;
				}
				console.log(item.value)
				this.companyInput = item.present;
				// console.log(this.companyInput)
			},
			nameTipFn(queryString, cb) { // 搜索企业名称时-推荐结果列表-模糊查询企业名接口
				cb([]); 
				// console.log(queryString)
				let _this = this
				if(_this.companyInput == '') {
					_this.companyHint = '请输入要绑定的企业！'
				}else{
					_this.companyHint = ''
				}
				let qsData = _this.$qs.stringify({
					keyword: _this.companyInput,  
				});
				
				_this.$axios({
					method: 'post',
					url:'/company/suggest',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 200){
						let dataTemp = res.data.data;
						// console.log(dataTemp)
						_this.nameTipData = []
						for(let i = 0; i < dataTemp.length; i++){
							_this.nameTipData.push({'value': dataTemp[i].title, 'val': dataTemp[i].id, 'present': dataTemp[i].present});
						}
						// console.log(_this.nameTipData)
						// let results = queryString ? _this.nameTipData.filter(_this.createFilter(queryString)) : [];
						// console.log(results)
						// cb(results);
						cb(_this.nameTipData)
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			// createFilter(queryString) {
			// 	return (data) => {
			// 		return (data.value.indexOf(queryString) === 0);
			// 	};
			// },
			// *************************************************************
		},
		beforeDestory(){
			clearTimeout(this.setTimer)
			this.setTimer = null;
			clearTimeout(this.countTimer)
			this.countTimer = null;
		}
	}
</script>

<style scoped>
	.nav-content{margin: 0 auto 60px;/* padding-top: 20px; */background: rgb(245, 245, 245);/* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */}
	.m-b0{margin-bottom: 0;}
	
	.height510{height: 510px;box-sizing: border-box;}
	/* 注册-内容 */
	.register-box{margin: auto;/* padding-bottom: 40px; */width: 1200px;}
	.register-box::after{display: block;content: '';clear: both;}
	
	/* 新用户注册 */
	.register-reguserpart{float: left;}
	.reguserpart-regtitle{display: block;padding: 40px 0;text-align: left;color: rgb(65, 199, 219);/* color: #E72D6B; */font-weight: 700;font-size: 20px;}
	.reguserpart-regcon{}
	.retrcon-retrbox:first-child{margin-top: 0px;}
	.retrcon-retrbox{margin-top: 20px;}
	.retrcon-retrbox::after{display: block;content: '';clear: both;}
	.retrcon-retrinput{float: left;width: 300px;height: 40px;}
	.retrcon-retrinput /deep/ .el-input__inner{padding: 0 10px;height: 40px;line-height: 40px;border-radius: 0px;box-sizing: border-box;font-size: 16px;}
	.retrcon-retrcodebtn{display: block;float: left;margin-left: 10px;padding: 0;/* padding: 0 10px; */width: 120px;height: 40px;line-height: 40px;background: rgb(65, 199, 219);box-sizing: border-box;color: #ffffff;font-size: 16px;}
	.retrcon-retrcodebtn-active{background: #c8c9cc;}
	.retrcon-retrcodebtn-active.el-button{border-radius: 0px !important;}
	.retrcon-retrhint{display: block;float: left;margin-left: 10px;line-height: 40px;color: #E72D6B;font-size: 16px;}
	.retrcon-retrtishi{color: rgb(102, 102, 102);}
	.color-red{color: #E72D6B;}
	
	/* 用户绑定企业 */
	.register-companypart{float: right;position: relative;margin-top: 101px;width: 400px;height: 180px;box-sizing: border-box;}
	.companypart-box{/* margin-bottom: 20px; */}
	.companypart-box:last-child{margin-bottom: 0px;}
	.companypart-box::after{display: block;content: '';clear: both;}
	.companypart-hint{/* position: absolute;top: 54px;left: 0; */margin-top: 4px;text-align: left;color: #E72D6B;font-size: 16px;}
	.companypart-loginput{display: block;margin-right: 20px;width: 400px;height: 40px;}
	.companypart-loginput /deep/ .el-input__inner{padding: 0 10px;height: 40px;line-height: 40px;border-radius: 0;box-sizing: border-box;font-size: 16px;}
	.companypart-submitbtn{display: block;position: absolute;top: 40px;right: 0;margin-top: 20px;padding: 0;width: 150px;height: 40px;line-height: 40px;background: rgb(65, 199, 219);box-sizing: border-box;color: rgb(255, 255, 255);font-weight: 400;font-size: 16px;}
	
	/* 提交注册-提示结果-弹框 */
	.popup-registerres{}
	.popup-registerres /deep/ .el-dialog{margin-top: 30vh !important;width: 600px;}
	.popup-registerres /deep/ .el-dialog__header{padding: 0;}
	.popup-registerres /deep/ .el-dialog__headerbtn{top: 22px;}
	.popup-registerres /deep/ .el-dialog__body{padding: 20px 20px;text-align: left;}
	.register-regtitle{color: #333333;font-weight: 700;font-size: 16px;}
	.register-regconres{margin-top: 20px;}
	.regconres-p{margin-bottom: 10px;color: #333333;font-size: 16px;}
	.regconres-p:last-child{margin-bottom: 0;color: #333333;font-size: 16px;}
	/* 保存/取消-按钮 */
	.register-regfooter{text-align: center;}
	.register-regfooter::after{display: block;content: '';clear: both;}
	.regfooter-reghint{display: block;float: left;/* margin-left: 10px; */line-height: 30px;color: #E72D6B;}
	.regfooter-regbtnbox{display: block;float: right;}
	.regfooter-footbtn{display: block;float: left;margin-left: 10px;padding: 0;width: 100px;height: 40px;line-height: 40px;background: rgb(65, 199, 219);/* background: #E72D6B; */box-sizing: border-box;color: #ffffff;font-size: 16px;}
	.regfooter-cancelbtn{line-height: 38px;border: 1px solid rgb(65, 199, 219);/* border: 1px solid #E72D6B; */background: #ffffff;color: #333333;}
	
	
</style>
