<template>
	<div class="nav-content">
		<Banner :bannerTitle="bannerTitle"></Banner>
		<!-- <div class="article-box">
			<div class="article-title">欢迎您，来自科创中国的用户！</div>
		</div> -->
		<div class="welcome-box">
			<div class="welcome-floatpart welcome-curenterpart">
				<div class="curenterpart-entercon">
					<span class="entercon-entertle">当前登录企业</span>
					<span class="entercon-companyname">{{companyName}}</span><!--北京科华万象科技有限公司-->
				</div>
			</div>
			<div class="welcome-rightpart welcome-pagepart">
				<div class="pagepart-pagecom">本次测评结果</div>
			</div>
		</div>
		<!-- 测评结果 -->
		<div class="evaluate-result">
			<div class="result-echart-box">
				<div class="result-echart">
					<div class="echart-bgbox">
						<div class="echart-box">
							<EchartGraph :nodes="nodes" :links="links"></EchartGraph><!--:graph="graph"-->
						</div>
					</div>
				</div>
				<div class="result-detail">
					<div class="detail-infobox detail-comscore">
						<span class="comscore-comfont comscore-comres">{{comScore ? parseFloat(comScore).toFixed(2) : 0}}分</span>
						<span class="comscore-comfont comscore-comname">企业技术创新力</span>
						<span class="comscore-comfont comscore-comversions">（基础版）</span>
					</div>
					<div class="detail-infobox detail-itemscore">
						<div class="itemscore-itembox itemscore-innovtalscore">
							<span class="itemscore-itemres">
								<span class="">创新人才</span>
								<span class="">{{scoreVal.innovtalScore ? parseFloat(scoreVal.innovtalScore).toFixed(2) : 0}}</span>
							</span>
							<div class="itemscore-barbox">
								<EchartBar :barColor="'rgb(159, 144, 188)'" :echartDatas="scoreVal.innovtalPercent ? [scoreVal.innovtalPercent] : [0]" :maxVal="100"></EchartBar>
							</div>
							<span class="itemscore-itemhint" v-if="scoreVal.innovtalPercent <= 40">被集群内 {{100 - scoreVal.innovtalPercent}}% 的企业超越</span>
							<span class="itemscore-itemhint" v-else>超越了集群内 {{scoreVal.innovtalPercent}}% 的企业</span>
						</div>
						<div class="itemscore-itembox itemscore-innovinpscore">
							<span class="itemscore-itemres">
								<span class="">创新投入</span>
								<span class="">{{scoreVal.innovinpScore ? parseFloat(scoreVal.innovinpScore).toFixed(2) : 0}}</span>
							</span>
							<div class="itemscore-barbox">
								<EchartBar :barColor="'rgb(29, 105, 150)'" :echartDatas="scoreVal.innovinpPercent ? [scoreVal.innovinpPercent] : [0]" :maxVal="100"></EchartBar>
							</div>
							<span class="itemscore-itemhint" v-if="scoreVal.innovinpPercent <= 40">被集群内 {{100 - scoreVal.innovinpPercent}}% 的企业超越</span>
							<span class="itemscore-itemhint" v-else>超越了集群内 {{scoreVal.innovinpPercent}}% 的企业</span>
						</div>
						<div class="itemscore-itembox itemscore-innovoutscore">
							<span class="itemscore-itemres">
								<span class="">创新产出</span>
								<span class="">{{scoreVal.innovoutScore ? parseFloat(scoreVal.innovoutScore).toFixed(2) : 0}}</span>
							</span>
							<div class="itemscore-barbox">
								<EchartBar :barColor="'rgb(237, 173, 8)'" :echartDatas="scoreVal.innovoutPercent ? [scoreVal.innovoutPercent] : [0]" :maxVal="100"></EchartBar>
							</div>
							<span class="itemscore-itemhint" v-if="scoreVal.innovoutPercent <= 40">被集群内 {{100 - scoreVal.innovoutPercent}}% 的企业超越</span>
							<span class="itemscore-itemhint" v-else>超越了集群内 {{scoreVal.innovoutPercent}}% 的企业</span>
						</div>
						<div class="itemscore-itembox itemscore-greendevelscore">
							<span class="itemscore-itemres">
								<span class="">绿色发展</span>
								<span class="">{{scoreVal.greendevelScore ? parseFloat(scoreVal.greendevelScore).toFixed(2) : 0}}</span>
							</span>
							<div class="itemscore-barbox">
								<EchartBar :barColor="'rgb(115, 175, 72)'" :echartDatas="scoreVal.greendevelPercent ? [scoreVal.greendevelPercent] : [0]" :maxVal="100"></EchartBar>
							</div>
							<span class="itemscore-itemhint" v-if="scoreVal.greendevelPercent <= 40">被集群内 {{100 - scoreVal.greendevelPercent}}% 的企业超越</span>
							<span class="itemscore-itemhint" v-else>超越了集群内 {{scoreVal.greendevelPercent}}% 的企业</span>
						</div>
					</div>
				</div>
			</div>
			<div class="result-reshint">
				<div class="reshint-resarticle">* 图示给出了技术创新力的构成维度和相关指标。圆形的面积越大，代表该项维度或指标的得分越高。</div>
				<div class="reshint-resarticle">* 灰色圆形代表因缺失数据而无法给出评价的指标或维度。要获得更加准确的测评结果，请返回并补充缺失的数据。</div>
			</div>
			<!-- 参考评价 -->
			<div class="result-resrefer" v-if="overallMerit || referEvals.innovtalEval || referEvals.innovinpEval || referEvals.innovoutEval || referEvals.greendevelEval">
				<div class="resrefer-refertle">参考评价</div>
				<div class="resrefer-rconclusive weight-700" v-if="overallMerit">
					<div class="rconclusive-rconicon"></div>
					<div class="rconclusive-rconarticle">{{overallMerit}}</div>
				</div>
				<div class="resrefer-rconclusive" v-if="referEvals.innovtalEval">
					<div class="rconclusive-rconicon rconicon-cxrc-bgcolor"></div>
					<div class="rconclusive-rconarticle">{{referEvals.innovtalEval}}</div>
				</div>
				<div class="resrefer-rconclusive" v-if="referEvals.innovinpEval">
					<div class="rconclusive-rconicon rconicon-cxtr-bgcolor"></div>
					<div class="rconclusive-rconarticle">{{referEvals.innovinpEval}}</div>
				</div>
				<div class="resrefer-rconclusive" v-if="referEvals.innovoutEval">
					<div class="rconclusive-rconicon rconicon-cxcc-bgcolor"></div>
					<div class="rconclusive-rconarticle">{{referEvals.innovoutEval}}</div>
				</div>
				<div class="resrefer-rconclusive" v-if="referEvals.greendevelEval">
					<div class="rconclusive-rconicon rconicon-lsfz-bgcolor"></div>
					<div class="rconclusive-rconarticle">{{referEvals.greendevelEval}}</div>
				</div>
			</div>
		</div>
		
		<!-- 下载报告 -->
		<div class="evaluate-evaopera">
			<router-link class="evaopera-btn evaopera-goback" to="/welcomeBasic">返回</router-link>
			<button class="evaopera-btn evaopera-download" v-if="downloadText == '下载本次测评报告'" @click="downloadReport">{{downloadText}}</button>
			<button class="evaopera-btn evaopera-download" v-else-if="downloadText == '生成失败，请重新生成报告'" @click="regenerateReport">{{downloadText}}</button>
			<span class="evaopera-btn evaopera-download" v-else>{{downloadText}}</span>
		</div>
		
		<!-- 意见反馈-弹框 -->
		<el-dialog
			class="popup-feedbackbox"
			:visible.sync="feedbackPopupShow"
			:show-close="false"
			:close-on-click-modal="false">
			<!-- <span class="submitconfirm-confirmtitle">找回密码</span> -->
			<div class="feedbackbox-feedbackcon">
				<div class="confirmcon-poptle">感谢使用！</div>
				<div class="confirmcon-puthint">开展企业创新力在线自测的体验如何？请尝试花1分钟时间提供您的意见，以便我们作出更符合您需求的改进。期待您的反馈！</div>
			</div>
			<div slot="footer" class="feedbackcon-feedbackfooter">
				<button class="feedbackfooter-footbtn feedbackfooter-cancelbtn" @click="feedbackClose">再用一阵</button>
				<button class="feedbackfooter-footbtn" type="primary" @click="feedbackHandle">我要反馈</button>
			</div>
		</el-dialog>
		
		<!-- 未登录-跳转登录页-弹框 -->
		<LoginHint :goLoginPopupShow="goLoginPopupShow"></LoginHint>
		
	</div>
</template>

<script>
	import Banner from '../../components/banner.vue'
	import LoginHint from '../../components/loginHint.vue'
	import EchartGraph from '../../components/echartGraph.vue'
	import EchartBar from '../../components/echartBar.vue'
	
	export default {
		data() {
			return {
				bannerTitle: '企业技术创新力在线自测系统 - 基础版', // banner背景图title
				companyName: '', // 当前绑定企业名称
				goLoginPopupShow: false, // 未登录-跳转登录页-弹框显示/隐藏
				comScore: 0,
				scoreVal: {
					innovtalScore: 0,
					innovtalPercent: '0%',
					innovinpScore: 0,
					innovinpPercent: '0%',
					innovoutScore: 0,
					innovoutPercent: '0%',
					greendevelScore: 0,
					greendevelPercent: '0%',
				},
				barColor: '', // 柱状图颜色
				echartDatas: [5.6], // 柱状图数据
				maxVal: 100, // 最大值
				nodes: [], // 关系图数据
				links: [], // 关系图数据
				graph: {}, // 关系图数据
				
				reportId: '', // 生成报告的id
				reportState: '', // 生成报告的状态
				timer: '',
				countTime: 10,
				downloadText: '下载本次测评报告',
				
				overallMerit: '', // 企业技术创新力评价
				referEvals: {
					innovtalEval: '',
					innovinpEval: '',
					innovoutEval: '',
					greendevelEval: ''
				},
				
				feedbackPopupShow: false, // 意见反馈弹框显示
				feedbackType: '',
				feedbackShow: true, // 判断每天显示一次或七天内显示一次
			}
		},
		computed:{
			
		},
		created() {
			this.getCompany()
			this.getData()
		},
		mounted(){
		},
		updated() {
		},
		components: {
			Banner,
			LoginHint,
			EchartGraph,
			EchartBar
		},
		methods: {
			getCompany() { // 获取当前绑定企业名称
				let _this = this
				// let qsData = _this.$qs.stringify({
				// 	mobile: _this.retrievePhoneVal,
				// });
				_this.$axios({
					method: 'post',
					url: '/user/online/find',
					// data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					// console.log(res.data.code == 200)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					if(res.data.code == 200){
						_this.companyName = res.data.data.company
						// _this.generateReport() // 生成报告
					}else{
						_this.companyName = ''
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			// 获取测评数据
			getData() {
				let _this = this
				// let qsData = _this.$qs.stringify({
				// 	mobile: _this.retrievePhoneVal,
				// });
				_this.$axios({
					method: 'post',
					url: '/company/online/evaluation?edition=b', // edition  传值：b:基础版 p:专业版
					// data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					if(res.data.code == 200){
						_this.nodes =  res.data.data.node
						_this.links = res.data.data.links
						// _this.graph.nodes = res.data.data.node
						// _this.graph.links = res.data.data.links
						_this.comScore = res.data.data.title_score
						_this.overallMerit = res.data.data.text
						
						// console.log(_this.comScore)
						for(let i = 0; i < res.data.data.dimension.length; i++) {
							if(res.data.data.dimension[i].name == '创新人才') {
								_this.scoreVal.innovtalScore = res.data.data.dimension[i].score
								_this.scoreVal.innovtalPercent = res.data.data.dimension[i].proportion
								_this.referEvals.innovtalEval =  res.data.data.dimension[i].text
							}
							if(res.data.data.dimension[i].name == '创新投入') {
								_this.scoreVal.innovinpScore = res.data.data.dimension[i].score
								_this.scoreVal.innovinpPercent = res.data.data.dimension[i].proportion
								_this.referEvals.innovinpEval =  res.data.data.dimension[i].text
							}
							if(res.data.data.dimension[i].name == '创新产出') {
								_this.scoreVal.innovoutScore = res.data.data.dimension[i].score
								_this.scoreVal.innovoutPercent = res.data.data.dimension[i].proportion
								_this.referEvals.innovoutEval =  res.data.data.dimension[i].text
							}
							if(res.data.data.dimension[i].name == '绿色发展') {
								_this.scoreVal.greendevelScore = res.data.data.dimension[i].score
								_this.scoreVal.greendevelPercent = res.data.data.dimension[i].proportion
								_this.referEvals.greendevelEval =  res.data.data.dimension[i].text
							}
						}
						_this.reportId = res.data.report
						// console.log(res.data)
						_this.getState()
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
				
			},
			
			getState() { // 下载报告之--获取报告的状态
				let _this = this
				let qsData = _this.$qs.stringify({
					id: _this.reportId,
				})
				_this.$axios({
					method: 'post',
					url:'/report/findState',
					data: qsData,
					// baseUrl: urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					// console.log('获取报告状态***********************')
					console.log(res)
					if(res.data.code == 200){
						_this.reportState = res.data.state; // 1 未生成 2正在生成 3 完成 4生成失败 5已删除
						// console.log('报告状态=======' + _this.reportState)
						if(_this.reportState == '1' || _this.reportState == '4') {
							_this.downloadText = '生成失败，请重新生成报告'
						}else if(_this.reportState == '2') {
							_this.downloadText = '正在生成报告，请稍后下载...'
							const TIME_COUNT = 5;
							if (!_this.timer) { // 隔5s刷新请求状态
								_this.countTime = TIME_COUNT;
								_this.timer = setInterval(() => {
									if (_this.countTime > 0 && _this.countTime <= TIME_COUNT) {
										_this.countTime--;
									}else {
										clearInterval(_this.timer);
										_this.timer = null;
										// console.log('刷新')
										_this.getState();
									}
								}, 1000)
							}
						}else if(_this.reportState == '3') {
							_this.downloadText = '下载本次测评报告'
						}
						// console.log(_this.downloadText)
					}
				})
			},
			regenerateReport() { // 下载报告之--未生成或生成失败，重新生成报告
				let _this = this
				let qsData = _this.$qs.stringify({
					id: _this.reportId,
					type: 'b' //（b基础版，p专业版）
				});
				_this.$axios({
					method: 'post',
					url: '/report/regenerate',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					// console.log('重新生成报告***********************')
					console.log(res)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					if(res.data.code == 200){
						_this.getState() // 获取生成报告的状态
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			// 下载报告-直接显示下载报告************************************
			downloadReport() {
				let _this = this
				// 	let qsData = this.$qs.stringify({
				// 		date: adminJson
				// 	});
				_this.$axios({
					method: 'post',
					url: '/report/download?id=' + _this.reportId,
					// data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {
						'X-Requested-With': 'XMLHttpRequest',
						'Content-Type': 'application/x-www-form-urlencoded', //请求的数据类型为form data格式
					},
					'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
				}).then(function (res) {
					console.log(res)
					console.log(res.headers['content-disposition'])
					let fileNames = res.headers['content-disposition'].split(';')[1]
					let fileName = decodeURI(fileNames.substring(fileNames.indexOf('=') + 1))
					console.log(fileName)
					const blob = new Blob([res.data]);
					// const fileName = '基础版-行政类.doc';
					const linkNode = document.createElement('a');
								
					linkNode.download = fileName; //a标签的download属性规定下载文件的名称
					linkNode.style.display = 'none';
					linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
					document.body.appendChild(linkNode);
					linkNode.click();  //模拟在按钮上的一次鼠标单击
					
					// _this.feedbackPopupShow = true;
					_this.goFeedback('')
					
					URL.revokeObjectURL(linkNode.href); // 释放URL 对象
					document.body.removeChild(linkNode);
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			
			feedbackClose() { // 我要反馈弹框-再用一阵
				this.feedbackPopupShow = false
				this.feedbackType = '1'
				this.goFeedback(this.feedbackType)
			},
			feedbackHandle() { // 我要反馈弹框-跳转反馈页面
				this.feedbackPopupShow = false
				this.feedbackType = '2'
				this.goFeedback(this.feedbackType)
				// window.location.href = 'https://wj.qq.com/s2/9344581/63fc/'
				window.open('https://wj.qq.com/s2/9344581/63fc/', '_blank')
			},
			goFeedback(feedbackType) {
				let _this = this
				console.log(feedbackType)
				let qsData = _this.$qs.stringify({
					type: feedbackType // 再用一阵（取值1）我要反馈 （取值2）
				});
				_this.$axios({
					method: 'post',
					url: '/user/online/feedback',
					data: qsData,
					// baseUrl: _this.urlCommon,
					headers: {'X-Requested-With': 'XMLHttpRequest'}
				}).then(function (res) {
					console.log(res)
					if(res.data.code == 401) {
						_this.goLoginPopupShow = true
						return
					}
					if(res.data.code == 200){
						_this.feedbackShow = res.data.status
						if(_this.feedbackShow == true) {
							_this.feedbackPopupShow = true
						}else{
							_this.feedbackPopupShow = false
						}
					}
				}).catch(function (e) {
					console.log('error------------------------------error');
					console.log(e.response)
				});
			},
			
		},
		beforeDestory(){
			clearTimeout(this.timer)
			this.timer = null;
		}
	}
</script>

<style scoped>
	.nav-content{margin: 0 auto 60px;/* padding-top: 20px; */background: rgb(245, 245, 245);/* background: linear-gradient(180deg, #fff 0%, #fff 60%, #e6f4ff 100%); */}
	.article-box{margin: auto;padding: 40px 0;width: 1200px;text-align: left;color: rgb(0, 0, 0);font-weight: 400;font-size: 16px;font-style: normal;text-decoration: none;}
	.article-title{margin-bottom: 00px;font-weight: 700;font-size: 20px;}
	.article-p{margin-bottom: 0px;line-height: 30px;}
	.article-p:last-child{margin-bottom: 0px;}
	
	/* 欢迎页-内容 */
	.welcome-box{margin: 40px auto 0;padding-bottom: 40px;width: 1200px;border-bottom: 1px solid #666666;}
	.welcome-box::after{display: block;content: '';clear: both;}
	
	/* 当前登录企业 */
	.welcome-floatpart{float: left;}
	.curenterpart-entercon{}
	.entercon-entertle{display: inline-block;margin-right: 10px;width: 160px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: #fff;box-sizing: border-box;font-size: 16px;}
	.entercon-companyname{display: inline-block;width: 400px;height: 40px;line-height: 38px;border: 1px solid #DCDFE6;background: #fff;box-sizing: border-box;font-size: 16px;}
	
	.retrcon-retrinput{float: left;width: 300px;height: 30px;}
	.retrcon-retrinput /deep/ .el-input__inner{height: 30px;border-radius: 0px;}
	.retrcon-retrcodebtn{display: block;float: left;margin-left: 10px;padding: 0 10px;line-height: 30px;background: rgb(65, 199, 219);/* background: #E72D6B; */color: #ffffff;}
	.retrcon-retrcodebtn-active{display: block;float: left;margin-left: 10px;padding: 0 10px;height: 30px;line-height: 30px;background: #c8c9cc;box-sizing: border-box;color: #ffffff;}
	.retrcon-retrcodebtn-active.el-button{border-radius: 0px !important;}
	.retrcon-retrhint{display: block;float: left;margin-left: 10px;line-height: 30px;color: #E72D6B;}
	.retrcon-retrtishi{color: rgb(102, 102, 102);}
	
	/* right页面显示 */
	.welcome-rightpart{float: right;position: relative;width: 290px;box-sizing: border-box;}
	.pagepart-pagecom{display: block;width: 290px;height: 40px;line-height: 40px;box-sizing: border-box;background: rgb(200, 198, 198);color: #333333;font-weight: 400;font-size: 16px;}
	.pagepart-pagespec{margin-top: 10px;background: rgb(241, 194, 50);}
	
	.companypart-box{margin-bottom: 20px;}
	.companypart-box:last-child{margin-bottom: 0px;}
	.companypart-box::after{display: block;content: '';clear: both;}
	.companypart-loginput{display: block;margin-right: 20px;width: 400px;height: 50px;}
	.companypart-loginput /deep/ .el-input__inner{height: 50px;line-height: 50px;font-size: 16px;}
	.companypart-submitbtn{display: block;position: absolute;bottom: 2px;right: 0;padding: 0;width: 150px;height: 30px;line-height: 30px;background: rgb(65, 199, 219);/* background: #E72D6B; */color: rgb(255, 255, 255);font-weight: 400;font-size: 16px;}
	.companypart-hint{position: absolute;top: 54px;left: 0;color: #E72D6B;}
	
	/* 测评结果 */
	.evaluate-result{margin: 0 auto;padding: 20px 0 20px;width: 1200px;border-bottom: 1px solid #666666;}
	.result-echart-box{display: flex;}
	/* 图表left */
	.result-echart{width: 75%;}
	.echart-bgbox{margin-right: 20px;height: 100%;background: rgb(254, 248, 239);}
	.echart-box{height: 766px;}
	/* right */
	.result-detail{width: 25%;box-sizing: border-box;}
	.detail-infobox{margin-top: 30px;margin-left: 0px;background: rgb(254, 248, 239);}
	.detail-infobox:first-child{margin-top: 0;}
	.comscore-comfont{display: block;white-space: nowrap;text-align: center;color: rgb(204, 80, 62);font-weight: 700;font-size: 50px;font-style: normal;text-decoration: none;font-family: "Segoe UI";}
	.comscore-comres{padding: 42px 0 10px;}
	.comscore-comname{font-size: 30px;font-family: "Segoe UI";}
	.comscore-comversions{padding-bottom: 45px;font-size: 30px;font-family: "Segoe UI";}
	/* 各分值 */
	.detail-itemscore{padding: 30px 50px 40px;}
	.itemscore-itembox{margin-bottom: 30px;}
	.itemscore-itembox:last-child{margin-bottom: 0;}
	.itemscore-innovtalscore .itemscore-itemres{color: rgb(159, 144, 188);}
	.itemscore-innovinpscore .itemscore-itemres{color: rgb(29, 105, 150);}
	.itemscore-innovoutscore .itemscore-itemres{color: rgb(237, 173, 8);}
	.itemscore-greendevelscore .itemscore-itemres{color: rgb(115, 175, 72);}
	.itemscore-itemres{display: flex;justify-content: space-between;margin-bottom: 5px;white-space: nowrap;text-align: left;font-weight: 700;font-size: 20px;font-style: normal;text-decoration: none;font-family: "Segoe UI";}
	.itemscore-barbox{height: 30px;}
	.itemscore-itemhint{display: block;margin-top: 5px;text-align: right;color: rgb(153, 153, 153);font-weight: 400;font-size: 14px;}
	
	.result-reshint{margin: 20px 0 0;padding: 0 20px;}
	.reshint-resarticle{text-align: left;color: #000000;font-weight: 400;font-size: 16px;}
	/* 参考评价 */
	.result-resrefer{margin: 20px 0 0;padding: 20px;background: rgb(254, 248, 239);text-align: left;color: rgb(0, 0, 0);font-family: "Segoe UI";}
	.resrefer-refertle{font-weight: 700;font-size: 20px;}
	.resrefer-rconclusive{position: relative;padding: 20px 0 0px;font-weight: 400;font-size: 16px;}
	.weight-700{font-weight: 700;}
	.rconclusive-rconicon{position: absolute;top: 22px;left: 0;width: 20px;height: 17px;border-radius: 4px;background: rgb(204, 80, 62);}
	.rconclusive-rconicon::after{content: "";position: absolute;top: 4px;right: -3px;transform: rotate(-135deg);width: 0px;height: 0px;border-top: 7px solid rgb(204, 80, 62);border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 7px solid transparent;}
	.rconicon-cxrc-bgcolor{background: rgb(159, 144, 188);}
	.rconicon-cxrc-bgcolor::after{content: "";position: absolute;top: 4px;right: -3px;transform: rotate(-135deg);width: 0px;height: 0px;border-top: 7px solid rgb(159, 144, 188);border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 7px solid transparent;}
	.rconicon-cxtr-bgcolor{background: rgb(29, 105, 150);}
	.rconicon-cxtr-bgcolor::after{content: "";position: absolute;top: 4px;right: -3px;transform: rotate(-135deg);width: 0px;height: 0px;border-top: 7px solid rgb(29, 105, 150);border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 7px solid transparent;}
	.rconicon-cxcc-bgcolor{background: rgb(237, 173, 8);}
	.rconicon-cxcc-bgcolor::after{content: "";position: absolute;top: 4px;right: -3px;transform: rotate(-135deg);width: 0px;height: 0px;border-top: 7px solid rgb(237, 173, 8);border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 7px solid transparent;}
	.rconicon-lsfz-bgcolor{background: rgb(115, 175, 72);}
	.rconicon-lsfz-bgcolor::after{content: "";position: absolute;top: 4px;right: -3px;transform: rotate(-135deg);width: 0px;height: 0px;border-top: 7px solid rgb(115, 175, 72);border-left: 7px solid transparent;border-right: 7px solid transparent;border-bottom: 7px solid transparent;}
	.rconclusive-rconarticle{margin-left: 30px;}
	
	/* 下载报告 */
	.evaluate-evaopera{display: flex;justify-content:space-between;margin: 0 auto;padding: 20px 0 0px;width: 1200px;}
	.evaopera-btn{display: block;padding: 0;width: 400px;height: 40px;line-height: 40px;box-sizing: border-box;background: rgb(102, 204, 51);color: #ffffff;font-weight: 400;font-size: 16px;}
	.evaopera-linka{background: rgb(67, 201, 219);}
	.evaopera-goback{width: 200px;background: rgb(200, 198, 198)}
	.evaopera-download{width: 290px;background: rgb(65, 199, 219);/* background: rgb(255, 153, 51); */}
	
	/* 意见反馈-弹框 */
	.popup-feedbackbox{}
	.popup-feedbackbox /deep/ .el-dialog{margin-top: 30vh !important;width: 600px;/* width: 60%;min-width: 730px; */}
	.popup-feedbackbox /deep/ .el-dialog__header{padding: 0;}
	.popup-feedbackbox /deep/ .el-dialog__headerbtn{top: 24px;}
	.popup-feedbackbox /deep/ .el-dialog__body{padding: 20px 20px;text-align: left;}
	.popup-feedbackbox /deep/ .el-dialog__footer{padding: 0px 20px 20px;}
	.feedbackbox-feedbackcon{}
	.confirmcon-poptle{color: #000000;font-weight: 700;font-size: 20px;}
	.confirmcon-puthint{margin-top: 20px;color: #000000;font-weight: 400;font-size: 16px;}
	.confirmcon-fillinhint{margin-top: 10px;color: #000000;font-weight: 400;font-size: 16px;}
	
	.feedbackcon-feedbackfooter{text-align: right;}
	.feedbackcon-feedbackfooter::after{display: block;content: '';clear: both;}
	.regfooter-reghint{display: block;float: left;/* margin-left: 10px; */line-height: 40px;color: #E72D6B;}
	.regfooter-regbtnbox{display: block;float: right;overflow: hidden;}
	.feedbackfooter-footbtn{display: block;float: right;margin-left: 10px;padding: 0;width: 100px;height: 40px;line-height: 40px;background: rgb(102, 204, 51);/* rgb(65, 199, 219)蓝色  background: #E72D6B; */box-sizing: border-box;color: #ffffff;font-size: 16px;}
	.feedbackfooter-cancelbtn{line-height: 38px;border: 1px solid rgb(102, 102, 102);/* rgb(102, 204, 51) border: 1px solid #E72D6B; */background: #ffffff;color: #333333;}
	
	
	</style>
