<template>
	<!-- 未登录-跳转登录页-弹框 -->
	<el-dialog
		class="popup-nologin"
		:visible.sync="goLoginPopupShow"
		:close-on-click-modal="false"
		:show-close="false">
		<!-- <span class="submitconfirm-confirmtitle">找回密码</span> -->
		<div class="nologin-logincon">
			<!-- <div class="confirmcon-poptle">确认返回上一页？</div> -->
			<div class="logincon-loghint">
				<span>您暂未登录，请 </span><router-link class="color-blue" to="/login">登录</router-link>
				<span> 后进行操作! </span><span class="color-blue">{{secondNum}}s</span><span>后将进入登录页面</span>
			</div>
		</div>
		<!-- <div slot="footer" class="confirmcon-confirfooter">
			<button class="confirfooter-footbtn" type="primary">确 认</button>
			<button class="confirfooter-footbtn confirfooter-cancelbtn" @click="goLoginPopupShow = false">取 消</button>
		</div> -->
	</el-dialog>
</template>

<script>
	export default {
		data(){
			return{
				secondNum: 3,
				countTimer: '',
			}
		},
		props:{
			goLoginPopupShow: {
				type: Boolean,
				default() {
					return false
				}
			},
			// secondNum: {
			// 	type: Number,
			// 	default() {
			// 		return 3
			// 	}
			// }
		},
		components:{
	
		},
		mounted() {
			// if(this.goLoginPopupShow == true) {
			// 	console.log(this.goLoginPopupShow + '----%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
			// 	this.goPage()
			// }
		},
		updated() {
			if(this.goLoginPopupShow == true) {
				console.log(this.goLoginPopupShow + '----%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
				this.goPage()
			}
		},
		methods: {
			goPage() { // 未登录-跳转登录页面-倒计时
				let _this = this
				const count_down = 3;
				if (!_this.countTimer) {
					_this.secondNum = count_down;
					_this.countTimer = setInterval(() => {
						if (_this.secondNum > 0 && _this.secondNum <= count_down) {
							_this.secondNum --;
							console.log('倒计时==================' + _this.secondNum)
						}else {
							clearInterval(_this.countTimer);
							_this.countTimer = null;
							_this.goLoginPopupShow == false
							_this.$router.push("/")
						}
					}, 1000)
				}
			},
		},
		watch: {
			// echartDatas: {
			// 	handler(val, oldVal){
			// 		// console.log('当数量变化时重新绘制图表')//但是这两个值打印出来却都是一样的
			// 		this.echartBar()
			// 	}
			// },
		},
		beforeDestory(){
			clearTimeout(this.countTimer)
			this.countTimer = null;
		}
	}
</script>

<style>
	.popup-nologin .el-dialog{margin-top: 30vh !important;width: 30%;min-width: 500px;}
	.popup-nologin .el-dialog__header{padding: 0;}
	.popup-nologin .el-dialog__headerbtn{top: 20px;}
	.popup-nologin .el-dialog__body{padding: 20px 20px;text-align: center;}
	.nologin-logincon{}
	.logincon-loghint{margin: 40px 0;color: #000000;font-weight: 400;font-size: 16px;}
	.color-blue{color: #1d59b2 !important;}
</style>
